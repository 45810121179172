<template>
  <div class="landing_page" > 
    
  </div>
</template>
<script>

export default {
    name:'Link',
    mounted(){
      let link = window.location.href.split('=')[1]
      link = decodeURIComponent(link)
      document.getElementsByTagName('body')[0].style.backgroundColor = 'white';
      if(link)
        window.location.href = link;
    }
}
</script>
