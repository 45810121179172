<template>
  <div>
    <Navbar/>
    <div class="bringin-container ">
        <div class="row my-4">
            <div class="col-12 col-md-3 col-sm-12">
                <img :src="viewBrandData.image" v-if="viewBrandData.image" alt="Avatar" class="avatar me-3 p-1">
                <img src="../../../assets/images/demo_brand.jpg" v-if="!viewBrandData.image" alt="Avatar" class="avatar me-3 p-1">
            </div>
            <div class="col-12 col-md-9 col-sm-12 mt-2 ">
                <div>
                    <h2 class="text-light me-4">{{viewBrandData.brand_name}}</h2>
                </div>
                <div>
                    <p class="text-muted mb-1">{{viewBrandData.description}}</p>
                </div>
            </div>    
        </div>
        <div class="row d-flex justify-content-center mt-4">
            <ul class="nav nav-pills mb-3 col-12 border d-flex justify-content-between border-color" id="pills-tab" role="tablist">
            <li class="nav-item py-1" role="presentation"  style="margin:auto" >
                <div class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"  role="tab" aria-controls="pills-home" aria-selected="true">
                     <img 
                    src="../../../assets/Assets.xcassets/Dark_Theme_icons/brand_video.imageset/brand_video.png" 
                    class="container-fluid cursor-point" 
                    alt="icon"
                    />
                    <!-- <img 
                    src="../../../assets/Assets.xcassets/Dark_Theme_icons/brand_video.imageset/brand_video@3x.png" 
                    class="container-fluid cursor-point" 
                    alt="icon"
                    v-if="!active"
                    /> -->
                </div>
            </li>
            <li class="nav-item border-right-left padding-right-left py-1" role="presentation">
                <div @click="getProductbyBrand" class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"  role="tab" aria-controls="pills-profile" aria-selected="false">
                   <img 
                    src="../../../assets/Assets.xcassets/Dark_Theme_icons/brand_hut.imageset/brand_hut.png" 
                    class="container-fluid cursor-point "
                    alt="icon"
                    />
                </div>
            </li>
            <li class="nav-item py-1" role="presentation" style="margin:auto">
                <div @click="getOfferbyBrand" class="nav-link "   id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact"  role="tab" aria-controls="pills-contact" aria-selected="false">
                <img 
                    src="../../../assets/Assets.xcassets/Dark_Theme_icons/brand_offers.imageset/brand_offers.png" 
                    class="container-fluid cursor-point" 
                    alt="icon"/>   
                </div>
            </li>
            </ul>
        </div>
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <!-- <div class=" d-flex justify-content-center" >
                    <p  class="m-4 text-light text-center"><font-awesome-icon :icon="['fas','align-justify']" size="3x" class="second-primary-color icon-filter p-3"/><br/>All</p>
                    <p  class="m-4 text-light text-center"><font-awesome-icon :icon="['fas','align-justify']" size="3x" class="second-primary-color icon-filter p-3"/><br/>Reviews</p>
                    <p  class="m-4 text-light text-center"><font-awesome-icon :icon="['fas','align-justify']" size="3x" class="second-primary-color icon-filter p-3"/><br/>Demo</p>
                </div> -->
                <!-- <div class="border-section"> -->
                    <!-- <div class="d-flex justify-content-center mt-4" >
                        <select v-model="changeLanguage" class="btn-bg-black mb-3 rounded-pill text-light px-4 py-2 me-4" aria-label="Default select example">
                            <option selected>language</option>
                            <option class="my-2" v-for="row of viewPostbyBrandData.language" :key="row"  :value="row">{{row}}</option>
                        </select>
                        <select class="btn-bg-black mb-3 rounded-pill text-light px-4 py-2 me-4">
                            <option selected>interest</option>
                            <option class="my-2" v-for="row of viewPostbyBrandData.interests" :key="row"  :value="row">{{row}}</option>
                        </select>
                        <div class="dropdown">
                            <button class="btn btn-bg-black dropdown-toggle rounded-pill text-light px-5 py-2 me-4" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                category
                            </button>
                            <ul class="dropdown-menu modal-bg" aria-labelledby="dropdownMenuButton1">
                                <li><a class="dropdown-item  text-light disabled" href="#" >category</a></li>
                                <li ><a v-for="row of viewPostbyBrandData.interests" :key="row" @click="changeIntrest()" class="dropdown-item  text-light" href="#">{{row}}</a></li>
                            </ul>
                        </div>
                        <p class="fw-bold second-primary-color pt-2">clear filter</p>
                    </div> -->
                    <div class="row text-light">
                        <div class="d-flex justify-content-center mt-4" v-if="loader">
                            <Loader/>
                        </div>
                        <div v-for="post of viewPostbyBrandData.posts" :key="post.id" class="col-6 col-md-3 col-sm-4 my-2">
                            <BringinCard 
                                v-bind:post="post" 
                                v-bind:handle="post.handle"
                                v-bind:profile_pic="post.profile_pic"
                            />
                        </div>
                    </div> 
                <!-- </div> -->
            </div>
            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <div class="row text-light">
                    <div class="d-flex justify-content-center mt-4" v-if="loader">
                        <Loader/>
                    </div>
                    <div v-for="product of viewProductbyBrandData" :key="product.id" class="col-6 col-md-2 col-sm-3 my-4">
                        <ProductCard v-bind:cardData="product" v-bind:ProductByBrandPage="ProductByBrandPage"/>
                    </div>
                </div> 
            </div>
            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                <div class="row text-light">
                    <div class="d-flex justify-content-center mt-4" v-if="loader">
                        <Loader/>
                    </div>
                    <div v-for="product of getOfferbyBrandData" :key="product.id" class="col-12 my-4">
                        <div class="offer-brand-container">
                            <a :href="product.link" target="_blanck">
                                <img :src="product.image_link" class="offer-brand img-fluid"/>
                            </a>
                        </div>
                        
                    </div>
                </div> 
            </div>
        </div>     
    </div>
    <Footer/>
  </div>
</template>

<script>
import axios from 'axios'
import Loader from '../../common/Loader/Loader.vue'
import BringinCard from '../../common/Card/BringinCard.vue'
import Navbar from '../../common/Navbar/Navbar.vue'
import ProductCard from '../CreateVideo/ProductCard.vue'
import Footer from "../../common/Footer/Footer.vue"

export default {
    name: 'FeaturedBrand',
    components: {BringinCard,Navbar,Loader,ProductCard,Footer},
    data(){
        return{
            loader:true,
            brandId:this.$route.params.id,
            changeLanguage:[],
            viewPostbyBrandData:{},
            viewProductbyBrandData:[],  
            viewBrandData:{}, 
            ProductByBrandPage:true,
            getOfferbyBrandData:[],
            filter:true        
        };
    },

    mounted(){
     this.dataAPIs();
    },

    methods:{
        dataAPIs(){
            let token = JSON.parse(localStorage.userData).token
            axios.defaults.headers.common['token'] = token
            this.getAPIsData();
        },
        getAPIsData(){
            this.getViewPostbyBrand();
            this.getBrandData();
        },
        changeLanguage(){
            console.log(this.changeLanguage[0])
            // this.getAPIsData();
        },
        changeIntrest(){

        },
        getBrandData(){
            let self = this
            self.loader=true
            let url = "/api/v1/brand/getbrand/"
            let payload={
                "brand_id":self.brandId
            }
            axios.post(url,payload).then(function(res){
                // console.log("res",res.data.);
                let resData = res.data.data
                // alert(resData.data)
                if(resData.success)
                {
                    self.viewBrandData=resData.data;
                    // self.loader=false;
                    self.filter=true
                }
                else
                {
                    alert("Invalid User getbrand data")
                }
            });
        },
        getViewPostbyBrand(){
            let self = this
            self.loader=true
            let url = "/api/v1/homepage/postbybrandv2/"
            let payload={
                brand_id:self.brandId,
                language:[],
                interest: []
            }
            axios.post(url,payload).then(function(res){
                // console.log("res",res.data.);
                let resData = res.data
                if(resData.success)
                {
                    self.viewPostbyBrandData=resData.data.data;
                    self.loader=false;
                    self.filter=true
                }
                else
                {
                    alert("Invalid User")
                }
            });
        },
        getBrandOffers(){
            console.log("cehck")
        },
        getProductbyBrand(){
            let self = this
            self.loader=true
            let url = "/api/v1/get/productslist?brand_id="+self.brandId
            axios.get(url,).then(function(res){
                // console.log("res",res.data.);
                let resData = res.data
                if(resData.success)
                {
                    self.viewProductbyBrandData=resData.data;
                    self.loader=false;
                    self.filter=false
                }
                else
                {
                    alert("Invalid User")
                }
            });
        },
        getOfferbyBrand(){
            let self = this
            self.loader=true
            let url = "/api/v1/products/offers/?brand_id="+self.brandId
            axios.get(url,).then(function(res){
                // console.log("res",res.data.);
                let resData = res.data
                if(resData.success)
                {
                    self.getOfferbyBrandData=resData.data;
                    self.loader=false;
                    self.filter=false
                }
                else
                {
                    alert("Invalid User")
                }
            });
        },
    },
}
</script>

<style scoped>
    .bringin-container {
        padding:0 4% 0 4%;
    }
    .second-btn-color{
        background-color: #b8f009;
    }
    .second-primary-color{
        color: #b8f009;
    }
    .padding-right-left{
        padding:0 12% 0 12%
    }
    .border-right-left{
        border-left: 1px solid #303030;
        border-right: 1px solid #303030;
    }
    .border{
        border:1px solid #303030!important;
    }
    .avatar {
        vertical-align: middle;
        width: 170px;
        height: 170px;
        border-radius: 50%;
        border:2px solid #b8f009;
    }
    .modal-align{
        text-align: center !important;
    }
    .modal-bg{
        background: #202020;
    }
    .border-section{
        /* border-top:1px solid #cccacab4; */
          border-top: 1px solid #303030;

    }
    .border-color{
        border-style: solid;
         border-color:#303030;
    }

    .icon-filter{
        vertical-align: middle;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #000;
        border:1px solid #b8f009; 
        margin: 2px;   
    }
    .btn-bg-black{
        background-color: #000;
        border:none
    }
    .cursor-point{
        cursor: pointer;

    }
    .nav-pills .nav-link.active{
        background-color: #000;
    }
    .offer-brand-container{
        background: white;
        padding: 5px;
        display: flex;
        justify-content: center;
    }
    .offer-brand{
        /* height:500px !important; */
        text-align: center;
    }
</style>>

