<template>
<div>
    <div class="spinner-border bringin-spinner"  role="status">
         <span class="visually-hidden">Loading...</span>
    </div>
</div>
</template>
<script>
export default{
  name:'Loader'
}
</script>
<style scopped>
  .bringin-spinner{
    color:#b8f009;
    width: 3rem; 
    height: 3rem;
  }
</style>