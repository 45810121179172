<template>
    <div>
        <Navbar/>
        <div class="bringin-container">
            <div class="row my-4 text-light d-flex justify-content-center">
                <div class="col-12">
                    <h2 class="text-light fw-bold text-center mb-3">Featured Categories</h2>
                    <div class="d-flex justify-content-center" v-if="loader">
                        <Loader/>
                    </div>
                    <div class="row ">
                        <div  v-for="data of viewCategoryData" :key="data.interest_id" class="col-4 col-sm-3 col-lg-2 pb-2">
                            <router-link :to="'/category/'+data.interest_id" class="text-dark">
                                <Category :data="data" :categoryList="true"/>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import axios from 'axios'
import Navbar from '../../common/Navbar/Navbar.vue'
import Loader from '../../common/Loader/Loader.vue'
import Footer from "../../common/Footer/Footer.vue"
import Category from '../Home/Category.vue'
export default{
    name: "AllFeaturedBrandList",
    components: {Navbar,Loader,Footer,Category},
    data(){
        return{
            loader:true,
            viewCategoryData:[],
        }
    },
    mounted(){
     this.dataAPIs();
    },
    methods:{
        dataAPIs(){
            let token = JSON.parse(localStorage.userData).token
            axios.defaults.headers.common['token'] = token
            this.getAPIsData();
        },
        getAPIsData(){
            this.getFormData();
        },
        getFormData(){
            let self = this
            self.loader=true
            let url = "/api/v1/get/formdata"
            axios.get(url).then(function(res){
                // console.log("res",res.data.);
                let resData = res.data
                if(resData.success)
                {
                    self.viewCategoryData=resData.data.interests;
                    self.loader=false;
                }
                else
                {
                    alert("Invalid ")
                }
            });
        },
    }
}
</script>
<style scoped>
.bringin-container{
    padding:0 4% 0 4%;
}
a{
    text-decoration: none;
}

</style>