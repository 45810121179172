<template>
    <div>
        <Navbar/>
        <div class="bringin-container">
            <div class="row my-4 text-light d-flex justify-content-center">
                
                <div class="col-12">
                    <h2 class="text-light fw-bold text-center mb-3">Featured Brands</h2>
                    <div class="d-flex justify-content-center" v-if="loader">
                        <Loader/>
                    </div>
                    <div class="row ">
                        <div  v-for="brand of viewFormData.brand" :key="brand.brand_id" class="col-4 col-sm-3 col-lg-2 pb-2">
                            <div v-if="brand.brand_name!=''&& brand.image!=null">
                                <router-link :to='"/brand/"+brand.brand_id'><FeaturedBrands :brandName="brand.brand_name" :brandImgUrl="brand.image" :brandslist="true"/></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>
<script>
import axios from 'axios'
import Navbar from '../../common/Navbar/Navbar.vue'
import Loader from '../../common/Loader/Loader.vue'
import BringinCard from '../../common/Card/BringinCard.vue'
import Footer from "../../common/Footer/Footer.vue"
import FeaturedBrands from "../Home/FeaturedBrands"

export default{
    name: "AllFeaturedBrandList",
    components: {Navbar,Loader,BringinCard,Footer,FeaturedBrands},
    data(){
        return{
            loader:true,
            viewFormData:[],
        }
    },
    mounted(){
     this.dataAPIs();
    },
    methods:{
        dataAPIs(){
            let token = JSON.parse(localStorage.userData).token
            axios.defaults.headers.common['token'] = token
            this.getAPIsData();
        },
        getAPIsData(){
            this.getFormData();
        },
        getFormData(){
            let self = this
            self.loader=true
            let url = "/api/v1/get/formdata"
            axios.get(url).then(function(res){
                // console.log("res",res.data.);
                let resData = res.data
                if(resData.success)
                {
                    self.viewFormData=resData.data;
                    self.loader=false;

                }
                else
                {
                    alert("Invalid ")
                }
            });
        },
    }
}
</script>
<style scoped>
.bringin-container{
    padding:0 4% 0 4%;
}
a{
    text-decoration: none;
}

</style>