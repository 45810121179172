<template>
  <div>
        <li class="py-2">
            <a class="dropdown-item dropdown-item-hover text-light fw-bold d-flex justify-content-start" href="#">
                <img src="../../../assets/images/avatar.jpg" alt="Avatar" class="avatar me-3"/>
                <p class="m-0">Action lkjlkjfgl fg;lkjsdfg saldfasj  aljas<br/>kjgkjgkjgkjgkjgkjg</p>
            </a>
        </li>
        <li><hr class="dropdown-divider p-0 m-0"></li>
  </div>
</template>

<script>
export default {
  name: 'Notifications',
}
</script>

<style scoped>
  .avatar {
  vertical-align: middle;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.dropdown-divider{
    border-bottom: 1px solid #cccacab4;
}
.dropdown-item-hover:hover{
    background-color: #b8f009;
}
.dropdown-item-hover p:hover{
   color: #000;
}
</style>>

