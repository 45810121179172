<template>
  <div class="landing_page" ref="support"  > 
    <header id="site-header">
       <div class="site-container d-flex align-items-space-between" style="justify-content:space-between">
          <div class="logo">
            <a href="/">
              <img src="/bringin_landing/assets/images/bringin_logo_new.png" alt="" />
            </a>
          </div>
          <!-- <div style="color:white" v-if="!Object.keys(userData).length">
             <a  href="/login" class="button button-white shadow-sm gotologin"><span class="d-flex align-items-center justify-content-center"><i class="fa fa-user  mr-2 text-black"></i><span class="text-black">Login</span></span></a>
          </div>  -->

        <!-- <nav id="nav" class="ml-auto">
          <button class="close-menu">
            <img src="/bringin_landing/assets/images/icons/close.svg" alt="">
          </button>
          <ul>
            <li>
              <a href="#">Creators</a>
            </li>
            <li>
              <a href="#">Brands</a>
            </li>
            <li>
              <a href="#">You</a>
            </li>
          </ul>
        </nav>
        <button class="hambuger">
          <img alt="" src="/bringin_landing/assets/images/icons/navicon.svg" />
        </button> -->
      </div>
    </header>
    <main id="main-content">
      <section class="content-section lightbg bg-color">
        <div class="container">
          <h1 class="heading heading-md text-color mb-5">
            Contact Us
        </h1>
                <div class="row">
                  <div class="col-md-6">
                    <div class="contact-info">
                      <ul>
                          <li>
                            <i class="fas fa-envelope-open-text"></i>
                            <small>Email:</small>
                              <a href="mailto:info@bringin.live">info@bringin.live</a>
                              </li>
                              <li>
                                <i class="far fa-building"></i>
                              <small>Company Name:</small>
                              Bringin Web Private Limted
                          </li>
                          <li>
                            <i class="fas fa-map-marked-alt"></i>
                              <small>Address:</small>
                              N1/003, the close (North) Unitech Nirvana Country, South City 2 Gurgaon HR-122018 India</li>
                      </ul>
                  </div>
                  </div>
                  <div class="col-md-6 d-none d-md-block text-center">
                      <img src="/bringin_landing/assets/images/support.html11.png" alt="">
                  </div>
                </div>
        </div>
      </section>
    </main>
    <footer id="site-footer">
      <div class="site-container">
        <!-- <div class="languages-wrap">
          <img src="/bringin_landing/assets/images/languages.png" alt="">
        </div> -->
        <div class="logo-icon mb-3">
          <img src="/bringin_landing/assets/images/image-12.png" alt="">
        </div>
        <div class="flinks mb-1">
          <ul>
            <li>
              <a href="/aboutus/" class="text-white">About Us</a>
            </li>
            <!-- <li>
              <a href="/blog/" class="text-white">Blogs</a>
            </li> -->
            <!-- <li>
              <a href="#">Careers</a>
            </li> -->
            <li>
              <a href="/privacy/" class="text-white">Privacy Policy</a>
            </li>
            <!-- <li>
              <a href="#">Terms of Service</a>
            </li> -->
            <li>
              <a href="/support/" class="text-white">Support</a>
            </li>
             <li>
              <a href="/bringinBazaar" class="text-white">Bazaar</a>
            </li>
          </ul>
        </div>
        <div class="copyright">
          <p class="m-0">&copy; Copyright 2024 Bringin Web Private Limited. All rights reserved.</p>
        </div>
      </div>
      <div class="bg-vector">
        <img src="/bringin_landing/assets/images/bg-vector-light.png" alt="">
      </div>
    </footer>
  </div>
</template>
<script>

export default {
    name:'Welcome',
    data(){
        return {
          desc: '',
          userData: {}
        }
    },
    mounted(){
      if(localStorage.userData) this.userData = JSON.parse(localStorage.userData)
    }
}
</script>
