<template>
  <div >
    <Navbar/>
    <div class="bringin-container ">
        <div class="row my-4">
            <div class="col-12 col-md-3 col-sm-12">
                <img :src="viewProfilePageData.user_data.profile_pic"  v-if="viewProfilePageData.user_data.profile_pic "alt="Avatar" class="avatar me-3 p-1">
                <img src="../../../assets/images/dummy.png" v-if="!viewProfilePageData.user_data.profile_pic" alt="Avatar" class="avatar me-3 p-1">
            </div>
            <div class="col-12 col-md-9 col-sm-12 mt-2">
                <div class="d-flex justify-content-start">
                    <h2 class="text-light me-4">{{viewProfilePageData.user_data.handle}}</h2>
                    <!-- <button type="button" class="btn second-btn-color me-4 ">Edit Profile</button> -->
                    <ProfileSetting/>
                    <!-- <a data-bs-toggle="modal" data-bs-target="#staticBackdrop"><font-awesome-icon :icon="['fas','cog']" size="2x " class="m-2 text-light"/></a> -->
                    <!-- <button type="button" class="btn second-btn-color me-4 ">Following</button> -->
                    <!-- <button type="button" class="btn second-btn-color me-4 ">Message</button> -->
                    <!-- <a href="/" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><font-awesome-icon :icon="['fas','ellipsis-h']" size="2x " class="m-2 text-muted"/></a> -->
                </div>
                <div class="d-flex justify-content-start my-4">
                    <FollowerList v-bind:followerNumber="viewProfilePageData.follow_stats.followers" v-bind:userId="userId"/>
                    <FollowingList v-bind:followingNumber="viewProfilePageData.follow_stats.following" v-bind:userId="userId"/>
                    <p class="text-light me-5 fw-bold m-0 hover-cursor">{{viewProfilePageData.takes.take_count}} takes</p>
                </div>
                <div>
                    <p class="fs-5 fw-bold text-light mb-1">{{viewProfilePageData.user_data.name}}</p>
                    <p class="text-muted mb-1">{{viewProfilePageData.user_data.city}}</p>
                    <p class="fw-bold text-light">{{viewProfilePageData.user_data.bio}}</p> 
                    <!-- <p class="text-muted">Followed by 
                        <span class="fw-bold text-light">shiv43, shivam56 and 1 others</span>
                    </p> -->
                </div>
            </div>    
        </div>
        <!-- <div class="border-section d-flex justify-content-center" >
            <p  class="m-4 text-light text-center"><font-awesome-icon :icon="['fas','align-justify']" size="3x" class="second-primary-color icon-filter p-3"/><br/>All</p>
            <p  class="m-4 text-light text-center"><font-awesome-icon :icon="['fas','align-justify']" size="3x" class="second-primary-color icon-filter p-3"/><br/>Reviews</p>
            <p  class="m-4 text-light text-center"><font-awesome-icon :icon="['fas','align-justify']" size="3x" class="second-primary-color icon-filter p-3"/><br/>Demo</p>
        </div> -->
        <div class="border-section ">
            <!-- <div class="d-flex justify-content-center mt-4">
                <div class="dropdown">
                    <button class="btn btn-bg-black dropdown-toggle rounded-pill text-light px-4 py-2 me-4" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                       language
                    </button>
                    <ul class="dropdown-menu modal-bg " aria-labelledby="dropdownMenuButton1">
                        <li><a class="dropdown-item  text-light disabled" href="#" >language</a></li>
                        <li v-for="row of viewUserPostsData.data.languages" :key="row"><a class="dropdown-item  text-light" href="#">{{row}}</a></li>
                    </ul>
                </div>
                <div class="dropdown">
                    <button class="btn btn-bg-black dropdown-toggle rounded-pill text-light px-5 py-2 me-4" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        brand
                    </button>
                    <ul class="dropdown-menu modal-bg" aria-labelledby="dropdownMenuButton1">
                        <li><a class="dropdown-item  text-light disabled" href="#" >brand</a></li>
                        <li v-for="row of viewUserPostsData.data.brands" :key="row"><a class="dropdown-item  text-light" href="#">{{row}}</a></li>
                    </ul>
                </div>
                <div class="dropdown">
                    <button class="btn btn-bg-black dropdown-toggle rounded-pill text-light px-4 py-2 me-4" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        interest
                    </button>
                    <ul class="dropdown-menu modal-bg" aria-labelledby="dropdownMenuButton1">
                       <li><a class="dropdown-item  text-light disabled" href="#" >interest</a></li>
                        <li v-for="row of viewUserPostsData.data.interests" :key="row"><a class="dropdown-item  text-light" href="#">{{row}}</a></li>
                    </ul>
                </div>
                <p class="fw-bold second-primary-color pt-2">clear filter</p>
            </div> -->
            <div class="row ">
                <div v-for="post of viewUserPostsData.data.posts" :key="post.post_id" class="col-6 col-md-3 col-sm-4 p-2 mt-4 my-4">
                     <BringinCard 
                        v-bind:post="post" 
                        v-bind:handle="viewProfilePageData.user_data.handle"
                        v-bind:profile_pic="viewProfilePageData.user_data.profile_pic"
                    />
                </div>
            </div>
        </div>
    </div>
    <!-- <Footer/> -->
  </div>
</template>

<script>
import axios from 'axios'
// import Loader from '../../common/Loader/Loader.vue'
import BringinCard from '../../common/Card/BringinCard.vue'
import Navbar from '../../common/Navbar/Navbar.vue'
import ProfileSetting from '../UserAccount/ProfileSetting.vue'
import FollowerList from './FollowerList.vue'
import FollowingList from './ FollowingList.vue'
import Footer from "../../common/Footer/Footer.vue"

export default {
    name: 'UserProfile',
    components: {BringinCard,Navbar,ProfileSetting,FollowerList,FollowingList,Footer},
    data(){
        return{
            userId:JSON.parse(localStorage.userData).user.id,
            viewProfilePageData:{},
            viewUserPostsData:{}
        };
    },

    mounted(){
     this.dataAPIs();
    },

    methods:{
        dataAPIs(){
            let token = JSON.parse(localStorage.userData).token
            axios.defaults.headers.common['token'] = token
            this.getAPIsData();
        },
        getAPIsData(){
            this.getViewProfilePage();
            this.getViewUserPosts(); 
        },
        getViewProfilePage(){
            let self = this
            let url = "/api/v1/profilepage/?profile_userid="+this.userId
            axios.get(url).then(function(res){
                // console.log("res",res.data.);
                let resData = res.data
                if(resData.success)
                {
                    self.viewProfilePageData=resData.data;
                }
                else
                {
                    alert("Invalid User")
                }
            });
        },
        
        getViewUserPosts(){
            let self = this
            let url = "/api/v1/viewuserposts/?profile_userid="+this.userId
            axios.get(url).then(function(res){
                // console.log("res",res.data.);
                let resData = res.data
                if(resData.success)
                {
                    self.viewUserPostsData=resData.data;
                    localStorage.setItem('userProfile_pic',self.viewUserPostsData.user_data.profile_pic);
                    localStorage.setItem('userHandle',self.viewUserPostsData.user_data.handle);
                    // console.log("chech self",self.viewProfilePageData);
                }
                else
                {
                    alert("Invalid User")
                }
            });
        }
    },
}
</script>

<style scoped>
    .bringin-container {
        padding:0 4% 0 4%;
    }
    .second-btn-color{
        background-color: #b8f009;
    }
    .second-primary-color{
        color: #b8f009;
    }
    .avatar {
        vertical-align: middle;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        border:2px solid #b8f009;
    }
    .modal-align{
        text-align: center !important;
    }
    .modal-bg{
        background: #202020;
    }
    .border-section{
        /* border-top:1px solid #cccacab4; */
          border-top: 1px solid #303030;

    }
    .icon-filter{
        vertical-align: middle;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #000;
        border:1px solid #b8f009; 
        margin: 2px;   
    }
    .btn-bg-black{
        background-color: #000;
    }
    .dropdown-item:hover{
        color:#000 !important;
    }
    .hover-cursor{
        cursor: pointer;
    }
</style>>

