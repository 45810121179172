<template>
  <div class="landing_page"  > 

    <font face="Arial" size="4px" color="#000000">
        <h2>About Bringin':</h2>
    </font>

    <font face="Arial" size="2px" style="color:black">
    <p style="color:black">Bringin' is a live-streaming, video content, shopper-tainment platform that allows its users to upload videos, brand reviews, tutorials and how-to videos so that the you can #DiscoverTheNew â€“ New brands, new ingredients, new hacks and get smarter about your brand choices.
    </p>
    </font>

    <font face="Arial" size="4px" color="#000000">
        <h2>About The  #BringinSquadGames Contest:</h2>
    </font>

    <font face="Arial" size="2px" color="#000000">
    <p>
    You don't need to purchase anything via Bringin website to improve your chances of winning.
    </p><ul>
      <li>Sponsor: Bringin Web private Limited (Bringin') is the sponsor of this contest.</li>
      <li>General Conditions: The #BringinSquadGames contest is open to participants who are:
          <ul>
        <li>At least 18 years of age</li>
        <li>Residents of India</li>
        <li>Not employed by Bringin'</li>
        </ul>
      </li>
    </ul>

    Bringin' reserves the right to cancel / suspend and/or modify the Contest in whole or in part, in particular but not limited to any fraud, technical failures or any other factor beyond our reasonable control that impairs the integrity or proper functioning of the Contest. Bringin reserves the right in its sole discretion to disqualify any Participant it finds:
        <ul>
        <li>to be tampering with the Entry processes or the operation of the Contest;</li>
        <li>to be acting in violation of the Rules or any other promotion; or</li>
        <li>to be acting in an unsportsmanlike or disruptive manner.</li>
        </ul>

    <p></p>
    </font>


    <font face="Arial" size="4px" color="#000000">
        <h2>Timing:</h2>
    </font>

    <font face="Arial" size="2px" color="#000000">
    <p>The Contest begins on Thursday, 2nd December, 2021 and ends on Tuesday, 14th December, 2021 â€“ Indian Standard Time. Bringin, upon its sole discretion, may extend the contest beyond 14th December or launch new contest as it may deem fit.
    </p>
    </font>

    <font face="Arial" size="4px" color="#000000">
        <h2>How to Participate: </h2>
    </font>

    <font face="Arial" size="2px" color="#000000">
    <p>
    </p><ul>
        <li>Download Bringin' app from the Google play store or Apple App Store and create your profile</li>
        <li>A new contest shall be announced every alternate day starting 2nd December and you will have to create a video as per the contest description. The title of the contest, use of hashtags, duration of the video shall be clearly mentioned in the contest announcement. For your video to be qualified as an entry, you need to follow the instructions of the specific contest as instructions may change for each contest.</li>
        <li>Entries should be of the user featuring himself or herself only.</li>
        <li>User needs to make sure that videos are shot in well-lit ambience and the products that are being discussed can be seen clearly along with the brand label. The video can be shot on a mobile phone and background should not be very busy. It is recommended that background should ideally be a light plain color wall so that the focus of the video is the user and the product that is being talked about.</li>
        <li>The video can be submitted in any of the following Languages: Assamese, Bangla, Bodo, Dogri, English, Gujarati, Hindi, Kashmiri, Kannada, Konkani, Malayalam, Manipuri, Marathi, Nepali, Oriya, Punjabi, Tamil and Telugu.</li>

        <li>For every contest, best 5 video submission entries shall get a goodie bag that shall be declared as the prize for the contest. The decision by Bringin' shall be considered final and the participants agree to abide by the final decision made by Bringin'.</li>
    </ul>
    <p></p>

    </font>

    <font face="Arial" size="4px" color="#000000">
        <h2>
            Representation and Warranties:
        </h2>
    </font>

    <font face="Arial" size="2px" color="#000000">
    <p>
    </p><ul>
        <li>User Video: Contest participant undertakes that the video being submitted has been shot by the user themselves and the user is clearly visible in the video while he / she is reviewing the product or following the contest requirements. The user undertakes that the video belong to him / her and is not infringing upon the copyrights of any other individual or organization.</li>
        <li>
            Decorum: All users of the app undertake to follow community guidelines. We are an inclusive platform that has a zero tolerance policy towards:
            <ul>
                <li>any kind of bullying, trolling or airing comments against a caste, religion, gender, sexual orientation, gender identities, age, appearance, dressing etc.</li>
                <li>Content that is indecent, obscene, slanderous, hateful, defamatory or libelous (depictions of nudity, sexual activity, offensive languages or images or propagating lewd behavior)</li>
                <li>Content that can be provoke violence,  threatening, abusive or depicting usage of drugs, weapons or any illegal activity)</li>
                <li>Sharing content that breaches privacy of any other person or entity including but not limited to sharing phone numbers, email ids, government identity cards like Aadhar card, PAN card, driving license etc or any information that can be personally identifiable of any individual or entity.</li>
            </ul>

        </li>
    </ul>
    <p></p>
    </font>

    <font face="Arial" size="4px" color="#000000">
        <h2>
            Declaration of Winners:
        </h2>
    </font>

    <font face="Arial" size="2px" color="#000000">
    <p>
    Since there shall be new contests launched every alternate day or as per the communication shared by Bringin, the winners shall be notified for each concluded contest as soon as the winners are identified but no later than 2 weeks from the date of the announcement of the contest. The winners shall be notified personally through a direct message on the Bringin app as well as via Bringin's social media handles on Instagram and Facebook. Within 7 days of award announcement, the winner needs to update the Bringin' team about their full name, postal address and other contact details so that the prize bags can be delivered to them. Any winner who doesn't respond to the winner announcement within the stipulated 7 days forfeits his or her rights to the prize.
    </p>
    </font>


    <font face="Arial" size="4px" color="#000000">
        <h2>
            Limitations of Liability:
        </h2>
    </font>
    <font face="Arial" size="2px" color="#000000">
    <p>
    Bringin' and its directors shall not be held responsible for: (1) any incorrect or inaccurate information, whether caused by Participant, printing, typographical or other errors or by any of the equipment or programming associated with or utilized in the Contest; (2) technical failures of any kind, including, but not limited to malfunctions, interruptions, or disconnections in phone lines or network hardware or software; (3) unauthorized human intervention in any part of the Entry process or the Contest; (4) printing, typographical, technical, computer, network or human error which may occur in the administration of the Contest, the uploading, the processing or judging of Entries, the announcement of the prizes or in any Contest-related materials; (5) late, lost, undeliverable, damaged or stolen mail; or (6) any injury or damage to persons or property which may be caused, directly or indirectly, in whole or in part, from Participant's participation in the Contest or receipt or use or misuse of any prize (including any travel/activity related thereto). Bringin or its directors are not responsible for misdirected or undeliverable Entries or for any technical problems, malfunctions of computer systems, servers, providers, hardware/software, lost or unavailable network connections or failed, incomplete, garbled or delayed computer transmission or any combination thereof. Bringin or its directors are not responsible for any unauthorized third party use of any Entry. Under no circumstances will Participant be permitted to obtain awards for, and Participant hereby waives all rights to claim, indirect, punitive, incidental and consequential damages and any other damages, and any and all rights to have damages multiplied or otherwise increased.
    </p>
    </font>


    <font face="Arial" size="4px" color="#000000">
        <h2>
            Intellectual Property Rights:
        </h2>
    </font>

    <font face="Arial" size="2px" color="#000000">
    <p>
    All participants agree to irrevocably grant, transfer, convey and assign to sponsor the entirety of the rights in and to the submission, any derivative works made thereof in perpetuity including without limitation, the rights to use the submission for any and all purposes, including production and sales of products with the videos, in and all media whether now known or hereafter developed on a worldwide basis â€“ by Bringin' or Bringin's clients in future. Uploading a submission in this contest also constitutes, participant's waiver, to the extent permitted by law, of all moral rights conferred upon participants or arising in respect of the participant's submission anywhere in the world. In the event that the Winner cannot transfer and assign the rights to the Submission as contemplated in this section, then Winner grants to Bringin' a non-exclusive, royalty-free, irrevocable, perpetual (or, if perpetual is prohibited by law, for the longest extent permitted by law but in no event less than ten (10) years), worldwide right and license to display, perform, modify, adapt, reproduce, encode, store, copy, transmit, publish, post, broadcast, exhibit and/or otherwise use or reuse (without limitation as to when or to the number of times used), the Submission, in whole or in part, and any/all works based thereon, for any purpose reasonably related to the Contest or to fulfill any promise made by Bringin', including for promotional or marketing purposes, except where prohibited by law.
    </p>
    </font>


    <font face="Arial" size="4px" color="#000000">
        <h2>
            Publicity:
        </h2>
    </font>

    <font face="Arial" size="2px" color="#000000">
    <p>
    Except where prohibited, participation in the Contest constitutes winner's consent to Sponsor's and its agents' use of winner's name, likeness, photograph, voice, opinions and/or hometown and state for promotional purposes in any media, worldwide, without further payment or consideration. Except where prohibited, acceptance of the prize constitutes winner's consent to Sponsor's and its agents' use of winner's name, likeness, photograph, voice, opinions and/or hometown and state for promotional purposes in any media, worldwide, without further payment or consideration.
    </p>
    </font>


   
  </div>
</template>
<script>

export default {
    name:'Welcome',
    data(){
        return {
          desc: ''
        }
    },
    mounted(){
        document.getElementsByTagName('body')[0].style.backgroundColor = 'white';
    }
}
</script>
<style scoped>
 p{
     color:black!important
 }
</style>