<template>
  <div class="landing_page"  > 
     <header id="site-header">
      <div class="site-container d-flex align-items-space-between" style="justify-content:space-between">
          <div class="logo">
            <a href="/">
              <img src="/bringin_landing/assets/images/bringin_logo_new.png" alt="" />
            </a>
          </div>
          <!-- <div style="color:white">
             <a  href="/login" class="button button-white shadow-sm gotologin"><span class="d-flex align-items-center justify-content-center"><i class="fa fa-user  mr-2 text-black"></i><span class="text-black">Login</span></span></a>
          </div>  -->

        <!-- <nav id="nav" class="ml-auto">
          <button class="close-menu">
            <img src="/bringin_landing/assets/images/icons/close.svg" alt="">
          </button>
          <ul>
            <li>
              <a href="#">Creators</a>
            </li>
            <li>
              <a href="#">Brands</a>
            </li>
            <li>
              <a href="#">You</a>
            </li>
          </ul>
        </nav>
        <button class="hambuger">
          <img alt="" src="/bringin_landing/assets/images/icons/navicon.svg" />
        </button> -->
      </div>
    </header>
    <main id="main-content">
      <section class="content-section">
        <div class="container">
          <h1 class="heading heading-md text-color mb-4">Welcome to Bringin’ Bazaar—a paradise for fashion enthusiasts!</h1>
          <h2 class="heading heading-sm mt-4 mb-3">
            The Brand
          </h2>
          <p>Bringin’ is a dynamic platform designed to celebrate and showcase the creativity of talented label designers & boutiques. Bringin' connects passionate designers with shopaholics across India, providing an amazing opportunity to display their work and attract new clients.</p>
          <h2 class="heading heading-sm text-color mb-3">What's in it for labels & designers?</h2>
          <ol class="listol-style text-white">
            <li>Exposure: Broaden your reach across India.</li>
            <li>Dedicated Profile: Craft a profile that showcases your unique style.</li>
            <li>Engagement Tools: Connect with a lively community of design lovers and experts.</li>
            <li>Growth Opportunities: Unlock features that boost your business and brand visibility.</li>
            <li>Inventory Management: Monitor sales, track product popularity, and manage stock levels efficiently. </li>
          </ol>
          <h2 class="heading heading-sm mt-4 mb-3">
            What's in it for shoppers? 
          </h2>
          <ol class="listol-style text-white">  
              <li>Exclusive Access: Early entry to new collections and stunning designs.</li>
              <li>Personalized Recommendations: Custom fashion picks based on your tastes. </li>
              <li>Easy Shopping: Seamless experience with quick chat and fast checkout. </li>
              <li>Real-Time Updates: Stay updated on sales, events, and offers. </li>
              <li>Customization: Tailor products to your preference. </li>
              <li>Wishlist: Track and be alerted about your must-haves. </li>
              <li>Customer Support: Get dedicated help with orders and queries. </li>
              <li>Fashion Insights: Discover the latest trends and tips. </li>
              <li>Enquire Now Feature: Direct Enquires and chat options for tailored purchases. </li>
          </ol>
          <p>Dive into a seamless blend of fashion artistry and innovative shopping, all at your fingertips.<br/>
             Excited? We are too—let’s make fashion happen!</p>
        </div>
      </section>

      <section class="content-section lightbg app-download-section pb-0">
        <div class="container">
          <div class="row d-flex align-items-center">
            <div class="col-md-6 col-lg-7">
              <div class="download-app-holder">
                <div class="heading heading-sm text-white mb-4">
                  Download the app now <br />
                  and get shopper-tained!
                </div>
                <div class="btn-holder">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.lyxel.bringin"
                    target="_blank"
                    class="button button-white shadow-sm"
                  >
                    <span
                      class="d-flex align-items-center justify-content-center"
                    >
                      <i class="fab fa-google-play mr-2 text-white"></i>
                      <span>Get it on <strong>Google Play</strong></span>
                    </span>
                  </a>
                  <a
                    href="https://apps.apple.com/in/app/bringin/id1579153016"
                    target="_blank"
                    class="button button-white shadow-sm"
                  >
                    <span
                      class="d-flex align-items-center justify-content-center"
                    >
                      <i class="fab fa-apple mr-2 text-white"></i>
                      <span>Download on the <strong>App Store</strong></span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-5">
              <div class="app-img text-center">
                <img src="/bringin_landing/assets/images/app1.png" width="460" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer id="site-footer">
      <div class="site-container">
        <!-- <div class="languages-wrap">
          <img src="/bringin_landing/assets/images/languages.png" alt="" />
        </div> -->
        <div class="logo-icon mb-3">
          <img src="/bringin_landing/assets/images/image-12.png" alt="" />
        </div>
        <div class="flinks mb-1">
          <ul>
            <li>
              <a href="/aboutus/" class="text-white">About Us</a>
            </li>
            <!-- <li>
              <a href="/blog/" class="text-white">Blogs</a>
            </li> -->
            <!-- <li>
              <a href="#">Careers</a>
            </li> -->
            <li>
              <a href="/privacy/" class="text-white">Privacy Policy</a>
            </li>
            <!-- <li>
              <a href="#">Terms of Service</a>
            </li> -->
            <li>
              <a href="/support/" class="text-white">Support</a>
            </li>
             <li>
              <a href="/bringinBazaar" class="text-white">Bazaar</a>
            </li>
          </ul>
        </div>
        <div class="copyright">
          <p class="m-0">
            &copy; Copyright 2024 Bringin Web Private Limited. All rights
            reserved.
          </p>
        </div>
      </div>
      <div class="bg-vector">
        <img src="/bringin_landing/assets/images/image-12.png" alt="" />
      </div>
    </footer>
  </div>
</template>
<script>

export default {
    name:'About',
    data(){
        return {
          desc: ''
        }
    }
}
</script>
