<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// import Home from './components/pages/Home/Home.vue'
import Navbar from './components/common/Navbar/Navbar.vue'
// import Home from './components/pages/Login/Login.vue'
// import Home from './components/pages/InfluencerProfile/InfluencerProfile.vue'
import axios from 'axios'
export default {
  name: 'App',
  components:{Navbar},
  metaInfo: {
    title: "Bringin' - Your favourite labels under one fashion roof!",
    titleTemplate: "%s"
  },
  watch: {
    '$route': function(to,from) {
      this.checkLoggedIn(to.path,from)
    } 
  },
  created() {
    this.checkLoggedIn(this.$router.history.current.path,'')
  },
  methods: {
    checkLoggedIn(currentPath,from){
      if(Object.keys(localStorage).includes('userData'))
      {
        let token = JSON.parse(localStorage.userData).token
        axios.defaults.headers.common['token'] = token
        if ( currentPath == "/app" || currentPath == "/login" || currentPath == "/" ) {
          this.$router.push("/bringinBazaar");
        }
      } 
      else {
        let exceptionUrls = [
          '/',
          '/bringinsquadgame.html',
          '/aboutus/',
          '/playback',
          '/privacy/',
          '/coinsTnC',
          '/tnc',
          '/support/',
          '/RnCpolicy',
          '/blog/',
          '/bringinClubTnC',
          '/campus-ambassador',
          '/campus-ambassador-register',
          '/bringinBazaar',
        ] 
        if (!exceptionUrls.includes(currentPath) && (!currentPath.includes('link'))) 
          this.$router.push("/login"); 
        if(from.path )
          window.location.reload()
        else {
          setTimeout(function(){
            window.scrollTo(0, 0)  
          },200)
        }  
      }
    }   
  }  
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
  .gotologin
  {
    min-width: 100px!important;
    padding: 9px!important;
    height: 32px!important;
    line-height: 0!important;
  }
</style>
