<template>
  <div class="landing_page" > 
    <header id="site-header">
      <div class="site-container d-flex align-items-space-between" style="justify-content:space-between">
          <div class="logo">
            <a href="/">
              <img src="/bringin_landing/assets/images/logo2.png" alt="" />
            </a>
          </div>
          <div style="color:white">
             <a  href="/login" class="button button-white shadow-sm gotologin"><span class="d-flex align-items-center justify-content-center"><i class="fa fa-user  mr-2 text-black"></i><span class="text-black">Login</span></span></a>
          </div> 

        <!-- <nav id="nav" class="ml-auto">
          <button class="close-menu">
            <img src="/bringin_landing/assets/images/icons/close.svg" alt="">
          </button>
          <ul>
            <li>
              <a href="#">Creators</a>
            </li>
            <li>
              <a href="#">Brands</a>
            </li>
            <li>
              <a href="#">You</a>
            </li>
          </ul>
        </nav>
        <button class="hambuger">
          <img alt="" src="/bringin_landing/assets/images/icons/navicon.svg" />
        </button> -->
      </div>
    </header>
    <div style="display:flex;justify-content:center">
      <div ref="loadiframe"></div>
    </div>  
  </div>
</template>
<script>

export default {
    name:'Welcome',
    data(){
        return {
          desc: ''
        }
    },
    mounted(){
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('id', 'bringin_stream_lyxel')
      recaptchaScript.setAttribute('src', 'https://bringin-core.sgp1.digitaloceanspaces.com/klqmgu/init.js')
      this.$refs.loadiframe.appendChild(recaptchaScript)
    },
}
</script>
<style scoped>

</style>