<template>
    <div>
        <a data-bs-toggle="modal" data-bs-target="#profileSetting" class="curser-point"><font-awesome-icon :icon="['fas','cog']" size="2x " class="m-2 text-light"/></a>
        <div class="modal fade" id="profileSetting" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered  modal-sm ">
                <div class="modal-content modal-bg">
                    <!-- <div class="modal-body border-section">
                        <h5 class="text-light curser-point" id="staticBackdropLabel"><font-awesome-icon :icon="['fas','share-alt']" size="1x " class="me-2"/>  Share</h5>
                    </div>
                    <div class="modal-body border-section ">
                        <h5 class="text-light curser-point" id="staticBackdropLabel"><font-awesome-icon :icon="['fas','heart']" size="1x " class="me-2"/>  My Wishlist</h5>
                    </div>
                     <div class="modal-body border-section ">
                        <h5 class="text-light curser-point" id="staticBackdropLabel"><font-awesome-icon :icon="['fas','cog']" size="1x " class="me-2"/>  Change Languages</h5>
                    </div>
                    <div class="modal-body border-section ">
                        <h5 class="text-light curser-point" id="staticBackdropLabel"><font-awesome-icon :icon="['fas','users']" size="1x " class="me-2"/>  Blocked Users</h5>
                    </div>
                    <div class="modal-body border-section ">
                        <h5 class="text-light curser-point" id="staticBackdropLabel"><font-awesome-icon :icon="['fas','info-circle']" size="1x " class="me-2"/>  About</h5>
                    </div>
                    <div class="modal-body border-section ">
                        <h5 class="text-light curser-point" id="staticBackdropLabel"><font-awesome-icon :icon="['fas','question-circle']" size="1x " class="me-2"/>  Help</h5>
                    </div> -->
                    <div class="modal-body border-section ">
                        <h5 @click="logoutNow" class="second-primary-color curser-point" id="staticBackdropLabel"><font-awesome-icon :icon="['fas','sign-out-alt']" size="1x " class="me-2"/>  Logout</h5>
                    </div>
                    <div class="modal-footer border-section d-flex justify-content-start px-0 py-1 ">
                        <button type="button" class="btn fw-bold text-light fs-5" data-bs-dismiss="modal"><font-awesome-icon :icon="['fas','window-close']" size="1x " class="me-2"/>  Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default{
        name:'ProfileSetting',
        methods:{
            logoutNow(){
                const modal = document.getElementById('profileSetting')
                // change state like in hidden modal
                modal.classList.remove('show');
                modal.setAttribute('aria-hidden', 'true');
                modal.removeAttribute('aria-modal');
                modal.removeAttribute('role');
                modal.setAttribute('style', 'display: none;');
                // get modal backdrop
                const modalBackdrops = document.getElementsByClassName('modal-backdrop');
                // remove opened modal backdrop
                document.body.removeChild(modalBackdrops[0]);
                delete localStorage.userData
                window.location.reload();
            }
        }
    }
</script>
<style scopped>
    .second-primary-color{
            color: #b8f009;
    }
    .modal-bg{
        background: #202020;
    }
    .border-section{
        /* border-top:1px solid #cccacab4; */
          border-top: 1px solid #303030;

    }
    .curser-point{
        cursor: pointer;
    }
</style>