<template>
    <div>
        <div id="carouselExampleControls" class="carousel slide carousel-fade" data-bs-ride="carousel">
            <div class="carousel-inner offer-brand-container">
                <div class="carousel-item" :class="{active:i==0}" v-for="(offer,i) of viewHomepageOffersData" :key="offer.brand_id">
                    <a :href="offer.link" target="_blanck"><img :src="offer.image_link" class="d-block w-100 slider-img" alt="..."></a>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    </div>
</template>
<script>
export default {
  name:'Offers',
  props:['viewHomepageOffersData']
}
</script>

<style scoped>

.offer-brand-container{
    background: none;
    /* padding: 5px; */
    /* height:550px; */
}
.slider-img{
    text-align: center;
    width:100%;
    height: 100%;
    border-radius: 20px;
}
</style>