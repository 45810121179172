<template>
  <div>
    <div class="container-fluid bringin-container">
      <div class="row ">
        <div class="col-12  col-md-6 login-image">
          <img src="../../../assets/images/app1.png" height="100%" />
        </div>
        <div class="col-12  col-md-6">
          <div class="login-form border align-center p-4 rounded">
            <div>
              <img src="../../../assets/Assets.xcassets/Dark_Theme_icons/logo_home.imageset/logo_home.png" height="100%" class="img-fluid" />             
              <div  v-if="otpStatus===false">
                  <p class="pt-4">Login To Continue</p>
                  <div class="input-group mb-3 px-3  rounded">
                    <span class="input-group-text">+91</span>
                    <input type="text" v-model="phoneNumber" class="form-control" required/>
                  </div>
                  <div class="d-grid gap-2 px-3 ">
                        <button class="btn btn-color rounded"  v-on:click="getOTP">Send OTP</button>
                  </div>
              </div>
              <div v-else-if="otpStatus===true">
                <p class="pt-4 m-0">OTP verification</p>
                <p class="m-0 pt-2">We have sent a verification code to <br/>+91{{phoneNumber}}</p>
                <div class="input-group mb-3 px-3  rounded">
                    <input type="text" v-model="OTP" class="form-control" required/>
                </div>
                  <div class="d-grid gap-2 px-3 ">
                        <button class="btn btn-color rounded"  v-on:click="verifyOTP">Verify</button>
                  </div>
              </div>
            </div>
          </div>
          <div class="section align-center">
              <p>Get the app.</p>
              <a class="btn btn-color rounded m-2 rounded p-3" 
                 href="https://play.google.com/store/apps/details?id=com.lyxel.bringin" target="_blank">
                <font-awesome-icon :icon="['fab','google-play']" size="lg" class="mr-3"/>
                Get it on
                <span class="fw-bold">Google Play</span>
              </a>
              <a class="btn btn-color rounded m-2 p-3" 
                href="https://apps.apple.com/in/app/bringin/id1579153016" target="_blank">
                <font-awesome-icon :icon="['fab','apple']" size="lg" class="mr-3"/>
                Download on the 
                <span class="fw-bold">App Store</span>
              </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import SignUp from '../SignUp/SignUp.vue'
import axios from 'axios';

export default {
  name: "Login",
  // components:{"v-otp-input":OtpInput},
  data(){
    return{
      phoneNumber:'',
      OTP:'',
      otpStatus:false,
      userData:'',
      title: 'Login Page',
      desc: 'Welcome to Bringin'
    }
  },

  mounded(){
  },
  
  methods:{
    getOTP(){
      let self = this
      let url = "/api/v1/generateOTP/";
      let payload = {
        phone:this.phoneNumber
      };
      axios.post(url, payload).then(function(res) {
        let resData = res.data
        if(resData.success)
        {
          self.otpStatus=true
        }
        else
        {
          alert("Invalid Number")
        }
      });
    },

    verifyOTP(){
      let self = this
      let url = "/api/v1/login/";
      let payload = {
        phone:this.phoneNumber,
        pin:this.OTP,
        // master_key : "b0bcd704-3650-49a4-9bfb-be3d01fa3fe1",
        referred_from : "PART935"
      };
      axios.post(url, payload).then(function (res) {
        if(!res.data.success) {
          alert(res.data.data.message)
        }
        else
        {
          axios.defaults.headers.common['token'] = res.data.data.token
          localStorage.userData = JSON.stringify(res.data.data)
          self.$router.push("/home");
        }
      });
    }
  }
}
</script>
<style scoped>
    .bringin-container {
        background-color: #181818;
    }
    .login-image {
        height: 100vh;
        text-align: right;
    }
    .login-form {
        margin-top: 100px;
        width: 50%;
        text-align: center;
        color: #fff;
    }
    .section{
        margin-top: 20px;
        width: 50%;
        text-align: center;
        color: #fff;
    }
    .section p{
        padding:0;
        margin: 0;
    }
    .btn-color{
        background-color: #b8f009;
    }
    .secont-primary-color{
        color: #b8f009;
    }
    @media only screen and (max-width: 1200px) {
      .login-form {
        margin-top: 100px;
        width: 100%;
        text-align: center;
        color: #fff;
      }
      .section{
            margin-top: 20px;
            width: 100%;
            text-align: center;
            color: #fff;
        }
        .login-image{
          height: 50vh;
            text-align: center;
        }
      .bringin-container {
        background-color: #181818;
        height: 100vh;
      }
    }
    @media only screen and (max-width: 600px) {
      .login-form {
        margin-top: 10px;
        width: 100%;
        text-align: center;
        color: #fff;
      }
      .section{
          margin-top: 20px;
          width: 100%;
          text-align: center;
          color: #fff;
      }
      .login-image{
        height: 50vh;
          text-align: center;
      }
      .bringin-container {
        background-color: #181818;
        height: auto;
      }
    }
   
</style>
