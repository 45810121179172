<template>
  <div>
    <Navbar/>
    <div class="row">
        <div id="banner-container">
          <div id="grad"></div>
          <div id="grad2"></div>
          <img src="../../../assets/images/image 24.png" alt="...">
            <div class="banner-title d-flex justify-content-start">
              <div class="card-title secont-primary-color">How It Works</div>
              <!-- Button trigger modal -->
              <div data-bs-toggle="modal" data-bs-target="#exampleModal" @click="openHowItWorkModal"  >
                <i class="fa-solid fa-play  banner-icon rounded-circle p-3 ml-5"></i>
              </div>
              <!-- Modal -->
             <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog " style="width:361px">
                <div class="modal-content b-modal-content">
                  <div class="modal-header">
                    <div class="language-filter" id="exampleModalLabel">
                        <div class="modal-title-english cursor-point" :class="{activeVideo:howItWorkData.currentId==howItWorkData.englishId}" @click="changeLanguage('englishId')">English</div>
                        <div class="modal-title-hindi cursor-point" :class="{activeVideo:howItWorkData.currentId==howItWorkData.hindiId}" @click="changeLanguage('hindiId')">Hindi</div>
                    </div>
                    <button type="button" class="btn fs-3 text-light" data-bs-dismiss="modal" aria-label="Close" @click="stopVideoNow">
                      <i class="fa-solid fa-xmark text-light"></i>
                    </button>
                  </div>
                  <div class="modal-body">
                      <video ref="howitwork_hindi_english"   controls>
                        <source :src="'https://stream.mux.com/'+ howItWorkData.currentId +'/low.mp4'" type="video/mp4">
                      </video>
                  </div>
                </div>
              </div>
            </div>
            </div>
        </div>
    </div>
    
 
    <div class="">
          <div class="categories">
            <div class="d-flex justify-content-between">
              <p class="text-light fs-3 fw-bold">Categories</p>
              <router-link to="/categories"><p class="secont-primary-color fs-5 fw-bold cour">See All</p></router-link>
            </div>
            <div>
               <Hooper :settings="responsiveSetting" style="height:100%" ref="updateCarousel" @slide="updateCarouselCategory">
                 <Slide v-for="data of viewFormData.interests" :key="data.interest_id">
                    <router-link :to="'/category/'+data.interest_id" class="text-dark">
                      <Category :data="data" />
                    </router-link>
                  </Slide>
               </Hooper>
                <div class="d-flex justify-content-center">
                  <button @click.prevent="slidePrevCategory" class="arrow-prev"><i class="fa-solid fa-arrow-left"></i></button>
                  <button @click.prevent="slideNextCategory" class=" arrow-next ml-2"><i class="fa-solid fa-arrow-right "></i></button>
                </div>
            </div>
          </div>
          <div class="trending-container">
              <div class="d-flex justify-content-between">
                <p class="text-light fs-3 fw-bold">Trending Topics</p>
                <router-link to="trendingtopics"><p class="secont-primary-color fs-5 fw-bold">See All</p></router-link>
              </div>
              <div class="mb-3 trending-pills-container d-flex justify-content-start">
                  <div  v-for="trendingHashtag of viewTrendingHashtags" :key="trendingHashtag.order">
                    <router-link :to="'/hashtag/'+trendingHashtag.tag_text" class="text-dark">
                      <span class="badge rounded-pill trending-pills-bedge p-2 m-1">
                          #{{trendingHashtag.tag_text}}                
                      </span>
                    </router-link>
                  </div>
              </div>
              <!-- checking section -->
              <div>
               <Hooper :settings="trendingResponsiveSettings" style="height:100%" ref="TrendingCarousel" @slide="updateCarouselTrending">
                 <Slide v-for="(post) in viewTrendingPostsData" :key="post.post_id">
                   <div class="p-2">
                      <BringinCard 
                      v-bind:post="post" 
                      v-bind:handle="post.handle"
                      v-bind:profile_pic="post.profile_pic"
                      />
                    </div>
                   </Slide>
                  <!-- <hooper-navigation slot="hooper-addons"></hooper-navigation> -->
               </Hooper>
                <div class="d-flex justify-content-center">
                  <button @click.prevent="slidePrevTrending" class="arrow-prev"><i class="fa-solid fa-arrow-left"></i></button>
                  <button @click.prevent="slideNextTrending" class=" arrow-next ml-2"><i class="fa-solid fa-arrow-right "></i></button>
                </div>
              </div>
          </div>
          <div class="featured-container ">
            <div class="d-flex justify-content-between">
              <p class="text-light fs-3 fw-bold">Featured Brands</p>
              <router-link to="/featuredbrands"><p class="secont-primary-color fs-5 fw-bold m-1 curser-point">See All</p></router-link>
            </div>
            <div>
               <Hooper  :settings="responsiveSetting" style="height:100%" ref="brandCarousel" @slide="updateCarouselBrand">
                 <Slide  v-for="brand of viewFormData.brand" :key="brand.brand_id">
                    <div v-if="brand.brand_name!=''&& brand.image!=null">
                      <router-link :to='"/brand/"+brand.brand_id'><FeaturedBrands :brandName="brand.brand_name" :brandImgUrl="brand.image"/></router-link>
                    </div>
                  </Slide>
               </Hooper>
                <div class="d-flex justify-content-center">
                  <button @click.prevent="slidePrevBrand" class="arrow-prev"><i class="fa-solid fa-arrow-left"></i></button>
                  <button @click.prevent="slideNextBrand('brand')" class=" arrow-next ml-2"><i class="fa-solid fa-arrow-right "></i></button>
                </div>
            </div>
          </div>
          <!-- <div class="featured-container my-4 p-3 rounded">
            <div class="d-flex justify-content-between">
              <p class="text-light fs-5 fw-bold">Discover Live Events</p>
              <p class="secont-primary-color fs-5 fw-bold">See All</p>
            </div>
            <div class="row" style="max-height:  668px;overflow-y: auto;overflow-x: hidden;">
              <div  v-for="post in viewDiscoverLiveEventsData" :key="post.id" class="col-6 col-md-3 col-sm-4">
                <div class="mb-3" > 
                  <DiscoverCard v-bind:post="post"/>
                </div>
              </div>   
            </div>
          </div> -->
          <div class="offers-container">
            <div class="d-flex justify-content-between">
              <p class="text-light fs-3 fw-bold">Offers</p>
              <router-link to="/Offers"><p class="secont-primary-color fs-5 fw-bold">See All</p></router-link>
            </div>
            <div class="row" >
              <div class="col-12">
                <Offers v-bind:viewHomepageOffersData="viewHomepageOffersData" />
              </div>
            </div>
          </div>
          <div class="follow-container">
            <p class="text-light fs-3 fw-bold">People you may follow</p>
            <div>
               <Hooper :settings="responsiveSetting" style="height:100%" ref="carousel" @slide="updateCarousel">
                 <Slide v-for="people of viewSuggestedForYouData" :key="people.id">
                    <router-link :to="'/profile/'+people.handle">
                      <Follow v-bind:people="people"/>
                    </router-link>
                   </Slide>
               </Hooper>
                <div class="d-flex justify-content-center mt-4">
                  <button @click.prevent="slidePrev" class="arrow-prev"><i class="fa-solid fa-arrow-left"></i></button>
                  <button @click.prevent="slideNext" class=" arrow-next ml-2"><i class="fa-solid fa-arrow-right "></i></button>
                </div>
              </div>
          </div>
        </div> 
        <!-- <div class="col-12 col-md-4 ">
           <div class="d-flex justify-content-start mt-4 p-3 text-light">
            <p class="px-4 align-middle fs-5 fw-bold mt-3">
              <img
                src="../../../assets/images/avatar.jpg"
                alt="Avatar"
                class="avatar me-3"
              />
              User Name
            </p>
          </div>
          <div class="mx-4 p-3 mt-2">
            <div class="d-flex justify-content-between">
              <p class="text-light fs-5 fw-bold">Suggestions for you</p>
              <p class="secont-primary-color fw-bold">See All</p>
            </div>
            <div v-for="people of viewSuggestedForYouData" :key="people.id">
            <div class="d-flex justify-content-between">
              <router-link :to="'/profile/'+people.handle">
                <p class="pe-4 align-middle text-light fw-bold">
                <img
                  :src="people.profile_pic"
                  alt="Avatar"
                  class="avatar-suggest me-3"
                  v-if="people.profile_pic"
                />
                <img
                  src="../../../assets/images/dummy.png"
                  alt="Avatar"
                  class="avatar-suggest me-3"
                  v-if="!people.profile_pic"
                />
                {{people.handle}}
              </p>
              </router-link>
              <p class="secont-primary-color fw-bold cursor-point" @click="followPeople(people.id)">Follow</p>
            </div>
            </div>
          </div>
        </div>  -->
    <Footer :homepage="true"/>
  </div>
</template>
<script>
import axios from 'axios';
import { Hooper, Slide,Navigation as HooperNavigation } from 'hooper';
import 'hooper/dist/hooper.css';
import BringinCard from '../../common/Card/BringinCard.vue'
import Navbar from "../../common/Navbar/Navbar.vue";
import Category from "./Category.vue";
import DiscoverCard from "../DiscoverLiveEvents/DiscoverCard.vue";
import Offers from "./Offers.vue";
import Follow from "./Follow.vue";
import FeaturedBrands from "./FeaturedBrands.vue";
import Footer from "../../common/Footer/Footer.vue"
export default {
  name: "Home",
  components: {
    BringinCard,
    Category,
    DiscoverCard,
    Offers,
    Follow,
    FeaturedBrands,
    Navbar,
    Footer,Hooper,Slide,HooperNavigation
  },
   data(){
        return{
            userId:JSON.parse(localStorage.userData).user.id,
            viewFormData:[],
            viewTrendingHashtags:[],
            viewTrendingPostsData:{},
            viewDiscoverLiveEventsData:{},
            viewSuggestedForYouData:{},
            viewHomepageOffersData:{},
            howItWorkData: {
              englishId: '',
              hindiId: '',
              currentId: ''
            },
            responsiveSetting:{
              itemsToShow: 4,
              itemsToSlide:4,
              breakpoints: {
                540: {
                  itemsToShow: 4,
                  itemsToSlide: 4,
                },
                720: {
                  itemsToShow: 5,
                  itemsToSlide: 5,
                },
                960: {
                  itemsToShow: 7,
                  itemsToSlide: 7,
                },
                1140: {
                  itemsToShow: 8,
                  itemsToSlide: 8,
                },
                1350: {
                  itemsToShow: 9,
                  itemsToSlide: 9,
                },
                 1600: {
                  itemsToShow: 12,
                  itemsToSlide: 12,
                }
              }
            },
            trendingResponsiveSettings:{
              itemsToShow: 2,
              itemsToSlide:2,
              breakpoints: {
                720: {
                  itemsToShow: 3,
                  itemsToSlide: 3,
                },
                960: {
                  itemsToShow: 4,
                  itemsToSlide: 4,
                }
              }
            }
        };
    },
    mounted(){
     this.dataAPIs();
    },

    methods:{
        slidePrev() {
        this.$refs.carousel.slidePrev();
        },
        slideNext() {
          this.$refs.carousel.slideNext();
        },
        updateCarousel(payload) {
          this.myCarouselData = payload.currentSlide;
        },

        slidePrevCategory() {
          this.$refs.updateCarousel.slidePrev();
        },
        slideNextCategory() {
          this.$refs.updateCarousel.slideNext();
        },
        updateCarouselCategory(payload) {
          this.myCarouselData = payload.currentSlide;
        },

        slidePrevTrending() {
          this.$refs.TrendingCarousel.slidePrev();
        },
        slideNextTrending() {
          this.$refs.TrendingCarousel.slideNext();
        },
        updateCarouselTrending(payload) {
          this.myCarouselData = payload.currentSlide;
        },

        slidePrevBrand() {
          this.$refs.brandCarousel.slidePrev();
        },
        slideNextBrand() {
          this.$refs.brandCarousel.slideNext();
        },
        updateCarouselBrand(payload) {
          this.myCarouselData = payload.currentSlide;
        },
        dataAPIs(){
            let token = JSON.parse(localStorage.userData).token
            axios.defaults.headers.common['token'] = token
            this.getAPIsData();   
        },
        getAPIsData(){
            this.getFormData();
            this.getTrendingHashtags();
            this.getTrendingPosts();
            this.getDiscoverLiveEvents();
            this.getSuggestedForYou();
            this.getHomepageOffers();
            this.getBannerVideoLink();
        },
        getBannerVideoLink(){
          let self = this
            let url = "/api/v1/message/allunread/"
            axios.get(url).then(function(res){
                // console.log("res",res.data.);
                let resData = res.data
                
                if(resData.success)
                {
                  self.howItWorkData.englishId = resData.data.data.video_link
                  self.howItWorkData.hindiId = resData.data.data.video_link_hindi                }
                else
                {
                    alert("Invalid ")
                }
            });
        },
        openHowItWorkModal(){
          this.howItWorkData.currentId = this.howItWorkData.englishId
          this.playVideoNow()
        },
        playVideoNow(){
            let self = this
            let video = self.$refs['howitwork_hindi_english']
            video.load()
            video.currentTime = 0;
            video.play()
        },
         stopVideoNow(){
            let self = this
            let video = self.$refs['howitwork_hindi_english']
            video.pause()
          
        },
        changeLanguage(language){    
          this.howItWorkData.currentId = this.howItWorkData[language]
          this.playVideoNow();
        },
        getFormData(){
            let self = this
            let url = "/api/v1/get/formdata"
            axios.get(url).then(function(res){
                // console.log("res",res.data.);
                let resData = res.data
                if(resData.success)
                {
                    self.viewFormData=resData.data;
                }
                else
                {
                    alert("Invalid ")
                }
            });
        },
        getTrendingHashtags(){
          let self = this
          let url = "/api/v1/homepage/trendinghashtagsv2/"
          let payload={}
          axios.post(url,payload).then(function(res){
            // console.log("res",res.data.);
            let resData = res.data
            if(resData.success)
            {
              self.viewTrendingHashtags=resData.data;
            }
            else
            {
              alert("Invalid User")
            }
          });
        },
        getTrendingPosts(){
          let self = this
          let arr = []
          self.viewTrendingPostsData = []
          let url = "/api/v1/homepage/trendingpostsv2/"
          let payload={
              "language": [],
              "interest_id": [],
              "brand_id": '',
              "force_refresh": true
          }
          axios.post(url,payload).then(function(res){
            // console.log("res",res.data.);
            let resData = res.data
            if(resData.success)
            {
              for(let [idx,data] of resData.data.data.entries()  )
              {
                if(data.thumbnail!=null && data.profile_pic!=null)
                {
                  arr.push(data)
                }
              }
              self.viewTrendingPostsData = arr
            }
            else
            {
              alert("Invalid User")
            }
          });
        },
        getDiscoverLiveEvents(){
          let self = this
          self.viewDiscoverLiveEventsData = []
          let arr = []
          let url = "/api/v1/homepage/discover/liveevents/"
          let payload={
              "language": [],
              "interest_id": [],
              "brand_id": '',
              "force_refresh": true
          }
          axios.post(url,payload).then(function(res){
            // console.log("res",res.data.);
            let resData = res.data
            if(resData.success)
            {
              for(let [idx,data] of resData.data.data.entries())
              {
                if(data.thumbnail!=null && data.profile_pic!=null)
                {
                  arr.push(data)
                }
              }
              self.viewDiscoverLiveEventsData = arr
            }
            else
            {
              alert("Invalid User")
            }
          });
        },
        getHomepageOffers(){
          let self = this
          let url = "/api/v1/all/offers/"
          axios.get(url).then(function(res){
            // console.log("res",res.data.);
            let resData = res.data
            if(resData.success)
            {
              self.viewHomepageOffersData=resData.data;
            }
            else
            {
              alert("Invalid User")
            }
          });
        },
        followPeople(userId){
            let self=this
            let url = "/api/v1/userfollows/"
            let payload={
                "followee_id":userId,
            }
            axios.put(url,payload).then(function(res){
                let resData = res.data
                if(resData.success)
                {
                    self.getSuggestedForYou();
                }
                else
                {
                   console.log('comment api error')
                }
            });
        },
        getSuggestedForYou(){
          let self = this
          let url = "/api/v1/homepage/suggestedforyou/"
          axios.post(url).then(function(res){
            // console.log("res",res.data.);
            let resData = res.data
            if(resData.success)
            {
              self.viewSuggestedForYouData=resData.data.data;
            }
            else
            {
              alert("Invalid User")
            }
          });
        },
    }
    
};
</script>

<style scoped>
.bringin-container{
  padding:45px 4% 60px 4%;
}

.secont-primary-color {
  color: #B8F009;
}
.cursor-point{
  cursor: pointer;
}
#banner-container{
 position:relative;
  width:fit-content;
  width: 100%;
}
#grad{
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  mix-blend-mode: normal;
  border: 1px solid #000000;
  /* box-sizing: border-box; */
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}
#grad2{
  position: absolute;
  width:100%;
  height:100%;
  background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.78) 100%);
}

#banner-container img{
  width:100%;
  height:100%;
}
.banner-title{
  position: absolute;
  bottom: 5%;
  left:5%;
  font-weight: 550;
  font-size: 60px;
}
.banner-icon{
  border:3px solid #B8F009;
  color:#B8F009;
  cursor: pointer;
  font-size: 48px !important;
}
/* modal css */
.b-modal-content{
  background: #343434;
  padding: 0px;
}
.language-filter{
  display: flex;
  justify-content: center;
  padding-left: 69px;
}
.modal-header{
  padding:0px
}
.modal-body{
  padding: 0px;
}

.modal-title-english{
  font-size:16px;
  /* background: #B8F009; */
  background: black;
  color: white;
  padding: 5px 30px;
  border-radius: 25px 0 0 25px;
}
.modal-title-hindi{
  font-size:16px;
  /* background: #B8F009; */
  background: black;
  color: white;
  padding: 5px 30px;
  border-radius: 0 25px 25px 0px;
}
.activeVideo{
  background: #B8F009; 
  color: #000;
}
.arrow-prev{
  background: none;
  border:1px solid #fff;
  border-radius: 50%;
  color:#fff;
  width:30px;
  height: 30px;
  font-size:14px;
}
.arrow-next{
  background: #B8F009;
  border:1px solid #B8F009;
  border-radius: 50%;
  color:#080808;
  width:30px;
  height: 30px;
  font-size:14px;

}

.avatar {
  vertical-align: middle;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.categories{
  overflow: hidden;
  padding:50px 4% 60px 4%;
}
.trending-container {
  background: #343434;
  overflow: hidden;
  padding:50px 4% 60px 4%;

}

.trending-pills-container {
  overflow: hidden;
  text-overflow: clip;
}

.trending-pills-bedge {
  background-color: #b8f009;
  color: #000;
}

.trending-pills-bedge:hover {
  cursor: pointer;
}

.featured-container {
  overflow: hidden;
  padding:50px 4% 60px 4%;

}
.offers-container {
  overflow: hidden;
  padding:50px 4% 60px 4%;
}
.follow-container {
  background: #343434;
  overflow: hidden;
  padding:50px 4% 60px 4%;
}
.avatar-suggest {
  vertical-align: middle;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
a{
  text-decoration: none;
}

</style>
