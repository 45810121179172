<template>
  <div class="m-2 ">
          <div class="d-flex justify-content-center">
            <img  :src="brandImgUrl" class="bg-light brandsIcon" :class="{brandsListIcon:brandslist}"/>
          </div>
          <p class="brands-name mt-2">{{brandName}}</p>
  </div>
</template>

<script>
export default {
  name: 'FeaturedBrands',
  props:['brandName','brandImgUrl','brandslist'],
}
</script>

<style scoped>
    .brandsIcon{
       vertical-align: middle;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        padding:5px;
        
    }
     .brandsListIcon{
       vertical-align: middle;
        width: 160px;
        height: 160px;
        border-radius: 50%;
        padding:5px;
    }
    .brands-name{
        margin: 0 0 -3px 0;
        font-weight: 500;
        font-size:14px;
        padding: 0px;
        color: #fff;
        text-align: center;
    }
</style>>

