<template>
    <div class="container-fluid mt-4 overflow-x-hidden">
        <section class="logo d-flex justify-content-center align-items-center w-100">
            <img src="../../../../assets/images/campus-ambassador/bringin logo.png" alt="bringin-logo"
                style="width:36%">
        </section>
        <div class="line-separator mt-3"></div>
        <div class="mt-4 w-100">
            <form class="w-100" onsubmit="return false">
                <div class="mt-4 form-group">
                    <div class="top_box">
                        <span class="title">Name<span class="text-light">*</span></span>
                    </div>
                    <div class="box d-flex justify-content-center align-items-center"><input v-model="details.name"
                            placeholder="Enter your name" color="white" class="form-control form-control-lg" required>
                    </div>
                </div>
                <div class="h-25 mt-4">
                    <div class="top_box" style="z-index: 100">
                        <span class="title">College</span>
                    </div>
                    <div class="box d-flex justify-content-center align-items-center position-relative">
                        <select color="white" class="form-control form-control-lg w-100" v-model="details.college">
                            <option value="">Select your college</option>
                            <option v-for="(college,index) in collegeList" :value="college" :key="index" color="white"
                                class="form-control form-control-lg w-100">{{college}}</option>
                        </select>
                        <i class="fa fa-angle-down dropdownAngle" aria-hidden="true"></i>
                    </div>
                </div>
                <div class="h-25 mt-4">
                    <div class="top_box">
                        <span class="title">Email<span class="text-light">*</span></span>
                    </div>
                    <div class="box d-flex justify-content-center align-items-center"><input v-model="details.email"
                            placeholder="Enter you email" type="email" color="white"
                            class="form-control form-control-lg w-100" required>
                    </div>
                </div>
                <div class="h-25 mt-4">
                    <div class="top_box">
                        <span class="title">Mobile No.<span class="text-light">*</span></span>
                    </div>
                    <div class="box d-flex justify-content-center align-items-center"><input type="text"
                            v-model="details.mobile_no" placeholder="Enter your mobile"
                            class="form-control form-control-lg w-100" required></div>
                </div>
                <div class="h-25 mt-4">
                    <div class="top_box">
                        <span class="title">Date Of Birth<span class="text-light">*</span></span>
                    </div>
                    <div class="box d-flex justify-content-center align-items-center"><input
                            v-model="details.date_of_birth" type="date" class="form-control form-control-lg w-100">
                    </div>
                </div>
                <div class="h-25 mt-4">
                    <div class="top_box" style="z-index: 100">
                        <span class="title">Gender<span class="text-light">*</span></span>
                    </div>
                    <div class="box d-flex justify-content-center align-items-center position-relative">
                        <select color="white" class="form-control form-control-lg w-100" v-model="details.gender"
                            required>
                            <option value="">Select your gender</option>
                            <option v-for="(gender,index) in genderList" :value="gender" :key="index" color="white"
                                class="form-control form-control-lg w-100">{{gender}}</option>
                        </select>
                        <i class="fa fa-angle-down dropdownAngle" aria-hidden="true"></i>
                    </div>
                </div>
                <div class="h-25 mt-4">
                    <div class="top_box" style="z-index: 100">
                        <span class="title">City</span>
                    </div>
                    <div class="box d-flex justify-content-center align-items-center position-relative">
                        <select color="white" class="form-control form-control-lg w-100" v-model="details.city">
                            <option value="">Select your city</option>
                            <option v-for="(city,index) in cityList" :value="city" :key="index" color="white"
                                class="form-control form-control-lg w-100">{{city}}</option>
                        </select>
                        <i class="fa fa-angle-down dropdownAngle" aria-hidden="true"></i>
                    </div>
                </div>
                <div class="h-25 mt-4">
                    <div class="top_box">
                        <span class="title">Insta Id</span>
                    </div>
                    <div class="box d-flex justify-content-center align-items-center"><input type="text"
                            v-model="details.insta_id" placeholder="Enter your insta id"
                            class="form-control form-control-lg w-100"></div>
                </div>
                <div class="h-25 mt-4">
                    <div class="top_box" style="z-index: 100">
                        <span class="title">College Course<span class="text-light">*</span></span>
                    </div>
                    <div class="box d-flex justify-content-center align-items-center position-relative">
                        <select color="white" class="form-control form-control-lg w-100"
                            v-model="details.college_course" required>
                            <option value="">Select a college course</option>
                            <option v-for="(course,index) in courseList" :value="course" :key="index" color="white"
                                class="form-control form-control-lg w-100">{{course}}
                            </option>
                        </select>
                        <i class="fa fa-angle-down dropdownAngle" aria-hidden="true"></i>
                    </div>
                </div>
                <div class="d-flex justify-content-center align-items-center mt-4 mb-3">
                    <button class="btn btn-primary bringin-btn btn-lg btn-block" @click="submitForm"><a>Submit</a>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
export default {
    name: 'CampusAmbassadorRegister',
    data() {
        return {
            details: {
                name: "",
                college: "",
                email: "",
                mobile_no: "",
                date_of_birth: '',
                gender: "",
                city: "",
                insta_id: "",
                college_course: ""
            },
            genderList: ['Female', 'Male', 'Other'],
            cityList: ['Delhi', 'Gurugram', 'Noida'],
            collegeList: ["Daulat Ram College", "Hansraj College", "Hindu College", "Indraprastha College for Women", "Kalindi College", "Kirori Mal College", "Lady Irwin College", "Lakshmibai College", "Maharaja Agrasen College", "Miranda House", "Ramjas College", "Satyawati College", "School of Open Learning", "Shri Ram College of Commerce", "Shyam Lal College", "Sri Guru Tegh Bahadur Khalsa College", "St. Stephen's College"],
            courseList: ["Graduate", "Higher Secondary", "Post Graduate", "Senior Secondary"],
        }
    },
    methods: {
        submitForm() {
            let self = this
            let url = "/api/v1/register/campusambassador"
            let payload = {
                "name": this.details.name,
                "college": this.details.college,
                "email": this.details.email,
                "mobile_no": this.details.mobile_no,
                "date_of_birth": moment(this.details.date_of_birth).format("YYYY-MM-DD"),
                "gender": this.details.gender,
                "city": this.details.city,
                "insta_id": this.details.insta_id,
                "college_course": this.details.college_course
            }
            if (this.details.name && this.details.email && this.details.mobile_no && this.details.date_of_birth && this.details.gender && this.details.college_course) {
                if (!this.validateEmail(this.details.email)) {
                    alert("Invalid email address")
                    return false
                }
                if (!this.validatePhone(this.details.mobile_no)) {
                    alert("Invalid phone number")
                    return false
                }
                console.log(this.details)
                axios.post(url, payload).then((res) => {
                    self.details.name = "",
                    self.details.college = "",
                    self.details.email = "",
                    self.details.mobile_no = "",
                    self.details.date_of_birth = '',
                    self.details.gender = "",
                    self.details.city = "",
                    self.details.insta_id = "",
                    self.details.college_course = ""
                    alert("Registered")
                }).catch(err => alert(err))
            }
            else {
                alert("Please fill all the required fields")
                return false
            }
        },
        validateEmail(email) {
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        validatePhone(mob_no) {
            let re = /^(\+\d{1,3}[- ]?)?\d{10}$/;
            return re.test(mob_no);
        }
    },
}
</script>

<style scoped>
.title {
    background-color: #212529;
    font-size: 14px;
    color: #ffffff;
    padding: 0 5px;

}

.dropdownAngle {
    position: absolute;
    left: 100;
    top: 50;
    bottom: 50;
    right: 0;
    margin-right: 2%
}

.top_box {
    text-align: left;
    display: block;
    line-height: 0;
    height: 0;
    position: relative;
    left: 5px;
    font-size: 12px;
}

select {
    background-color: #202529;
    color: white;
    border: 2px solid #202529;
    font-size: 12px;
}

select:focus {
    background-color: #202529;
    color: white;
    border: 2px solid #202529;
    font-size: 12px;
}

.box {
    border: 2px solid #4f4f4f;
    text-align: justify;
    color: white;
    border-radius: 7px;
}

.box>input {
    border: 0;
    outline: 0;
    background-color: #212529;
    color: aliceblue;
    font-size: 12px;
}

::placeholder {
    /* Most modern browsers support this now. */
    color: #4f4f4f;
}


.line-separator {
    border: 1px solid #343434;
}

.bringin-btn {
    background-color: #212121;
    color: #B8F009;
    outline: none;
    border: 2px solid #B8F009;
    border-radius: 10px;
}

.bringin-btn:hover {
    background-color: #212121;
    color: #B8F009;
    outline: none;
    border: 2px solid #B8F009;
    border-radius: 10px;
}

.bringin-btn:focus {
    background-color: #212121;
    color: #B8F009;
    outline: none;
    border: 2px solid #B8F009;
    border-radius: 10px;
}

a {
    text-decoration: none;
    color: #B8F009;

}

a:focus {
    text-decoration: none;
    color: #B8F009;
}
</style>