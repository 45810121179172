<template>
    <div class="container-fluid mt-4 overflow-x-hidden">
        <section class="logo d-flex justify-content-center align-items-center w-100" id="banner-container">
            <img src="../../../../assets/images/campus-ambassador/bringin logo.png" alt="bringin-logo"
                style="width:36%">
        </section>
        <div class="line-separator mt-3">
        </div>
        <section>
            <div class="heading text-light d-flex justify-content-center align-items-center mt-5">
                <div class="h2">Who is a Bringinfluencer?</div>
            </div>
            <div class="content text-light d-flex justify-content-center align-items-center mt-3">
                <div class="text-center p">A creator, a reviewer and most importantly, a straight shooter - a
                    BringInfluencer is a student that's driven by the truth and wants to build a community around it!
                    From fests to events, your work will be rewarded through vouchers, merch, certificates and a lot
                    more!
                    <br>
                    <br>
                    Ready to start your influencer journey?
                </div>
            </div>
        </section>
        <div class="d-flex justify-content-center align-items-center mt-3">
            <button class="btn btn-primary bringin-btn btn-lg btn-block"><a href="/campus-ambassador-register">Apply
                    Now</a></button>
        </div>
        <section class="d-flex justify-content-center align-items-center flex-column mt-3 w-100">
            <div class="d-flex justify-content-center align-items-center" style="position: relative">
                <img src="../../../../assets/images/campus-ambassador/campus-ambassdor-frame.png" alt="video-banner"
                    class="w-100">
                <div data-bs-toggle="modal" data-bs-target="#exampleModal" @click="openCampusAmbassadorModal"
                    class="playBtnPosition">
                    <i class="fa-solid fa-play  banner-icon rounded-circle"></i>
                </div>
            </div>
            <div class="banner-title d-flex justify-content-start">
                <!-- Button trigger modal -->
                <!-- Modal -->
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog " style="width: 95%">
                        <div class="modal-content b-modal-content">
                            <div class="modal-header">
                                <button type="button" class="btn fs-3 text-light" data-bs-dismiss="modal"
                                    aria-label="Close" @click="stopVideoNow">
                                    <i class="fa-solid fa-xmark text-light"></i>
                                </button>
                            </div>
                            <div class="modal-body d-flex justifyyy-content-center align-items-center"
                                style="width: 100%">
                                <video class="bg-black mb-4" style="width: 100%" ref="bringinfluencer_video" controls>
                                    <source src="../../../../assets/images/campus-ambassdor.mp4" type="video/mp4">
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
        <section>
            <div class="card-container">
                <div>
                    <div v-for="(item, index) in cardItems" :key="item+index">
                        <div class="d-flex align-items-center mt-2">
                            <div class="text-light fs-3 fw-bold">{{item.heading}}</div>
                        </div>
                        <div class="d-flex justify-content-center align-items-center mt-3 w-100">
                            <img :src="require(`@/assets/images/campus-ambassador/${item.imgName}`)" alt=""
                                class="w-100">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="section d-flex justify-content-center align-items-center">
            <div>
                <a class="btn btn-color rounded m-1 rounded p-1 footer-font-size"
                    href="https://play.google.com/store/apps/details?id=com.lyxel.bringin" target="_blank">
                    <font-awesome-icon :icon="['fab','google-play']" size="lg" class="" />
                    Get it on
                    <span class="fw-bold">Google Play</span>
                </a>
                <a class="btn btn-color rounded m-1 p-1 footer-font-size"
                    href="https://apps.apple.com/in/app/bringin/id1579153016" target="_blank">
                    <font-awesome-icon :icon="['fab','apple']" size="lg" class="" />
                    Download on the
                    <span class="fw-bold">App Store</span>
                </a>
            </div>
        </div>
        <div class="line-separator mt-2"></div>
        <footer class="d-flex justify-content-center align-items-center">
            <div class="footer-text">All Rights Reserved &copy; 2022 Bringin'</div>
        </footer>
    </div>
</template>

<script>
export default {
    name: 'CampusAmbassadorLanding',
    data() {
        return {
            cardItems: [
                {
                    heading: "Fashionista 💁🏻‍♀️👠",
                    imgName: 'fashionista.jpg'
                },
                {
                    heading: "Foodie by heart 🍔🍕",
                    imgName: 'foodie.jpg'
                },
                {
                    heading: "Fitness freak 🏋🏻‍♂️🧘🏼",
                    imgName: 'fitness freak.jpg'
                },
                {
                    heading: "Obsessive gamer 🎮🎧",
                    imgName: 'gamer.jpg'
                },
                {
                    heading: "Cosmoholic 💄💅🏻",
                    imgName: 'cosmoholic.jpg'
                },
                {
                    heading: "Skincare junkie 🧖🏻‍♀️🧖🏻‍♀️",
                    imgName: 'skincare junkie.jpg'
                },
                {
                    heading: "Sneakerheads 👟",
                    imgName: 'sneakerhead.jpg'
                },

            ],

        }
    },
    methods: {
        openCampusAmbassadorModal() {
            this.playVideoNow()
        },
        stopVideoNow() {
            let self = this
            let video = self.$refs['bringinfluencer_video']
            video.pause()
        },
        playVideoNow() {
            let self = this
            let video = self.$refs['bringinfluencer_video']
            video.load()
            video.currentTime = 0;
            video.play()
        },
    }
}
</script>

<style>
.overflow-x-hidden {
    overflow-x: hidden;
}

.section {
    margin-top: 5px;
    text-align: center;
    color: #fff;
}

.footer-font-size {
    font-size: 10px !important;
}

.btn-color {
    background-color: #B8F009;
}

.playBtnPosition {
    position: absolute;
    left: 50;
    right: 50;
    top: 50;
    bottom: 50;
    margin: auto
}

.footer-text {
    font-size: 8px;
    color: #6A6A6A;
    line-height: 11px;
    margin-block: 2%;
}

.line-separator {
    border: 1px solid #343434;
}

.bringin-btn {
    background-color: #212121;
    color: #B8F009;
    outline: none;
    border: 2px solid #B8F009;
    border-radius: 10px;
}

.bringin-btn:hover {
    background-color: #212121;
    color: #B8F009;
    outline: none;
    border: 2px solid #B8F009;
    border-radius: 10px;
}

.bringin-btn:focus {
    background-color: #212121;
    color: #B8F009;
    outline: none;
    border: 2px solid #B8F009;
    border-radius: 10px;
}

.card-container {
    background: #343434;
    overflow: hidden;
    padding: 10px 4% 10px 4%;

}

.second-primary-color {
    color: #B8F009;
}

.cursor-point {
    cursor: pointer;
}

a {
    text-decoration: none;
    color: #B8F009;
}

a:focus {
    text-decoration: none;
    color: #B8F009;
}

#banner-container {
    position: relative;
    width: fit-content;
    width: 100%;
}

#banner-container img {
    width: 100%;
    height: 100%;
}

.banner-title {
    position: absolute;
    bottom: 5%;
    left: 5%;
    font-weight: 550;
    font-size: 60px;
}

.banner-icon {
    border: 3px solid #B8F009;
    color: #B8F009;
    cursor: pointer;
    font-size: 48px !important;
}

/* modal css */
.b-modal-content {
    background: #343434;
    padding: 0px;
}

.modal-header {
    padding: 0px
}

.modal-body {
    padding: 0px;
}
</style>