<template>
  <div class="m-2">
          <div class="d-flex justify-content-center">
            <img  :src="data.icon_new" class="brands-icon" :class="{categoryListIcon:categoryList}"/>
          </div>
          <p class="brands-name mt-2">{{data.interest_name}}</p>
  </div>
</template>

<script>
export default {
  name: 'Category',
  props:['data','categoryList'],
}
</script>

<style scoped>
    .brands-icon{
       vertical-align: middle;
        width: 140px;
        height: 140px;
        border-radius: 50%;
        /* padding:5px; */
        background:transparent;

    }
    .categoryListIcon{
       vertical-align: middle;
        width: 190px;
        height: 190px;
        border-radius: 50%;
        padding:5px;
    }
    .brands-name{
        margin: 0 0 -3px 0;
        font-weight: 500;
        font-size:14px;
        padding: 0px;
        color: #fff;
        text-align: center;
    }
</style>>

