<template>
    <div>
        <Navbar/>
        <div class="bringin-container">
            <div class="row my-4 text-light d-flex justify-content-center">
                <div class="d-flex justify-content-center" v-if="loader">
                    <Loader/>
                </div>
                <div class="col-12">
                    <h2 class="text-light fw-bold text-center mb-3">Featured Brands</h2>
                    <div class="row ">
                        <div class="row text-light">
                            <div class="d-flex justify-content-center mt-4" v-if="loader">
                                <Loader/>
                            </div>
                            <div v-for="product of viewOffersData" :key="product.id" class="col-12 my-4">
                                <div class="offer-brand-container">
                                    <a :href="product.link" target="_blanck">
                                        <img :src="product.image_link" class="offer-brand img-fluid"/>
                                    </a>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>
<script>
import axios from 'axios'
import Navbar from '../../common/Navbar/Navbar.vue'
import Loader from '../../common/Loader/Loader.vue'
import BringinCard from '../../common/Card/BringinCard.vue'
import Footer from "../../common/Footer/Footer.vue"
import FeaturedBrands from "../Home/FeaturedBrands"

export default{
    name: "AllOffersList",
    components: {Navbar,Loader,BringinCard,Footer,FeaturedBrands},
    data(){
        return{
            loader:true,
            viewOffersData:[],
        }
    },
    mounted(){
     this.dataAPIs();
    },
    methods:{
        dataAPIs(){
            let token = JSON.parse(localStorage.userData).token
            axios.defaults.headers.common['token'] = token
            this.getAPIsData();
        },
        getAPIsData(){
            this.getFormData();
        },
        getFormData(){
            let self = this
            self.loader=true
            let url = "/api/v1//all/offers/"
            axios.get(url).then(function(res){
                // console.log("res",res.data.);
                let resData = res.data
                if(resData.success)
                {
                    self.viewOffersData=resData.data;
                    self.loader=false;

                }
                else
                {
                    alert("Invalid ")
                }
            });
        },
    }
}
</script>
<style scoped>
.bringin-container{
    padding:0 4% 0 4%;
}
a{
    text-decoration: none;
}
 .offer-brand-container{
        background: white;
        padding: 5px;
        display: flex;
        justify-content: center;
    }
</style>