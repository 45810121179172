<template>
  <div class="m-2 position-relative ">
    <div class="plus-badge"><i class="fa-solid fa-plus"></i></div>
    <div class="position-relative">
        <img :src="people.profile_pic" class="status-icon p-1 "/>
    </div>
    <p class="status-name mt-2">  
      {{people.handle}}</p>
  </div>
</template>

<script>
export default {
  name: "Follow",
  props:['people']
};
</script>

<style scoped>

.status-icon {
  width:100%;
  vertical-align: middle;
  width: 130px;
  height: 130px;
  border-radius: 50%;
}
.status-name {
  margin: 0 0 -3px 0;
  font-weight: 500;
  font-size:14px;
  padding: 0;
  color: #fff;
  text-align: center;
}
.plus-badge{
    text-align: center;
    background: #B8F009;
    border: 1px solid #B8F009;
    border-radius: 50%;
    line-height: 35px;
    color: #080808;
    width: 35px;
    height: 35px;
    font-size: 18px;
    position: absolute;
    bottom: 51px;
    right: -4px;
    z-index: 1;
}
</style>>

