<template>
    <div>
        <p class="text-light me-5 fw-bold m-0 curser-point" data-bs-toggle="modal" data-bs-target="#followingList">{{followingNumber}} following</p>               
        <div class="modal fade" id="followingList" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered  modal-sm modal-dialog-scrollable">
                <div class="modal-content modal-bg">
                    <div class="modal-header">
                        <h5 class="modal-title text-light modal-align fw-bold">Following</h5>
                        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <!-- {{viewFollowingData}} -->
                    <div class="modal-body border-section">
                        <div v-for="row of viewFollowingData" :key="row.id">
                            <router-link :to="'/profile/'+row.handle" style="text-decoration:none" data-bs-dismiss="modal">
                                <p  class="pe-4 align-middle text-light fw-bold curser-point" > 
                                    <img
                                    :src="row.profile_pic"
                                    alt="Avatar"
                                    class="avatar-suggest me-3"
                                    v-if="row.profile_pic"
                                    />
                                    <img
                                        src="../../../assets/images/dummy.png"
                                        alt="Avatar"
                                        class="avatar-suggest me-3"
                                        v-if="!row.profile_pic"
                                    />
                                    {{row.name}}
                                </p>
                            </router-link>
                        </div>
                    </div>                  
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    export default{
        name:'FollowingList',
        props:['followingNumber','userId'],
    data(){
        return{
            userId:this.userId,
            viewFollowingData:[]
        };
    },

    mounted(){
     this.dataAPIs();
    },

    methods:{
        dataAPIs(){
            this.getAPIsData();
        },
        getAPIsData(){
           this.getViewFollowing();
        },
        getViewFollowing(){
            let self = this
            let url = "/api/v1/viewfollowing/?profile_userid="+this.userId
            axios.get(url).then(function(res){
                // console.log("res",res.data.);
                let resData = res.data
                if(resData.success)
                {
                    self.viewFollowingData=resData.data.data;
                }
                else
                {
                    alert("Invalid User")
                }
            });
        }
    },
    }    
</script>
<style scopped>
    .modal-header{
        border:none !important;
    }
    .second-primary-color{
            color: #b8f009;
    }
    .modal-align{
        text-align: center !important;
    }
    .modal-bg{
        background: #202020;
    }
    .border-section{
        border-top:1px solid #303030;
    }
    .curser-point{
        cursor: pointer;
    }
    .avatar-suggest {
        vertical-align: middle;
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
</style>