<template>
    <div>
        <!-- Button trigger modal -->
        <div class="input-group">
            <p class="flex-fill mb-3 curser-point bringin-input" aria-label="Recipient's username" aria-describedby="button-addon2" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Add your products</p>
            <button class="btn bringin-btn text-light" type="button" id="button-addon2" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Add</button>
        </div>
        <p class="text-light fw-bold">Products({{selectedProductTagsArray.length}})</p>
        <!-- Modal -->
        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable ">
            <div class="modal-content  modal-bg">
                <div class="modal-header">
                    <h5 class="modal-title modal-align">Products</h5>
                    <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body border-section">
                   <div class="row">
                       <div v-for="cardData in viewProductListData" :key="cardData" class="col-4 mb-2">
                            <ProductCard v-bind:cardData="cardData" v-bind:selectedProductTagsArray="selectedProductTagsArray"/>
                       </div>
                       <!-- {{viewProductListData.error}} -->
                   </div>
                   <!-- {{selectedProductTagsArray}} -->
                </div>
                <div class="modal-footer border-section">
                    <button type="button" class="btn text-light fw-bold"  @click="addProducts" data-bs-dismiss="modal">Done</button>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
    import ProductCard from './ProductCard.vue'
    export default{
        name:'AddYourProduct',
        components:{ProductCard},
        props:['viewProductListData','selectedProductTags'],
        data(){
            return{
                selectedProductTagsArray:[]
            }
        },
        methods:{
            addProducts(){
                let self=this
                self.selectedProductTags.push(self.selectedProductTagsArray)
            }
        }
    }
</script>
<style scoped>
.second-btn-color {
  background-color: #b8f009;
}
.second-primary-color {
  color: #b8f009;
}
.curser-point{
  cursor: pointer;
}
.bringin-input{
    border: none;
    background: #181818;
    color:#fff;
    border-bottom: 1px solid #fff;
}
.bringin-input::placeholder{
    color:#fff;
}
.bringin-input:focus{
    outline:none;
}
.bringin-btn:focus{
    outline: none  !important;
}
.modal-bg{
    background: #202020;
}
.border-section{
    border-top:1px solid #303030;
}
.modal-align{
    text-align: center !important;
}
</style>