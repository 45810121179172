<template>
  <div>
    <Navbar/>
    <div class="container">
      <div class="row my-4 text-light d-flex justify-content-center">
        <div class="col-8">
          <h2 class="text-light fw-bold text-center">Create</h2>
          <div class="input-group my-3">
            <input v-model="videoTitle" type="text" class="bringin-input flex-fill mb-3" placeholder="Video Title" aria-label="Username" aria-describedby="basic-addon1"/>
          </div>
          <div class="input-group mb-3">
            <textarea v-model="videoDescription" class="bringin-textarea flex-fill mb-3 rounded" placeholder="Video Description" rows="3" ></textarea>
          </div>
          <div class="input-group">
            <input @keyup="addHashtag" v-model="hashtag" type="text" class="hashtag-input rounded flex-fill mb-1" placeholder="Video Hashtags (Please use space for separation)" aria-label="Username" aria-describedby="basic-addon1"/>
          </div>
          <div class="mb-3">
              <span class="badge rounded-pill second-btn-color text-dark me-2" v-for="(d,i) in hashtags" :key="d">
                <span>{{d}}</span>
                <span><font-awesome-icon @click='removeHashtag(i)' :icon="['fas','times']" size="sm" class="ms-2 text-dark curser-point" title="remove"/></span>
              </span>
            </div>
          <div class="input-group mb-3">
                <select class="bringin-select flex-fill mb-3 curser-point" aria-label="Default select example" v-model="category">
                    <option selected>Select Category</option>
                    <option class="my-1" v-for="category in viewListData.category" :key="category" :value="category.category_name">{{category.category_name}}</option>
                </select>
          </div>  
           <div class="input-group mb-3">
                <select class="bringin-select flex-fill mb-3 curser-point" aria-label="Default select example" v-model="language">
                    <option selected>Select Language</option>
                    <option class="my-1" v-for="language in viewListData.languages" :key="language" :value="language.language">{{language.language}}</option>
                </select>
          </div>  
           <div class="input-group mb-3">
                <select class="bringin-select flex-fill mb-3 curser-point" aria-label="Default select example" v-model="interestId[0]">
                    <option selected>Select Interest</option>
                    <option class="my-1" v-for="interest in viewListData.interests" :key="interest" :value="interest.interest_id">{{interest.interest_name}}</option>
                </select>
           </div> 
           <div class="input-group mb-3">
              <input type="file" id="add-thumbnail" hidden/>
              <label for="add-thumbnail" class="mb-3 add-thumbnail flex-fill p-3 curser-point">             
                Add video thumbnail
              </label>
           </div> 
           <div class="input-group mb-3">
                <select class="bringin-select flex-fill mb-3 curser-point" aria-label="Default select example" @click="getProductListData" v-model="brandId[0]">
                    <option selected>Select Brand</option>
                    <option class="my-1" v-for="brand in viewListData.brand" :key="brand" :value="brand.brand_id">{{brand.brand_name}}</option>
                </select>
           </div> 
           <!-- <p class="text-light">{{selectedProductTags}}</p> -->
           <AddYourProduct v-bind:viewProductListData="viewProductListData" v-bind:selectedProductTags="selectedProductTags"/>
            <div class="row">
              <div v-for="(cardData,i) in selectedProductTags[0]" :key="cardData" class="col-3 mb-2">
                <ProductCard v-bind:cardData="cardData" v-bind:selected='selected' v-bind:index="i"/>
              </div>
           </div>
          <div class="mt-5 text-center">
              <input type="file" id="upload-video" accept="video/mp4,video/x-m4v,video/*" hidden/>
              <label for="upload-video" class="mb-3 upload-btn p-3 curser-point">             
                  <font-awesome-icon :icon="['fas','play']" size="sm" class="mr-3"/>
                Upload Video
              </label>
              <p class="text-light cursor-hover" @click="postUserPosts">check post</p>
              <!-- <input type="file" id="upload-video" hidden/>
              <label for="upload-video" class="mb-3 live-btn p-3 curser-point">             
                  <font-awesome-icon :icon="['fas','circle']" size="sm" class="mr-3"/>
                Live Video
              </label> -->
          </div> 
        </div>
      </div>
    </div>   
  </div>
</template>

<script>
import axios from 'axios'
import Navbar from '../../common/Navbar/Navbar.vue'
import AddYourProduct from './AddYourProduct.vue'
import ProductCard from './ProductCard.vue'

export default {
  name: "InfluencerProfile",
  components: {Navbar,AddYourProduct,ProductCard},
  data(){
    return{
      videoTitle:'',
      videoDescription:'',
      hashtag:'',
      hashtags:[],
      category:'Select Category',
      language:'Select Language',
      interestId:['Select Interest'],
      brandId:['Select Brand'],
      selectedProductTags:[],
      viewListData:{},
      viewProductListData:[],
      selected:true  ,
    };
  },
  mounted(){
    this.getDataAPIs();
  },
  methods:{
    addHashtag(){
      let data=this.hashtag.trim()
      if(event.key!="Enter"||!data.length){
        return false;
      }
      if(!this.hashtags.includes(data)){
        this.hashtags.push('#'+data)
        this.hashtag=''
      }
    },
    removeHashtag(i){
        this.hashtags.splice(i,1);
    },
    getDataAPIs(){
      let token = JSON.parse(localStorage.userData).token
      axios.defaults.headers.common['token'] = token
      this.getFormData();
    },
    postUserPosts(){
      let self = this
      let url = "/api/v1/userposts/"
      let payload={
      "product_tags":self.selectedProductTags[0],
      "hash_tags":self.hashtags,
      "meta": {
          "extra_data": []
      },
      "visiblity": true,
      "privacy_rules": {
          "blocks": []
      },
      "title":self.videoTitle, 
      "language": self.language,
      "text": self.videoDescription,
      "native_entity_id": "0b596388-d7c8-4161-a2f2-bd752dfc63d8",
      "origin_media_type": "video\/upload",
      "category": self.category,
      "live_at": "chekc time",
      "brand": self.brandId
      }
      console.log(payload)
      // axios.get(url).then(function(res){
      // // console.log("res",res.data.);
      // let resData = res.data
      // if(resData.success)
      // {
      //   self.viewListData=resData.data;
      // }
      // else
      // {
      //   alert("Invalid User")
      // }
    // });
    },    
    getFormData(){
      let self = this
      let url = "/api/v1/get/formdata"
      axios.get(url).then(function(res){
      // console.log("res",res.data.);
      let resData = res.data
      if(resData.success)
      {
        self.viewListData=resData.data;
      }
      else
      {
        alert("Invalid User")
      }
    });
    },
    
    getProductListData(){
      let self = this
      let url = "/api/v1/get/productslist?brand_id="+self.brandId
      axios.get(url).then(function(res){
      // console.log("res",res.data.);
      let resData = res.data
      if(resData.success)
      {
        self.viewProductListData=resData.data;
      }
      else
      {
        alert("Invalid User")
      }
    });
    },
  }
};
</script>

<style scoped>
.second-btn-color {
  background-color: #b8f009;
}
.second-primary-color {
  color: #b8f009;
}
.curser-point{
  cursor: pointer;
}
.bringin-input{
    border: none;
    background: #181818;
    color:#fff;
    border-bottom: 1px solid #fff;
}
.bringin-input::placeholder{
    color:#fff;
}
.bringin-input:focus{
    outline:none;
}
.bringin-textarea{
    background: #181818;
    color:#fff;
    border: 1px solid #fff;
}
.bringin-textarea::placeholder{
    color:#fff;
    padding: 2px;
}
.bringin-textarea:focus{
    outline:none;
}
.hashtag-input{
    height: 3em;
    background: #181818;
    color:#fff;
    border: 1px solid #fff;
}
.hashtag-input::placeholder{
    color:#fff;
}
.hashtag-input:focus{
    outline:none;
}
.bringin-select{
    border: none;
    background: #181818;
    color:#fff;
    border-bottom: 1px solid #fff;
}
.bringin-select:focus{
    outline:none;
}
.modal-align {
  text-align: center !important;
}
.modal-bg {
  background: #353535;
}
.add-thumbnail{
  border-bottom:1px solid #fff ;
  text-align: center;
}

.upload-btn {
  background-color: #b8f009;
  color: #000;
  cursor: pointer;
  border-radius: 50px;
  width: 150px;
}
.live-btn {
  background-color: #000;
  color: #b8f009;
  cursor: pointer;
  border-radius:0 50px 50px 0;
  width: 150px;
}
</style>>

