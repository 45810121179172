<template>
    <div>
        <Navbar/>
        <div class="bringin-container">
            <div class="row my-4 text-light d-flex justify-content-center">
                <div class="col-12">
                    <h2 class="text-light fw-bold text-center">#{{hashtag}}</h2>
                     <div class="row text-light">
                        <div class="d-flex justify-content-center mt-4" v-if="loader">
                            <Loader/>
                        </div>
                        <div v-for="post of viewPostbyHashtagData" :key="post.id" class="col-6 col-md-3 col-sm-4 my-4">
                            <BringinCard 
                                v-bind:post="post" 
                                v-bind:handle="post.handle"
                                v-bind:profile_pic="post.profile_pic"
                            />
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>
<script>
import axios from 'axios'
import Navbar from '../../common/Navbar/Navbar.vue'
import Loader from '../../common/Loader/Loader.vue'
import BringinCard from '../../common/Card/BringinCard.vue'
import Footer from "../../common/Footer/Footer.vue"

export default{
    name: "Hashtag",
    components: {Navbar,Loader,BringinCard,Footer},
    data(){
        return{
            loader:true,
            hashtag:this.$route.params.hashtag,
            viewPostbyHashtagData:[]
        }
    },
    mounted(){
     this.dataAPIs();
    },
    methods:{
        dataAPIs(){
            let token = JSON.parse(localStorage.userData).token
            axios.defaults.headers.common['token'] = token
            this.getAPIsData();
        },
        getAPIsData(){
            this.getPostbyHashtag();
        },
        getPostbyHashtag(){
            let self = this
            self.loader=true
            let url = "/api/v1/postsbyhashtagv2/"
            let payload={
                "user_id": "",
                "language": [],
                "interest_id": [],
                "filter_tag": self.hashtag,
                "start_pos": 0,
                "end_pos": 100
            }
            axios.post(url,payload).then(function(res){
                // console.log("res",res.data.);
                let resData = res.data
                if(resData.success)
                {
                    self.viewPostbyHashtagData=resData.data.data;
                    self.loader=false;
                }
                else
                {
                    alert("Invalid User")
                }
            });
        },
    }
}
</script>
<style scoped>
.bringin-container{
    padding:0 4% 0 4%;
}
</style>