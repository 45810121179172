<template>
  <div > 
    <main >
      <section class="content-section">
        <div class="container">
          <div class="document-body">
            <h2>TERMS OF USE AND END USER LICENSE AGREEMENT</h2>
            <p>PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THE SERVICES OFFERED BY BRINGIN..</p>
            <h4>Acceptance of Terms</h4>
            <p>
              By registering for and/or using the Service in any manner, including but not limited to visiting or
              browsing the Site, you agree to all of the terms and conditions contained herein ("Terms of Use)
              and all other operating rules, policies and procedures that may be published from time to time
              on the Site by Bringin, each of which is incorporated by reference and each of which may be
              updated by Bringin from time to time without notice to you in accordance with the terms set out
              under the "Modification of Terms of Use" section below. In addition, some services offered
              through the Service may be subject to additional terms and conditions specified by Bringin from
              time to time; your use of such services is subject to those additional terms and conditions, which
              are incorporated into these Terms of Use by this reference. These Terms of Use apply to all
              users of the Service, including, without limitation, users who are contributors of content,
              information, and other materials or services on the Site, individual users of the Service, venues
              that access the Service, and users that have a page on the Service.
            </p>
            <h4>Access</h4>
            <p>
              Subject to these Terms of Use, Bringin may offer to provide the Services, as described more
              fully on the Site, and which are selected Bringin Contentby you, solely for your own use, and not for the use or
              benefit of any third party. Services shall include, but not be limited to, any services Bringin
              performs for you, any applications or widgets offered by Bringin that you download from the Site
              or, subject to the terms set out under the "Third party Sites and Services" section below, from
              third party application stores (e.g., iTunes® store, or Google Apps Marketplace) authorized by
              Bringin, as well as the offering of any materials displayed or performed on or through the
              Services (including Content (as defined below)).
            </p>
            <h4>Registration and Eligibility</h4>
            <p>
              You are required to register with Bringin to browse the Site and view Content only and
              represent, warrant and covenant that you provide Bringin with accurate and complete
              registration information (including, but not limited to a user name ("User Name"), e-mail address
              and/or mobile telephone number and a password you will use to access the Service) and to
              keep your registration information accurate and up-to-date. Failure to do so shall constitute a
              breach of these Terms of Use, which may result in immediate termination of your Bringin
              account. We recommend, but do not require, that you use your own name as your User Name
              so your friends can recognize you more easily. You shall not:
            </p>
            <p>  
              <ul>
                <li>Create any account for anyone other than yourself without such person's permission</li>
                <li>Use a username that is the name of another person with the intent to impersonate that person</li>
                <li>Use a username or Bringin account that is subject to any rights of a person other than you without appropriate authorization</li>
                <li>Use a username that is a name that is otherwise offensive, vulgar or obscene or otherwise unlawful.</li>
              </ul>  
            </p>
            <p>  
              Bringin reserves the right to refuse registration of, or cancel a User Name in its sole discretion.
              You are solely responsible and liable for activity that occurs on your account and shall be
              responsible for maintaining the confidentiality of your Bringin password. You shall never use
              another user's account without such other user's prior express permission. You will immediately
              notify Bringin in writing of any unauthorized use of your account, or other account related
              security breach of which you are aware.
            </p>
            <p>  
              The content already present on the Service in special cases for elite accounts are visible to
              public and other users only upon the activation by them. Elite accounts have the facility to
              delete/modify the initial posts on their discretion. Bringin holds no responsibility for their
              subjective liking/disliking of the post that has been inspired from their personal text, social profile
              (like FacebookTM, InstagramTM etc.) or likewise maintaining the privacy of their account before
              activation. Hereby, registering as an elite user, you are giving Bringin the permission to make
              your account ready with inspired posts.
            </p>
            <p>  
              You represent and warrant that if you are an individual, you are of legal age to form a binding
              contract, or that if you are registering on behalf of an entity, that you are authorized to enter into,
              and bind the entity to, these Terms of Use and register for the Service. The Service is not
              available to individuals who are younger than 10 years old. Bringin may, in its sole discretion,
              refuse to offer the Service to any person or entity and change its eligibility criteria at any time.
              You are solely responsible for ensuring that these Terms of Use are in compliance with all laws,
              rules and regulations applicable to you and the right to access the Service is revoked where
              these Terms of Use or use of the Service is prohibited and, in such circumstances, you agree
              not to use or access the Site or Services in any way.
            </p>
            <p>   
              If you use a mobile device, please be aware that your carrier's normal rates and fees, such as
              text messaging and data charges, will still apply. In the event you change or deactivate your
              mobile telephone number, you agree that you will update your account information on the
              Services within 48 hours to ensure that your messages are not sent to the person who acquires
              your old number.
            </p>  
            <h4>Content</h4>
            <p>
              All Content, whether publicly posted or privately transmitted, is the sole responsibility of the
              person who originated such Content. Bringin cannot guarantee the authenticity of any Content
              or data which users may provide about themselves. You acknowledge that all Content accessed
              by you using the Service is at your own risk and you will be solely responsible and liable for any
              damage or loss to you or any other party resulting therefrom. For purposes of these Terms of
              Use, the term "Content" includes, without limitation, any location information, "highlights", video
              clips, audio clips, responses, information, data, text, photographs, software, scripts, graphics,
              and interactive features generated, provided, or otherwise made accessible by Bringin on or
              through the Service. Content added, created, uploaded, submitted, distributed, posted or
              otherwise obtained through the Service by users, including Content that is added to the Service
              in connection with users linking their accounts to third party websites and services, is collectively
              referred to as, "User Submissions."
            </p>  
            <h4>Bringin Content</h4>
            <p>
              The Service contains Content specifically provided by Bringin or its partners and such Content
              is protected by copyrights, trademarks, service marks, patents, trade secrets or other proprietary
              rights and laws, as applicable. You shall abide by and maintain all copyright notices, information,
              and restrictions contained in any Content accessed through the Service.
              Subject to these Terms of Use, Bringin grants each user of the Site and/or Service a worldwide,
              non-exclusive, non-sub licensable and non-transferable license to use, modify and reproduce
              the Content, solely for personal, non-commercial use. Use, reproduction, modification,
              distribution or storage of any Content for other than personal, non-commercial use is expressly
              prohibited without prior written permission from Bringin, or from the copyright holder identified in
              such Content's copyright notice, as applicable. You shall not sell, license, rent, or otherwise use
              or exploit any Content for commercial (whether or not for profit) use or in any way that violates
              any third party right.
            </p>  
            <h4>User Submissions</h4>
            <p>
              We may use your User Submissions in a number of different ways in connection with the Site,
              Service and Bringin’s business as Bringin may determine in its sole discretion, including but not
              limited to, publicly displaying it, reformatting it, incorporating it into marketing materials,
              advertisements and other works, creating derivative works from it, promoting it, distributing it,
              and allowing other users to do the same in connection with their own websites, media platforms,
              and applications (“Third Party Media”). By submitting User Submissions on the Site or otherwise
              through the Service, you hereby do and shall grant Bringin a worldwide, non- exclusive,
              royalty-free, fully paid, sub licensable and transferable license to use, copy, edit, modify,
              reproduce, distribute, prepare derivative works of, display, perform, and otherwise fully exploit
              the User Submissions in connection with the Site, the Service and Bringin's (and its successors
              and assigns') business, including without limitation for promoting and redistributing part or all of
              the Site (and derivative works thereof) or the Service in any media formats and through any
              media channels (including, without limitation, third party websites and feeds). You also hereby
              do and shall grant each user of the Site and/or the Service, including Third Party Media, a
              non-exclusive license to access your User Submissions through the Site and the Service, and to
              use, edit, modify, reproduce, distribute, prepare derivative works of, display and perform such
              User Submissions in connection with their use of the Site, Service and Third Party Media. For
              clarity, the foregoing license grant to Bringin does not affect your other ownership or license
              rights in your User Submission(s), including the right to grant additional licenses to the material
              in your User Submission(s), unless otherwise agreed in writing with Bringin.
            </p>
            <p>  
              You represent and warrant that you have all rights to grant such license to us without
              infringement or violation of any third party rights, including without limitation, any privacy rights,
              publicity rights, copyrights, contract rights, or any other intellectual property or proprietary rights.
            </p>
            <p>  
              You understand that all information publicly posted or privately transmitted through the Site is
              the sole responsibility of the person from which such Content originated; that Bringin will not be
              liable for any errors or omissions in any Content; and that Bringin cannot guarantee the identity
              of any other users with whom you may interact in the course of using the Service.
            </p>
            <p>  
              You should be aware that the opinions expressed in the Content in User Submissions are theirs
              alone and do not reflect the opinions of Bringin. Bringin is not responsible for the accuracy of
              any of the information supplied in User Submissions or in relation to any comments that are
              posted. You should bear in mind that circumstances change and that information that may have
              been accurate at the time of posting will not necessarily remain so.
            </p>
            <p>  
              When you delete your User Submissions, they will be removed from the Service. However, you
              understand that any removed User Submissions may persist in backup copies for a reasonable
              period of time (but following removal will not be shared with others) or may remain with users
              who have previously accessed or downloaded your User Submissions.
            </p>  
            <h4>Rules and Conduct</h4>
            <p>
              As a condition of use, you promise not to use the Service for any purpose that is prohibited by
              these Terms of Use. You are responsible for all of your activity in connection with the Service.
              Additionally, you shall abide by all applicable local, state, national and international laws and
              regulations and, if you represent a business, any advertising, marketing, privacy, or other selfregulatory code(s) applicable to your industry.
            </p>
            <p>  
              By way of example, and not as a limitation, you shall not (and shall not permit any third party to)
              either (a)take any action or (b)upload, download, post, submit or otherwise distribute or facilitate
              distribution of any Content on or through the Service, including without limitation any User
              Submission, that:
            </p>
            <p>  
              <ul>
                <li>infringes any patent, trademark, trade secret, copyright, right of publicity or other right of any other person or entity or violates any law or contractual duty;</li>
                <li>you know is false, misleading, untruthful or inaccurate;</li>
                <li>is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive of another's privacy, tortious, obscene, vulgar, pornographic, offensive, profane, contains or depicts nudity, contains or depicts sexual activity, promotes bigotry, discrimination or violence, or is otherwise inappropriate as determined by Bringin in its sole discretion;</li>
                <li>constitutes unauthorized or unsolicited advertising, junk or bulk e-mail ("spamming");</li>
                <li>contains software viruses or any other computer codes, files, worms, logic bombs or programs that are designed or intended to disrupt, disable, damage, limit or interfere with the proper function of any software, hardware, or telecommunications equipment or to damage or obtain unauthorized access to any system, data, password or other information of Bringin or any third party;</li>
                <li>impersonates any person or entity, including any employee or representative of Bringin;</li>
                <li>includes anyone's identification documents or sensitive financial information; or</li>
                <li>breaches any of the policies and rules incorporated herein.</li>
              </ul>  
            </p>
            <p>  
              If the User Submission includes a photograph, Bringin's Photo Guidelines shall apply as
              mentioned below. Bringin has a zero-tolerance policy against pornography, and will terminate
              and report to the appropriate authorities any user who publishes or distributes such content.
              You shall not (directly or indirectly):
            </p>
            <p>   
              <ul> 
              <li>take any action that imposes or may impose (as determined by Bringin in its sole
              discretion) an unreasonable or disproportionately large load on Bringin's (or its third
              party providers') infrastructure;</li><li> interfere or attempt to interfere with the proper working of the Service or any activities
              conducted on the Service;
              </li><li> bypass any measures Bringin may use to prevent or restrict access to the Service (or
              other accounts, computer systems or networks connected to the Service);
              </li><li> run any form of auto-responder or "spam" on the Service;
              </li><li> use manual or automated software, devices, or other processes to "crawl" or "spider"
              any page of the Site;
              </li><li> harvest or scrape any Content from the Service;
              </li><li> modify, adapt, appropriate, reproduce, distribute, translate, create derivative works or
              adaptations of, publicly display, republish, repurpose, sell, trade, or in any way exploit
              the Service or Site Content (other than your User Submissions), except as expressly
              authorized by Bringin;
              </li><li> decipher, decompile, disassemble, reverse engineer or otherwise attempt to derive any
              source code or underlying ideas or algorithms of any part of the Service (including
              without limitation any application or widget), except to the limited extent applicable laws
              specifically prohibit such restriction;
              </li><li> copy, rent, lease, distribute, or otherwise transfer any of the rights that you receive
              hereunder;
              </li><li> otherwise take any action in violation of Bringin’s and policies.
              Bringin does not guarantee that any Content or User Submissions (as defined above) will be
              made available on the Site or through the Service. Bringin has no obligation to monitor the Site,
              Service, Content, or User Submissions. However, Bringin reserves the right to (i) remove,
              suspend, edit or modify any Content in its sole discretion, including without limitation any User
              Submissions at any time, without notice to you and for any reason (including, but not limited to,
              upon receipt of claims or allegations from third parties or authorities relating to such Content or
              if Bringin is concerned that you may have violated these Terms of Use), or for no reason at all
              and (ii) to remove, suspend or block any User Submissions from the Service. Bringin also
              reserves the right to access, read, preserve, and disclose any information as Bringin reasonably
              believes is necessary to (i) satisfy any applicable law, regulation, legal process or governmental
              request, (ii) enforce these Terms of Use, including investigation of potential violations hereof, (iii)
              detect, prevent, or otherwise address fraud, security or technical issues, (iv) respond to user
              support requests, or (v) protect the rights, property or safety of Bringin, its users and the public.</li></ul>
            </p>
            <h4>Photo Guidelines</h4>
            <p>
              Taking photographs is a scientific art which emerges out the true emotions of the place and time
              of capture. Here are some tips which can help you in becoming one of the best Bringin
              photographers possible.
            </p>
            <p>
              <ol>
              <li> <strong>Lighting and framing!</strong> Make it pretty. People love pretty things.
              </li><li> <strong>Originality! </strong>Upload your photos, not somebody else's.
              </li><li> <strong>Keep it appropriate.</strong> Though nudity is natural, not everyone wants to see it. The same
              goes for hateful things, spam, and irrelevant photos. So steer away from naked people,
              mean-ness, gross-ness, inappropriate-ness and anything else that you wouldn't want to
              see when you're exploring.
              </li><li> <strong>Be respectful. </strong>Don't take photos that include people's private information, like a credit
              card number or phone number, and don't upload a photo of anyone who doesn't want
              their picture uploaded. Think twice: you wouldn't want your private info shared either.
              </li><li> <strong>Be yourself.</strong> Bringin is a community of people, and you're a big part of that. Take photos
              and have fun.
              </li><li> <strong>Be Cautious: </strong>Do not upload images of places of high security and importance,
              archaeological sites, railways, defence areas, etc. without prior compliance with
              applicable laws from the concerned government authorities and departments.</li></ol>
            </p>
            <p>  
              Thanks and happy photographing!
            </p>
            <p>  
              P.S. If you see something inappropriate, use the "Report Content" option to alert us and we'll
              take care of things.
            </p>
            <p>  
              All photos are "User Submissions" as defined in Bringin's Terms and Conditions.
            </p>  
            <h4>Technical Failures</h4>
            <p>
              It is possible that you may face disruptions, including, but not limited to errors, disconnections or
              interferences in communication in the internet services, software or hardware that you have
              used to avail our services. Bringin is not responsible for such factors in the disruption or
              interruption in the services and you take full responsibility with complete knowledge for any risk
              of loss or damages caused due to interruption of services for any such reasons.
            </p>  
            <h4>Advertisements, Third Party Sites and Services</h4>
            <p>
              Some of the Bringin Services are supported by advertising revenue and may display
              advertisements, promotions, and links to third-party websites. You hereby agree that Bringin
              may place such advertising and promotions on the Bringin Services or on, about, or in
              conjunction with your Content. The manner, mode and extent of such advertising and
              promotions are subject to change without specific notice to you.
            </p>
            <p>    
              The Service may permit you to link to other websites, services or resources on the Internet, and
              other websites, services or resources may contain links to the Site. When you access third party
              websites, you do so at your own risk. These other websites are not under Bringin’s control, and
              you acknowledge that Bringin is not responsible or liable for the content, functions, accuracy,
              legality, appropriateness or any other aspect of such websites or resources. The inclusion of any
              such link does not imply endorsement by Bringin or any association with its operators. You
              further acknowledge and agree that Bringin shall not be responsible or liable, directly or
              indirectly, for any damage or loss caused or alleged to be caused by or in connection with the
              use of or reliance on any such Content, goods or services available on or through any such
              website or resource.
            </p>  
            <h4>Termination</h4>
            <p>
              Bringin may terminate your access to all or any part of the Service at any time, with or without
              cause, with or without notice, effective immediately, which may result in the forfeiture and
              destruction of all information associated with your membership. If you wish to terminate your
              account, you may do so by contacting us at info@bringin.live till we develop the procedure on
              the website and apps. Any fees paid hereunder are non-refundable. All provisions of these
              Terms of Use which by their nature should survive termination shall survive termination,
              including, without limitation, ownership provisions, warranty disclaimers, indemnity and
              limitations of liability.
            </p>  
            <h4>Warranty Disclaimer</h4>
            <p>  
              Save to the extent required by law, Bringin has no special relationship with or fiduciary duty to
              you. You acknowledge that Bringin has no control over, and no duty to take any action
              regarding: which users gain access to the Service; what Content you access via the Service;
              what effects the Content may have on you; how you may interpret or use the Content; or what
              actions you may take as a result of having been exposed to the Content.
            </p>
            <p>    
              You release Bringin from all liability for you having acquired or not acquired Content through the
              Service. The Service may contain, or direct you to websites containing, information that some
              people may find offensive or inappropriate. Bringin makes no representations concerning any
              Content contained in or accessed through the Service, and Bringin will not be responsible or
              liable for the accuracy, copyright compliance, legality or decency of material contained in or
              accessed through the Service and cannot be held liable for any third-party claims, losses or
              damages.
            </p>
            <p>    
              You release us from all liability relating to your connections and relationships with other users.
            </p>
            <p>    
              You understand that we do not, in any way, screen users, nor do we inquire into the
              backgrounds of users or attempt to verify their backgrounds or statements. We make no
              representations or warranties as to the conduct of users or the veracity of any information users
              provide. In no event shall we be liable for any damages whatsoever, whether direct, indirect,
              general, special, compensatory, consequential, and/or incidental, arising out of or relating to the
              conduct of you or anyone else in connection with the Services, including, without limitation,
              bodily injury, emotional distress, and any damages resulting in any way from communications or
              meetings with users or persons you may otherwise meet through the Services. As such, you
              agree to take reasonable precautions and exercise the utmost personal care in all interactions
              with any individual you come into contact with through the Services, particularly if you decide to
              meet such individuals in person. For example, you should not, under any circumstances,
              provide your financial information (e.g., credit card or bank account numbers) to other
              individuals.
            </p>
            <p>    
              THE SITE, SERVICE AND CONTENT ARE PROVIDED "AS IS", "AS AVAILABLE" AND ARE
              PROVIDED WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND,
              EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES
              OF TITLE, NON- INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR
              PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR
              USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED, SAVE TO THE
              EXTENT REQUIRED BY LAW.
            </p>
            <p>    
              BRINGIN, AND ITS TEAM, DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES,
              SUPPLIERS, PARTNERS AND CONTENT PROVIDERS DO NOT WARRANT THAT: (A) THE
              SERVICE WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (B)
              ANY DEFECTS OR ERRORS WILL BE CORRECTED; (C) ANY CONTENT OR SOFTWARE
              AVAILABLE AT OR THROUGH THE SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL
              COMPONENTS; OR (D) THE RESULTS OF USING THE SERVICE WILL MEET YOUR
              REQUIREMENTS. YOUR USE OF THE WEBSITE, SERVICE AND CONTENT IS SOLELY AT
              YOUR OWN RISK. SOME STATES / COUNTRIES DO NOT ALLOW LIMITATIONS ON
              IMPLIED WARRANTIES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
            </p>  
            <h4>Indemnification</h4>
            <p>
              You shall defend, indemnify, and hold harmless Bringin, its affiliates and each of its and its
              affiliates' employees, contractors, directors, suppliers and representatives from all losses, costs,
              actions, claims, damages, expenses (including reasonable legal costs) or liabilities, that arise
              from or relate to your use or misuse of, or access to, the Site, Service, Content or otherwise
              from your User Submissions, violation of these Terms of Use, or infringement by you, or any
              third party using the your account, of any intellectual property or other right of any person or
              entity (save to the extent that a court of competent jurisdiction holds that such claim arose due
              to an act or omission of Bringin). Bringin reserves the right to assume the exclusive defense and
              control of any matter otherwise subject to indemnification by you, in which event you will assist
              and cooperate with Bringin in asserting any available defenses.
            </p>  
            <h4>Limitation of Liability</h4>
            <p>
              ALL LIABILITY OF BRINGIN, ITS DIRECTORS, EMPLOYEES, AGENTS,
              REPRESENTATIVES, PARTNERS, SUPPLIERS OR CONTENT PROVIDERS HOWSOEVER
              ARISING FOR ANY LOSS SUFFERED AS A RESULT OF YOUR USE THE SITE, SERVICE,
              CONTENT OR USER SUBMISSIONS IS EXPRESSLY EXCLUDED TO THE FULLEST
              EXTENT PERMITTED BY LAW, SAVE THAT, IF A COURT OF COMPETENT JURISDICTION
              DETERMINES THAT LIABILITY OF BRINGIN, ITS DIRECTORS, EMPLOYEES, AGENTS,
              REPRESENTATIVES, PARTNERS, SUPPLIERS OR CONTENT PROVIDERS (AS
              APPLICABLE) HAS ARISEN, THE TOTAL OF SUCH LIABILITY SHALL BE
            </p>
            <p>  
              LIMITED IN AGGREGATE TO ONE THOUSAND RUPEES (Rs.1000).
            </p>
            <p>    
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
              Bringin, NOR ITS DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES, PARTNERS,
              SUPPLIERS OR CONTENT PROVIDERS, BE LIABLE UNDER CONTRACT, TORT, STRICT
              LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL OR EQUITABLE THEORY OR
              OTHERWISE (AND WHETHER OR NOT Bringin, ITS DIRECTORS, EMPLOYEES, AGENTS,
              REPRESENTATIVES, PARTNERS, SUPPLIERS OR CONTENT PROVIDERS HAD PRIOR
              KNOWLEDGE OF THE CIRCUMSTANCES GIVING RISE TO SUCH LOSS OR DAMAGE)
              WITH RESPECT TO THE SITE, SERVICE, CONTENT OR USER SUBMISSIONS FOR:
            </p>
            <p>  
              <ul>  
                <li> INDIRECT OR CONSEQUENTIAL LOSSES OR DAMAGES;
                </li><li> LOSS OF ACTUAL OR ANTICIPATED PROFITS;
                </li><li> LOSS OF REVENUE;
                </li><li> LOSS OF GOODWILL;
                </li><li> LOSS OF DATA;
                </li><li> LOSS OF ANTICIPATED SAVINGS;
                </li><li> WASTED EXPENDITURE; OR
                </li><li> COST OF PROCUREMENT OF SUBSTITUE GOODS OR SERVICES.</li>
              </ul>  
            </p>
            <p>   
              NOTHING IN THESE TERMS OF USE SHALL BE DEEMED TO EXCLUDE OR LIMIT YOUR
              LIABILITY IN RESPECT OF ANY INDEMNITY GIVEN BY YOU UNDER THESE TERMS OF
              USE. APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY
              OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR
              EXCLUSION MAY NOT APPLY TO YOU. IN SUCH CASES, BRINGIN’S LIABILITY WILL BE
              LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
            </p>  
            <h4>Integration and Severability</h4>
            <p>
              These Terms of Use are the entire agreement between you and Bringin with respect to the
              Service and use of the Site, Service, Content or User Submissions, and supersede all prior or
              contemporaneous communications and proposals (whether oral, written or electronic) between
            </p>
            <p>  
              you and Bringin with respect to the Site. If any provision of these Terms of Use is found to be
              unenforceable or invalid, that provision will be limited or eliminated to the minimum extent
              necessary so that these Terms of Use will otherwise remain in full force and effect and
              enforceable. The failure of either party to exercise in any respect any right provided for herein
              shall not be deemed a waiver of any further rights hereunder. Waiver of compliance in any
              particular instance does not mean that we will waive compliance in the future. In order for any
              waiver of compliance with these Terms of Use to be binding, Bringin must provide you with
              written notice of such waiver through one of its authorized representatives.
            </p>  
            <h4>Modification of Terms of Use</h4>
            <p>
              Bringin reserves the right, at its sole discretion, to modify or replace any of these Terms of Use,
              or change, suspend, or discontinue the Service (including without limitation, the availability of
              any feature, database, or content) at any time by posting a notice on the Site or by sending you
              notice through the Service or via email. Bringin may also impose limits on certain features and
              services or restrict your access to parts or all of the Service without notice or liability. It is your
              responsibility to check these Terms of Use periodically for changes. Your continued use of the
              Service following the posting of any changes to these Terms of Use constitutes acceptance of
              those changes.
            </p>  
            <h4>Other Provisions</h4>
            <h4>Claims of Copyright or Trademark Infringement</h4>
            <p> 
              Claims of copyright or trademark infringement should be sent to Bringin’s designated agent.
            </p>
            <p>  
              If you believe that someone is infringing your copyright or trademark rights on Bringin, you can
              report it to us by contacting our designated agent at info@bringin.live with a report containing
              the following information:
            </p>
            <p>  
              <ol>
                <li> your complete contact information (name, mailing address and phone number),
                </li><li> a detailed description of the content on Bringin that you claim infringes your copyright or
                trademark along with details on how it infringes upon your copyright or trademark,
                </li><li> the web address (URL) of the infringing content,
                </li><li> a declaration that you are filing this report in good faith and that all the information provided is accurate and that you are the owner of the copyright and/or trademark.</li>
              </ol>  
            </p>
            <p>   
              Please attach your digital signature or physical signature to the report.
            </p>
            <p>   
              Within 36 hours of receiving this notice with the abovementioned details, we will take down the
              allegedly infringing material from public view while we assess the issues identified in your
              notice.
            </p>
            <p>   
              On completion of the take-down procedure above:
            </p>
            <p>   
              <ul>
                <li> If the complainant is successful in obtaining an order of injunction from a court of
                competent jurisdiction within 21 days from filing the complaint, the material will be
                permanently removed from Bringin’s website and database upon Bringin being provided
                with a copy of such order;
                </li><li> If the complainant is not successful in obtaining an order of injunction from a court of
                competent jurisdiction within 21 days from receiving notice from the complainant, the
                material will be made available for public view once again.</li>
              </ul>  
            </p>
            <p>
              Before you submit a report of infringement, you may want to send a message to the person who
              posted the content. You may be able to resolve the issue without contacting Bringin. Please
              remember, only the copyright/trademark owner or their authorized representative may file a
              report of infringement. If you believe something on Bringin infringes someone else’s
              copyright/trademark, you may want to let the rights owner know.
            </p>  
            <h4>Notice</h4>
            <p>
              Bringin may give notice by means of a general notice on the Services, notification within the
              Application on your Account, electronic mail to your email address in your Account. You may
              give notice to Bringin by written communication to Bringin’s email address at info@bringin.live.
            </p>  
            <h4>General</h4>
            <p>
              You may not assign or transfer these Terms in whole or in part without Bringin’s prior written
              approval. You hereby give your approval to Bringin for it to assign or transfer these Terms in
              whole or in part, including to: (i) a subsidiary or affiliate; (ii) an acquirer of Bringin’s equity,
              business or assets; or (iii) a successor by merger. No joint venture, partnership, employment or
              agency relationship exists between you, Bringin or any Third Party Provider as a result of the
              contract between you and Bringin or use of the Services.
            </p>
            <p>  
              If any provision of these Terms is held to be illegal, invalid or unenforceable, in whole or in part,
              under any law, such provision or part thereof shall to that extent be deemed not to form part of
              these Terms but the legality, validity and enforceability of the other provisions in these Terms
              shall not be affected. In that event, the parties shall replace the illegal, invalid or unenforceable
              provision or part thereof with a provision or part thereof that is legal, valid and enforceable and
              that has, to the greatest extent possible, a similar effect as the illegal, invalid or unenforceable
              provision or part thereof, given the contents and purpose of these Terms. These Terms
              constitute the entire agreement and understanding of the parties with respect to its subject
              matter and replaces and supersedes all prior or contemporaneous agreements or undertakings
              regarding such subject matter. In these Terms, the words “including” and “include” mean
              “including, but not limited to.”
            </p>  
            <h4>Miscellaneous</h4>
            <p>  
              Bringin shall not be liable for any failure to perform its obligations hereunder where such failure
              results from any cause beyond Bringin's reasonable control, including, without limitation,
              mechanical, electronic or communications failure or degradation (including "line-noise"
              interference). These Terms of Use are personal to you, and are not assignable, transferable or
              sublicensable by you except with Bringin's prior written consent. Bringin may assign, transfer or
              delegate any of its rights and obligations hereunder without consent. No agency, partnership,
              joint venture, or employment relationship is created as a result of these Terms of Use and
              neither party has any authority of any kind to bind the other in any respect.
            </p>
            <p>   
              Unless otherwise specified in these Term of Use, all notices under these Terms of Use will be in
              writing and will be deemed to have been duly given when received, if personally delivered or
              sent by certified or registered mail, return receipt requested; when receipt is electronically
              confirmed, if transmitted by facsimile or e-mail; or the day after it is sent, if sent for next day
              delivery by recognized overnight delivery service.
            </p>  
            <h4>Contact Us</h4>
            <p>
              You may contact us at the following address:
            </p>
            <p>   
              Bringin Web Private Limited
            </p>
            <p>   
              N1/003, the close (North) Unitech Nirvana Country, South City 2 Gurgaon HR-122018 India
            </p>
            <p>   
              Email us at info@bringin.live
            </p>  

          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>

export default {
    name:'Welcome',
    data(){
        return {
          desc: ''
        }
    }
}
</script>
<style scoped>
  *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif!important;
    line-height: 25px!important;
    background-color: #212529!important;
    color: white!important;
}
/* .landing_page{
    background-color: #212529!important;
    color: white!important;
} */
/* body{
    background-color: #212529 !important;
    color: white !important;
} */

    .container{
        width: 80%;
        margin: auto;
    }
    .document-body{
        padding: 100px 0;
    }
    .document-body h2{
        text-align: center;
        text-decoration: underline;
        margin-bottom: 35px;
    }
    h4{
        font-size: 25px;
        font-weight: bold;
    }
    h4, p {
        padding: 15px 0;
    }
    ul,ol{
        padding-left: 15px;
    }
    h2{
        font-size:1.5em!important;
        font-weight: bold;
    }
</style>