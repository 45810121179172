<template>
  <div>
    <Navbar/>
    <div class="container ">
        <div class="row message-container my-4 ">
            <div class="col-4 p-0">
                <h4 class="text-light username-container fw-bold text-center py-4">shivprasad721</h4>
                <div class="d-flex justify-content-start m-3">
                    <div class="mx-3">
                        <img src="../../../assets/images/avatar.jpg" class="avatar p-1"/>
                    </div>
                    <div class="pt-2">
                        <p class="m-0 text-light p-0">users name</p>
                        <p class="m-0 p-0 text-muted">chat</p>
                    </div>
                </div>
                <div class="d-flex justify-content-start m-3">
                    <div class="mx-3">
                        <img src="../../../assets/images/avatar.jpg" class="avatar p-1"/>
                    </div>
                    <div class="pt-2">
                        <p class="m-0 text-light p-0">users name</p>
                        <p class="m-0 p-0 text-muted">chat</p>
                    </div>
                </div>
                <div class="d-flex justify-content-start m-3">
                    <div class="mx-3">
                        <img src="../../../assets/images/avatar.jpg" class="avatar p-1"/>
                    </div>
                    <div class="pt-2">
                        <p class="m-0 text-light p-0">users name</p>
                        <p class="m-0 p-0 text-muted">chat</p>
                    </div>
                </div>
            </div>
            <div class="col-8 chat-container">
                <p class="text-light text-center"> no new message</p>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import Navbar from "../../common/Navbar/Navbar.vue";

export default {
  name: "Message",
  components: {Navbar}
};
</script>
<style scoped>
    .secont-primary-color{
        color: #b8f009;
    }

    .message-container{
        /* border:1px solid #cccacab4;
        background: #353535; */
        border: 1px solid #303030;

        height: 100vh;
    }
    .username-container {
        /* border-bottom:1px solid #cccacab4;  */
          border-bottom: 1px solid #303030;

    }
    .chat-container{
        /* border-left: 1px solid #cccacab4; */
          border-left: 1px solid #303030;

    }
    .avatar{
        vertical-align: middle;
        width: 62px;
        height: 62px;
        border-radius: 50%;
    }

    a{
        text-decoration: none;
    }
</style>
