<template>
    <div>
         <div class="card">
             <div class="card-img">
                <img  v-if="cardData.image_link" :src="cardData.image_link" class="card-img-top img-fluid" alt="...">
                <img v-if="!cardData.image_link" src="../../../assets/images/avatar.png" class="card-img-top img-fluid" alt="...">
             </div>
            <div class="card-body py-2 px-0">
                <p class="card-title text-light m-1 mb-2">{{cardData.product_name}}</p>
                <p class="card-text card-descriptiontext-light m-1 mb-2">{{cardData.description}}</p>
                <p  v-if="!selected" class=" m-1 mb-2"><small class="text-light fw-bold">Rs. {{cardData.price}}</small></p>
                <button v-if="!selected && !ProductByBrandPage" class="btn add-btn rounded-pill" @click="selectProducts">ADD</button>
                <a :href="cardData.link" v-if="ProductByBrandPage" target="_blanck">
                    <button class="btn add-btn rounded-pill">BUY</button>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'ProductCard',
    props:['cardData','selectedProductTagsArray','selected','index','ProductByBrandPage'],
    methods:{
        selectProducts(){
            let self=this
            self.selectedProductTagsArray.push({
                "product_id": self.cardData.product_id,
                "description": self.cardData.description,
                "availability": self.cardData.availability,
                "product_name": self.cardData.product_name,
                "image_link": self.cardData.image_link,
                "link": self.cardData.link
            })
        },
    }
}
</script>
<style scoped>
.card{
    border: none;
    padding:5px 5px 0 5px;
    background: #000;

}
p{
    font-size: 12px;
}
.card-img{
    height:250px;
    overflow: hidden;
}

.card-body{
    background: #000;
    border: none;
}
.card-title{
    text-overflow: ellipsis !important;
    overflow: hidden; 
    white-space: nowrap;
}
.card-text{
    line-height: 1.5em;
    height: 3em;
    text-overflow: ellipsis !important;
    overflow: hidden;
    /* white-space: nowrap; */
}
.add-btn{
    border:2px solid #b8f009;
    font-size: 12px;
    color:#b8f009;
    padding: 2px 20px;
}
.add-btn:hover{
    background: #b8f009;
    color: #000;
}
</style>
