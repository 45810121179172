<template>
  <div > 
    <main >
      <section class="content-section">
          <div class="container">
        <div class="document-body">
<h2>
    BRINGIN COIN TERMS AND CONDITION
</h2>

<h4>
    About Bringin’:
</h4>
<p>
    Bringin’ is a live-streaming, video content, shopper-tainment platform that allows its users to upload videos, brand reviews, tutorials and how-to videos so that the you can #DiscoverTheNew – New brands, new ingredients, new hacks and get smarter about your brand choices
</p>
<h4>
    About The Bringin’ Coins
</h4>
<p>
    <b> "Bringin Coin"</b> is a feature introduced on Bringin mobile application ("Platform"), by the owner and operator of the Platform, Bringin Web Private Limited ("Bringin").

The following terms ("Terms") are part of and subject to the terms of use, privacy policy and other relevant documentation including any modifications, alterations or updates thereof, that are published on the Platform.

</p>
<h4>

    Earning Mechanism: -
</h4>

<ul>
    <li>
        Minimum age eligibility criteria to earn and use Bringin coins is 18+. 
    </li>
    <li>
        A user will only be credited once for its login coins
    </li>
    <li>
        Referral coins will be only credited after the referred user completes its profile.
    </li>
    <li>
        A User can earn maximum of 500 Bringin Coins in 24hours by uploading review videos.
    </li>
    <li>
        A User can earn maximum of 100 Bringin Coins in 48 hours by sharing videos.
    </li>
    <li>
        A User can earn maximum of 20 Bringin Coins in 24 hours by commenting on the videos on the app.
    </li>
    <li>

        A User will earn 20% of Bringin Coins from the likes on its review/live video.
    </li>
    <li>
        A User will earn 10 Bringin Coins for each share of its video from the app but upto a maximum of 200 Bringin Coins can be earned.
    </li>
    <li>
        A User will earn 5 Bringin Coins for each comment on its video but upto a maximum of 40 Bringin Coins can be earned.
    </li>
    <li>
        A User will earn 10 Bringin Coins for each follower added on the app profile.
    </li>
    <li>
        A user can earn maximum of 500 Bringin Coins in 24 hours for live sessions.
    </li>
    <li>
        All users will get 20% of affiliation on the purchase made by an end user via their video.
    </li>
    <li>
        All Bringin Coins are valid for 365 days from the date of earning.
    </li>
    <li>
        Deleted actions from our end will also lead to deduction of Bringin Coins in respect to their earnings.
    </li>
    <li>
        A User can earn maximum of 500 bringin coins from multiple or single activity in 24 hours. 
    </li>
</ul>
<h4>
    Forfeit Mechanism-
</h4>
<p>
    Bringin’ reserves the right to cancel / suspend and/or modify the Bringin Coins in whole or in part, in particular but not limited to any fraud, technical failures or any other factor beyond our reasonable control that impairs the integrity or proper functioning of the mechanism. Bringin reserves the right in its sole discretion to forfeit Bringin coins of any user if finds:
</p>
<ul>
    <li>
        	to be tampering with the earning process.
    </li>
    <li>

        to be acting in violation of the Rules or any other terms; or
    </li>
    <li>

    
		to be acting in a disruptive manner.

    </li>
</ul>
<h4>
    The User Bringin Coins shall also be Forfeited on the occurrence of following events:
</h4>
<ul>

    <li>
        Video Deletion- 50 Coins
        </li>
        <li>

            Comment Deletion- 5 Coins
        </li>
        <li>

            Unlike- 2 Coins
        </li>
        <li>

            Follower lost- 10 Coins
        </li>
        <li>

            Deletion of live video- 100 Coins
        </li>
        <li>

            Comment removed from your video- 5 Coins
        </li>

    
</ul>

<b> Note:</b> <i> 1 Bringin coin is equal to 10 paise in a rupee i.e. 10% of Bringin coins, In actual INR value.</i>

<h4>
    Use of Bringin Coins:
</h4>
<ul>
    <li>
        The Bringin coins can only be used for in app purchase like participation in the games, live events, lucky draw to purchase brand vouchers etc.</li>
        <li>

            Bringin Coins are not transferable. Any wrongful transfer of Bringin Coins shall be void ab initio.
        </li>
        <li>

            These are not actual coins and are purely rewarded for regular and continuous use of the Bringin application.
        </li>
        <li>

            Bringin may from time to time make these coins redeemable with their commercial partners
        </li>

    
</ul>

<h4>
    Representation and Warranties: 
</h4>
<ul>
    <li>
        User Video:  User undertakes that the content being uploaded has been shot by the user themselves and the user is clearly visible in the video while he / she is reviewing the product or following the video requirements. The user undertakes that the video belongs to him / her and is not infringing upon the copyrights of any other individual or organization. 
    </li>
    <li>

        	Decorum: All users of the app undertake to follow community guidelines. We are an inclusive platform that has a zero tolerance policy towards:
    </li>
    <li>

        	 any kind of bullying, trolling or airing comments against a caste, religion, gender, sexual orientation, gender identities, age, appearance, dressing etc.
    </li>
    <li>

        	Content that is indecent, obscene, slanderous, hateful, defamatory or libelous (depictions of nudity, sexual activity, offensive languages or images or propagating lewd behavior)
    </li>
    <li>

        	Content that can be provoke violence, threatening, abusive or depicting usage of drugs, weapons or any illegal activity)
    </li>
    <li>

        Sharing content that breaches privacy of any other person or entity including but not limited to sharing phone numbers, email ids, government identity cards like Aadhar card, PAN card, driving license etc or any information that can be personally identifiable of any individual or entity. 
    </li>

    
</ul>
<h4>
    Intellectual Property Rights:
</h4>
<p>
    All users agree to irrevocably grant, transfer, convey and assign to platform the entirety of the rights in and to the submission, any derivative works made thereof in perpetuity including without limitation, the rights to use the submission for any and all purposes, including production and sales of products with the videos, in and all media whether now known or hereafter developed on a worldwide basis – by Bringin’ or Bringin’s clients in future. 
</p>
<h4>
    Other Terms and Conditions:
</h4>

<ul>
    <li>

        These Terms are subject to change, at the sole discretion of Bringin, from time to time.
    </li>
    <li>

        	The user is required to create its full account/profile and register on the application to avail benefit of Bringin Coins.
    </li>
    <li>

        	By providing your information, you consent to the collection, transfer, sharing, use and otherwise processing of your information in accordance with the Terms and the privacy policy published on the Platform.
    </li>
    <li>

        	Bringin does not allow any user to purchase these coins in real world money.
    </li>
    <li>

        	None of the activity involves any type of betting or illegal activity.
    </li>
    <li>

        	Bringin reserves the right to disqualify you from earning any Bringin Coin if any fraudulent/abuse/affiliates activity is identified as being carried out for the purpose of earning Bringin Coins or if You have violated any Terms or applicable laws. Disqualification may result in immediate cancellation of all Bringin Coins in 'earned' or 'pending to be earned'.
    </li>
    <li>

        	Bringin does not guarantee any winning prizes.
    </li>
    <li>

        Prizes for Bringin contests may be given by Bringin or its commercial partners.
    </li>
    <li>

        	Bringin may at its discretion require a user to agree to feature in bringin’s advertisement or promotional /marketing material as a condition for receiving the prize in a Reward Promotion.
    </li>
    <li>

        	Bringin may require the user to provide PAN, proof of identity and proof of residence if any required for reward distribution.
    </li>
    <li>

        	These Terms are subject to the prevailing laws of India.
    </li>
    <li>

        	Bringin disclaims all liability that may arise due to any violation of the applicable laws by You.
    </li>
    <li>

        	Bringin reserves the right to suspend issue of Bringin Coins to you personally, or to all users temporarily or permanently at any time without notice.
    </li>
    <li>

        In case of any dispute or difference arising concerning Bringin Coins or any alleged defect or deficiency of service availed, the same shall be resolved through arbitration as per the arbitration and the conciliation act 1996 and seat will be at Gurugram, Haryana.
    </li>
    <li>

        	Bringin reserves the right to deactivate or delete the User account, all related information on it, and forfeit all Coins or related prizes from any User, in the event of misuse of the offer, fraud or suspicious.
    </li>
    <li>

        	Selection of the winner by Bringin shall be final, binding and not subject to any dispute settlement procedures
    </li>
    <li>

        	Bringin reserves the right at any time to modify, withdraw or terminate 'Bringin Coins' as a feature on the Platform at its discretion, without any prior notice and without any liability or obligation in this regard.
    </li>
    <li>

        	You are not bound in any way to actively accumulate or use Bringin Coins. Any action towards using Bringin Coins is voluntary.
    </li>
    <li>

        	You hereby agree to indemnify and keep Bringin harmless against all damages, liabilities, costs, expenses, claims, suits and proceedings (including reasonable attorney's fee) that may be suffered by Bringin as a consequence of (i) violation of terms of this T&C by You; (ii) violation of applicable laws; (iii) any action or inaction resulting in wilful misconduct or negligence on Your part.
    </li>
    <li>

        	This document is an electronic record in terms of Information Technology Act, 2000, and the Rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.
    </li>

</ul>
        </div>

    </div>
      </section>
    </main>
  </div>
</template>
<script>

export default {
    name:'Welcome',
    data(){
        return {
          desc: ''
        }
    }
}
</script>
<style scoped>
  *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif!important;
    line-height: 25px!important;
    background-color: #212529!important;
    color: white!important;
}
/* .landing_page{
    background-color: #212529!important;
    color: white!important;
} */
/* body{
    background-color: #212529 !important;
    color: white !important;
} */

    .container{
        width: 80%;
        margin: auto;
    }
    .document-body{
        padding: 100px 0;
    }
    .document-body h2{
        text-align: center;
        text-decoration: underline;
        margin-bottom: 35px;
    }
    h4{
        font-size: 25px;
        font-weight: bold;
    }
    h4, p {
        padding: 15px 0;
    }
    ul{
        padding-left: 15px;
    }
    h2{
        font-size:1.5em!important;
        font-weight: bold;
    }
</style>