<template>
    <div class="container-fluid footerBringinForOtherPage" :class="{footerBringinHomepage:homepage==true}">
      <div class="bringin-container py-2 ">
        <div class="row mx-4 ">
          <div class="d-flex justify-content-center">
            <img
              src="../../../assets/images/bg-vector-iconn.png"
              class="mb-1 mt-3"
              height="20px"
            />
          </div>
          <ul class="d-flex justify-content-center fw-bold m-0">
            <li>
              <a href="https://bringin.live/aboutus/" class="text-light me-3 footer-font-size" target="_blank">About Us</a>
            </li>
            <li>
              <a href="https://bringin.live/privacy/" class="text-white me-3 footer-font-size" target="_blank">Privacy Policy</a>
            </li>
            <li>
              <a href="https://bringin.live/support/" class="text-white footer-font-size" target="_blank">Support</a>
            </li>
          </ul>
          <div class="section">
            <div >
              <a class="btn btn-color rounded m-1 rounded p-1 footer-font-size" 
                href="https://play.google.com/store/apps/details?id=com.lyxel.bringin" target="_blank">
              <font-awesome-icon :icon="['fab','google-play']" size="lg" class=""/>
              Get it on
              <span class="fw-bold">Google Play</span>
              </a>
              <a class="btn btn-color rounded m-1 p-1 footer-font-size" 
                href="https://apps.apple.com/in/app/bringin/id1579153016" target="_blank">
                <font-awesome-icon :icon="['fab','apple']" size="lg" class=""/>
                Download on the 
                <span class="fw-bold">App Store</span>
              </a>
            </div>
          </div>
          <p class="text-muted footer-font-size align-middle p-0 m-1 text-center fw-light" style="font-size:12px">
            © Copyright 2024 Bringin Web Private Limited. All rights reserved
          </p>
        </div>
      </div>
    </div>

</template>
<script>
export default{
    name:'Footer',
    props:['homepage']
}
</script>
<style scoped>
.footerBringinHomepage{
  background-color:#212121 !important;
}
.footerBringinForOtherPage{
 background-color: #343434;
}
 .bringin-container{
    padding:0 4% 0 4%;
}
 .section{
      margin-top: 5px;
      text-align: center;
      color: #fff;
  }
  .section p{
      padding:0;
      margin: 0;
  }
   .btn-color{
      background-color: #b8f009;
  }
   .secont-primary-color{
      color: #b8f009;
  }
   .footer-font-size{
    font-size:14px !important;
  }
  li{
    list-style: none;
  }
</style>