<template>
  <div class="landing_page">
    <header id="site-header">
      <div class="site-container d-flex align-items-space-between" style="justify-content:space-between">
        <div class="logo">
          <a href="/">
            <img src="/bringin_landing/assets/images/bringin_logo_new.png" alt="" />
          </a>
        </div>
        <!-- <div style="color:white">
          <a href="/login" class="button button-white shadow-sm gotologin"><span
              class="d-flex align-items-center justify-content-center"><i class="fa fa-user  mr-2 text-black"></i><span
                class="text-black">Login</span></span></a>
        </div> -->


        <!-- <nav id="nav" class="ml-auto">
          <button class="close-menu">
              <img src="/bringin_landing/assets/images/icons/close.svg" alt="">
          </button>
          <ul>
              <li>
              <a href="#">Creators</a>
              </li>
              <li>
              <a href="#">Brands</a>
              </li>
              <li>
              <a href="#">You</a>
              </li>
          </ul>
          </nav>
          <button class="hambuger">
          <img alt="" src="/bringin_landing/assets/images/icons/navicon.svg" />
          </button> -->
      </div>
    </header>
    <main id="main-content">
      <section class="herosection">
        <div class="site-container">
          <div class="row d-flex flex-wrap align-items-center">
            <div class="col-md-6">
              <div class="herobanner-content">
                <h1 class="heading mb-4 txt-clr-white font-HB">
                  Where Style Meets Your Story
                </h1>
                <div class="txt-wrap txt-large mb-5 txt-clr-white font-playfair">
                  <p>
                    Discover unique style at our boutique, where we blend modern trends with timeless elegance just for you.
                  </p>
                </div>
                <div class="download-app-holder">
                  <h5 class="mb-4 txt-clr-white h5-style">
                    <span>Download the App</span>
                  </h5>
                  <div class="btn-holder">
                    <a href="https://play.google.com/store/apps/details?id=com.lyxel.bringin"
                      class="button button-white shadow-sm">
                      <span class="d-flex align-items-center justify-content-center">
                        <i class="fab fa-google-play mr-2 text-white"></i>
                        <span class="text-white">Get it on <strong>Google Play</strong></span>
                      </span>
                    </a>
                    <a href="https://apps.apple.com/in/app/bringin/id1579153016" class="button button-white shadow-sm">
                      <span class="d-flex align-items-center justify-content-center">
                        <i class="fab fa-apple mr-2 text-white"></i>
                        <span class="text-white">
                          Download on the <strong>App Store</strong></span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="app-img text-center">
                <img src="/bringin_landing/assets/images/app1.png" alt="" width="460" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- <section class="content-section">
        <div class="site-container">
          <div class="row d-flex align-items-center flex-wrap">
            <div class="col-lg-5 d-none d-lg-block">
              <div class="app-img has-bgvector">
                <img src="/bringin_landing/assets/images/app2.png" class="app-img" alt="" />
                <img src="/bringin_landing/assets/images/bg-vector-light.png" class="bg-vector-img" alt="" />
              </div>
            </div>
            <div class="col-lg-7">
              <div class="row">
                <div class="col-md-6">
                  <div class="heading heading-md mb-5">
                    Online <br class="d-none d-lg-block" />Shopping,
                    <br class="d-none d-lg-block" />Reinvented.
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="featured-box">
                    <div class="icon mb-3">
                      <img src="/bringin_landing/assets/images/icons/icon-5.png" alt="" />
                    </div>
                    <h4 class="font-playfair mb-2 h4-style">Tutorials</h4>
                    <div class="txt-wrap">
                      <p>
                        Make up tutorials, regimens, features, how to use,
                        product demos - everything on your language by your
                        favourite creators
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="featured-box">
                    <div class="icon mb-3">
                      <img src="/bringin_landing/assets/images/icons/icon-4.png" alt="" />
                    </div>
                    <h4 class="font-playfair mb-2 h4-style">Reviews</h4>
                    <div class="txt-wrap">
                      <p>
                        Quick 1 minute reviews, long format reviews, product
                        unboxing, exclusive launch pre-views - stay update on
                        what’s fresh
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="featured-box">
                    <div class="icon mb-3">
                      <img src="/bringin_landing/assets/images/icons/icon-3.png" alt="" />
                    </div>
                    <h4 class="font-playfair mb-2 h4-style">This Vs That</h4>
                    <div class="txt-wrap">
                      <p>
                        confused between products. Maybe with better features
                        explanations you can choose the brand that’s perfect for
                        you
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="featured-box">
                    <div class="icon mb-3">
                      <img src="/bringin_landing/assets/images/icons/icon-1.png" alt="" />
                    </div>
                    <h4 class="font-playfair mb-2 h4-style">How to choose</h4>
                    <div class="txt-wrap">
                      <p>
                        Our creators will help you choose better with their
                        in-depth understanding of products and categories
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="featured-box mb-0">
                    <div class="icon mb-3">
                      <img src="/bringin_landing/assets/images/icons/icon-2.png" alt="" />
                    </div>
                    <h4 class="font-playfair mb-2 h4-style">
                      Top Reasons to buy
                    </h4>
                    <div class="txt-wrap">
                      <p>
                        let the creators update you more on recent trends and
                        innovations so that you can choose better
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> -->
      <!-- <section class="content-section lightbg"> -->
        <!-- <div class="site-container pr-0">
          <div class="category-slider">
            <div class="slide-item">
              <div class="category-block">
                <div class="figure">
                  <img src="/bringin_landing/assets/images/image-1.jpg" alt="" width="100%" />
                </div>
                <div class="figcaption">
                  <div class="heading heading-sm txt-clr-white">
                    Beauty & Skin
                  </div>
                </div>
                <a href="#" class="fulllink"></a>
              </div>
            </div>
            <div class="slide-item">
              <div class="category-block">
                <div class="figure">
                  <img src="/bringin_landing/assets/images/image-2.jpg" alt="" width="100%" />
                </div>
                <div class="figcaption">
                  <div class="heading heading-sm txt-clr-white">
                    Home Fashion
                  </div>
                </div>
                <a href="#" class="fulllink"></a>
              </div>
            </div>
            <div class="slide-item">
              <div class="category-block">
                <div class="figure">
                  <img src="/bringin_landing/assets/images/image-3.jpg" alt="" width="100%" />
                </div>
                <div class="figcaption">
                  <div class="heading heading-sm txt-clr-white">
                    Tech & Gadgets
                  </div>
                </div>
                <a href="#" class="fulllink"></a>
              </div>
            </div>
            <div class="slide-item">
              <div class="category-block">
                <div class="figure">
                  <img src="/bringin_landing/assets/images/image-4.jpg" alt="" width="100%" />
                </div>
                <div class="figcaption">
                  <div class="heading heading-sm txt-clr-white">
                    Mom & Parenting
                  </div>
                </div>
                <a href="#" class="fulllink"></a>
              </div>
            </div>
            <div class="slide-item">
              <div class="category-block">
                <div class="figure">
                  <img src="/bringin_landing/assets/images/image-5.jpg" alt="" width="100%" />
                </div>
                <div class="figcaption">
                  <div class="heading heading-sm txt-clr-white">
                    Category Title
                  </div>
                </div>
                <a href="#" class="fulllink"></a>
              </div>
            </div>
          </div>
        </div>
      </section> -->
      <!-- <section> -->
        <!-- <div class="trending-container">
          <div class="d-flex justify-content-between">
            <p class="text-light fs-3 fw-bold">Trending Topics</p>
            <router-link to="trendingtopics">
              <p class="secont-primary-color fs-5 fw-bold">See All</p>
            </router-link>
          </div>
          <div class="mb-3 trending-pills-container d-flex justify-content-start">
            <div v-for="trendingHashtag of viewTrendingHashtags" :key="trendingHashtag.order">
              <router-link :to="'/hashtag/' + trendingHashtag.tag_text" class="text-dark">
                <span class="badge rounded-pill trending-pills-bedge p-2 m-1">
                  #{{ trendingHashtag.tag_text }}
                </span>
              </router-link>
            </div>
          </div> -->
          <!-- checking section -->
          <!-- <div>
            <Hooper :settings="trendingResponsiveSettings" style="height:100%" ref="TrendingCarousel"
              @slide="updateCarouselTrending">
              <Slide v-for="(post) in viewTrendingPostsData" :key="post.post_id">
                <div class="p-2">
                  <BringinCard v-bind:post="post" v-bind:handle="post.handle" v-bind:profile_pic="post.profile_pic" />
                </div>
              </Slide>
               <hooper-navigation slot="hooper-addons"></hooper-navigation> -->
            <!-- </Hooper>
            <div class="d-flex justify-content-center">
              <button @click.prevent="slidePrevTrending" class="arrow-prev"><i
                  class="fa-solid fa-arrow-left"></i></button>
              <button @click.prevent="slideNextTrending" class=" arrow-next ml-2"><i
                  class="fa-solid fa-arrow-right "></i></button>
            </div>
          </div>
        </div>
      </section> -->
      <!-- <section class="content-section darkbg">
        <div class="site-container pr-0">
          <div class="featured-slider">
            <div class="slide-item">
              <div class="featured-box-2 d-flex flex-column justify-content-around"> -->
                <!-- <div class="item-count">01</div> -->
                <!-- <div class="icon mb-5 text-center">
                  <img src="/bringin_landing/assets/images/icons/icon-6.png" alt="" />
                </div>
                <h3 class="font-playfair h3-style">
                  Connect with People who have Similar Taste
                </h3>
              </div>
            </div>
            <div class="slide-item">
              <div class="featured-box-2 d-flex flex-column justify-content-around"> -->
                <!-- <div class="item-count">02</div> -->
                <!-- <div class="icon mb-5 text-center">
                  <img src="/bringin_landing/assets/images/icons/icon-4.png" alt="" />
                </div>
                <h3 class="font-playfair h3-style">
                  See Product Reviews, Unboxing Live
                </h3>
              </div>
            </div>
            <div class="slide-item">
              <div class="featured-box-2 d-flex flex-column justify-content-around"> -->
                <!-- <div class="item-count">03</div> -->
                <!-- <div class="icon mb-5 text-center">
                  <img src="/bringin_landing/assets/images/icons/icon-7.png" alt="" />
                </div>
                <h3 class="font-playfair h3-style">Build Communites & Share</h3>
              </div>
            </div>
            <div class="slide-item">
              <div class="featured-box-2 d-flex flex-column justify-content-around"> -->
                <!-- <div class="item-count">04</div> -->
                <!-- <div class="icon mb-5 text-center">
                  <img src="/bringin_landing/assets/images/icons/icon-8.png" alt="" />
                </div>
                <h3 class="font-playfair h3-style">
                  Become a Creator & Learn More
                </h3>
              </div>
            </div>
            <div class="slide-item autoplay">
              <div class="featured-box-2 d-flex flex-column justify-content-around"> -->
                <!-- <div class="item-count">05</div> -->
                <!-- <div class="icon mb-5 text-center">
                  <img src="/bringin_landing/assets/images/icons/icon-6.png" alt="" />
                </div>
                <h3 class="font-playfair h3-style">
                  Connect with People who have Similar Taste
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section> -->
    </main>
    <footer id="site-footer">
      <div class="site-container">
        <!-- <div class="languages-wrap">
          <img src="/bringin_landing/assets/images/languagess.png" alt=""/>
        </div> -->
        <div class="logo-icon mb-3">
          <img src="/bringin_landing/assets/images/image-12.png" alt="" />
        </div>
        <div class="flinks mb-1">
          <ul>
            <li>
              <a href="/aboutus/" class="text-white">About Us</a>
            </li>
            <!-- <li>
              <a href="/blog/" class="text-white">Blogs</a>
            </li> -->
            <!-- <li>
              <a href="#">Careers</a>
            </li> -->
            <li>
              <a href="/privacy/" class="text-white">Privacy Policy</a>
            </li>
            <!-- <li>
              <a href="#">Terms of Service</a>
            </li> -->
            <li>
              <a href="/support/" class="text-white">Support</a>
            </li>
            <li>
              <a href="/bringinBazaar" class="text-white">Bazaar</a>
            </li>
          </ul>
        </div>
        <div class="copyright">
          <p class="m-0">
            &copy; Copyright 2024 Bringin Web Private Limited. All rights
            reserved.
          </p>
        </div>
      </div>
      <div class="bg-vector">
        <img src="/bringin_landing/assets/images/bg-vector-light.png" alt="" />
      </div>
    </footer>
  </div>
</template>
<script>
import axios from 'axios';
import { Hooper, Slide,Navigation as HooperNavigation } from 'hooper';
import 'hooper/dist/hooper.css';
import BringinCard from '../../common/Card/BringinCard.vue'
import Navbar from "../../common/Navbar/Navbar.vue";
export default {
  name: 'Welcome',
  components: {
    BringinCard,
    Navbar,
    Hooper,Slide,HooperNavigation
  },
  data() {
    return {
      desc: '',
      viewTrendingHashtags: [],
      viewTrendingPostsData: {},
      trendingResponsiveSettings: {
        itemsToShow: 2,
        itemsToSlide: 2,
        breakpoints: {
          720: {
            itemsToShow: 3,
            itemsToSlide: 3,
          },
          960: {
            itemsToShow: 4,
            itemsToSlide: 4,
          }
        }
      }
    }
  },
  mounted() {
    this.dataAPIs()
  },
  methods: {
    dataAPIs() {
      this.getAPIsData();
    },

    getAPIsData() {
      this.getTrendingHashtags()
      this.getTrendingPosts()
    },

    slidePrevTrending() {
      this.$refs.TrendingCarousel.slidePrev();
    },
    slideNextTrending() {
      this.$refs.TrendingCarousel.slideNext();
    },
    updateCarouselTrending(payload) {
      this.myCarouselData = payload.currentSlide;
    },

    getTrendingHashtags() {
      let self = this
      let url = "/api/v1/homepage/trendinghashtagspub/"
      let payload = {}
      axios.post(url, payload).then(function (res) {
        // console.log("res",res.data.);
        let resData = res.data
        if (resData.success) {
          self.viewTrendingHashtags = resData.data;
        }
        else {
          alert("Invalid User")
        }
      });
    },
    getTrendingPosts() {
      let self = this
      let arr = []
      self.viewTrendingPostsData = []
      let url = "/api/v1/homepage/trendingpostspub/"
      let payload = {
        "language": [],
        "interest_id": [],
        "force_refresh": true
      }
      axios.post(url, payload).then(function (res) {
        // console.log("res",res.data);
        let resData = res.data
        if (resData.success) {
          for (let [idx, data] of resData.data.entries()) {
            if (data.thumbnail != null && data.profile_pic != null) {
              arr.push(data)
            }
          }
          self.viewTrendingPostsData = arr
        }
        else {
          alert("Invalid User")
        }
      });
    },
  }
}
</script>
<style scoped>
.bringin-container{
  padding:45px 4% 60px 4%;
}

.secont-primary-color {
  color: #B8F009;
}
.cursor-point{
  cursor: pointer;
}
#banner-container{
 position:relative;
  width:fit-content;
  width: 100%;
}
#grad{
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  mix-blend-mode: normal;
  border: 1px solid #000000;
  /* box-sizing: border-box; */
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}
#grad2{
  position: absolute;
  width:100%;
  height:100%;
  background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.78) 100%);
}

#banner-container img{
  width:100%;
  height:100%;
}
.banner-title{
  position: absolute;
  bottom: 5%;
  left:5%;
  font-weight: 550;
  font-size: 60px;
}
.banner-icon{
  border:3px solid #B8F009;
  color:#B8F009;
  cursor: pointer;
  font-size: 48px !important;
}
/* modal css */
.b-modal-content{
  background: #343434;
  padding: 0px;
}
.language-filter{
  display: flex;
  justify-content: center;
  padding-left: 69px;
}
.modal-header{
  padding:0px
}
.modal-body{
  padding: 0px;
}

.modal-title-english{
  font-size:16px;
  /* background: #B8F009; */
  background: black;
  color: white;
  padding: 5px 30px;
  border-radius: 25px 0 0 25px;
}
.modal-title-hindi{
  font-size:16px;
  /* background: #B8F009; */
  background: black;
  color: white;
  padding: 5px 30px;
  border-radius: 0 25px 25px 0px;
}
.activeVideo{
  background: #B8F009; 
  color: #000;
}
.arrow-prev{
  background: none;
  border:1px solid #fff;
  border-radius: 50%;
  color:#fff;
  width:30px;
  height: 30px;
  font-size:14px;
}
.arrow-next{
  background: #B8F009;
  border:1px solid #B8F009;
  border-radius: 50%;
  color:#080808;
  width:30px;
  height: 30px;
  font-size:14px;

}

.avatar {
  vertical-align: middle;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.categories{
  overflow: hidden;
  padding:50px 4% 60px 4%;
}
.trending-container {
  background: #343434;
  overflow: hidden;
  padding:50px 4% 60px 4%;

}

.trending-pills-container {
  overflow: hidden;
  text-overflow: clip;
}

.trending-pills-bedge {
  background-color: #b8f009;
  color: #000;
}

.trending-pills-bedge:hover {
  cursor: pointer;
}

.featured-container {
  overflow: hidden;
  padding:50px 4% 60px 4%;

}
.offers-container {
  overflow: hidden;
  padding:50px 4% 60px 4%;
}
.follow-container {
  background: #343434;
  overflow: hidden;
  padding:50px 4% 60px 4%;
}
.avatar-suggest {
  vertical-align: middle;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
a{
  text-decoration: none;
}
</style>