<template>
  <div>
    <div class="container-fluid navbar-bringin" >
      <div class="bringin-container ">
        <nav class="navbar navbar-expand-lg  ">
          <div class="container-fluid ">
            <a class="navbar-brand" href="#">
                <router-link to="/home"><img src="../../../assets/Assets.xcassets/Dark_Theme_icons/logo_home.imageset/logo_home@3x.png" class="img-fluid" /></router-link>
            </a>
            <div class="navbar-icon">
                <router-link to="/home" class="text-dark icon-container"><font-awesome-icon :icon="['fas','home']" size="lg" class="mx-3 text-light icon-hover active icon-container" /></router-link>
                <!-- <router-link to="/message"><font-awesome-icon :icon="['fas','comment-dots']" size="lg " class="mx-3 text-light"/></router-link> -->
                <!-- <router-link to="/createvideo"><font-awesome-icon :icon="['fas','plus-circle']" size="lg" class="mx-3 text-light"/></router-link> -->
                <!-- <router-link to="/"><font-awesome-icon :icon="['fas','heart']" size="lg" class="mx-3 text-light"/></router-link> -->
                <!-- <div class="btn-group">
                  <a  type="button" class="" data-bs-toggle="dropdown" aria-expanded="false">
                    <font-awesome-icon :icon="['fas','bell']" size="lg" class="mx-3 text-light icon-hover" />
                  </a>
                  <ul class="dropdown-menu dropdown-bg dropdown-menu-end py-0">
                    <Notifications/>
                    <Notifications/>
                    <Notifications/>
                  </ul>
                </div> -->
                <!-- <router-link to='/userprofile'><font-awesome-icon :icon="['fas','user']" size="lg" class="mx-3 text-light" /></router-link> -->
                <div class="btn-group">
                  <a  type="button" class="" data-bs-toggle="dropdown" aria-expanded="false">
                    <font-awesome-icon :icon="['fas','user']" size="lg" class="mx-3  icon-hover active" />
                  </a>
                  <ul class="dropdown-menu dropdown-bg dropdown-menu-end py-0">
                    <li class="py-2">
                      <router-link to="/userprofile" class="dropdown-item text-light fw-bold d-flex justify-content-start">
                          <p class="m-0 "><font-awesome-icon :icon="['fas','user-circle']" size="lg" class="me-3 text-light " />Profile</p>
                      </router-link>
                    </li>
                    <!-- <li><hr class="dropdown-divider p-0 m-0"></li>
                    <li class="py-2">
                      <div class="dropdown-item text-light fw-bold d-flex justify-content-start" href="#">
                          <p class="m-0">Settings</p>
                      </div>
                    </li> -->
                    <li><hr class="dropdown-divider p-0 m-0"></li>
                    <li class="py-2">
                      <div class="dropdown-item  text-light fw-bold d-flex justify-content-start" @click="logout">
                          <p class="m-0"><font-awesome-icon :icon="['fas','sign-out-alt']" size="lg" class="me-3 text-light" />Logout</p>
                      </div>
                    </li>
                  </ul>
                </div>
            </div>
          </div>
        </nav>
      </div>
    </div>    
  </div>
</template>

<script>
import Notifications from '../../pages/Notifications/Notifications.vue'
export default {
  name: "Navbar",
  components:{Notifications},
  data(){
    return{
    }
  },
  methods:{
    logout(){
      delete localStorage.userData
      window.location.reload();
    }
  }
};
</script>
<style scoped>
.bringin-container{
  padding:0 3% 0 3%;
  
}
.active{
  height: 40px;
  width: 40px;
  color:#b8f009 !important;
  border: 8px solid #212529;
  border-radius: 50%;
  background: #212529;
  font-size:14px;
  vertical-align: middle;
}

.navbar-bringin{
  background-color:#343434;
}
.navbar-brand  img{
    width: 180px;  
}
.secont-primary-color{
    color: #b8f009;
}
.icon-hover:hover{
    color:#b8f009 !important;
}
.dropdown-bg{
  background-color:#353535;
}
.dropdown-divider{
    border-bottom: 1px solid #cccacab4;
}
 .dropdown-item:hover{
  background: none;
  color: white !important;
  cursor: pointer;
}
/* .dropdown-item-hover p:hover{
   color: #000;
} */
</style>
