<template>
    <div>
        <p v-if="post.is_sponsored" class="text-light p-0  mb-2 ml-3 " style="font-size:12px">Brand association</p>
        <p v-if="!post.is_sponsored" class="text-light p-0  mb-2 ml-3 " style="font-size:12px">Independent Content <i class="fa-solid fa-circle-info ml-2"></i></p>
        <a :href="'/watch?v='+post.post_id" style="text-decoration:none" target="_blank" >
        <!-- <router-link :to="'/watch/'+post.post_id" style="text-decoration:none" > -->
        <div class="card">
            <!-- <div @click="playVideoNow" class="curser-point" data-bs-toggle="modal" :data-bs-target="'#modal_'+post.mux_playback_id"> -->
             <div class="card-img">
                <img v-if="post.thumbnail" :src="post.thumbnail" class="card-img-top img-fluid" alt="thumbnail" >
                <img v-if="!post.thumbnail" :src="'https://image.mux.com/'+ post.mux_playback_id +'/thumbnail.png?width=400&height=400&fit_mode=smartcrop'" class="card-img-top img-fluid" alt="thumbnail" >
                 <div class="card-img-overlay">
                    <img src="../../../assets/Assets.xcassets/Dark_Theme_icons/play_btn_dark.imageset/play_btn_dark@2x.png" 
                    class="position-absolute top-50 start-50 translate-middle curser-point img-fluid" />
                </div>
             </div>
            </div>
           
            <div class="card-body p-2" style="font-size:12px">
                <router-link :to="'/profile/'+handle" style="text-decoration:none">
                    <p class="card-title text-light" style="font-size:12px">                 
                        <img :src="profile_pic" v-if="profile_pic" alt="Avatar" class="avatar me-2 card-text-handle img-fluid">
                        <img src="../../../assets/images/dummy.png" v-if="!profile_pic"  alt="Avatar" class="avatar me-2 card-text-handle img-fluid">
                    {{handle}}
                    </p>
                </router-link>
                <p class="card-text card-text-title text-light m-1">{{post.title}}</p>
                <p class="card-text m-1" v-if="post.created_at"><small class="text-muted">
                   {{ getTimeAgo() }}
             </small></p>
            </div>
        <!-- </div> -->
        </a>
        <!---modal !-->
        <div class="modal fade" :id="'modal_'+post.mux_playback_id" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg ">
                <div class="modal-content modal-bg">
                    <div class="row">
                        <div class="col-6 pe-0" style="height:100%;">
                            <div class="align-middle" >
                                <video :ref="'video_'+post.mux_playback_id" :id="'video_'+post.mux_playback_id" height="100%" controls>
                                    <source :src="'https://stream.mux.com/'+ post.mux_playback_id +'/'+'low.mp4'" type="video/mp4">
                                </video> 
                            </div>
                        </div>
                        <div class="col-6 ps-0">
                            <div class="modal-header">
                                <p class="align-middle text-light fw-bold curser-point m-0">
                                    <img :src="profile_pic" alt="Avatar" class="avatar-suggest me-2 img-fluid"/>
                                    {{handle}}
                                </p>
                                <div>
                                    <div class="d-flex justify-content-start mt-0">
                                     <p v-if="Object.keys(commentsData).length" class="m-0 text-light curser-point"><font-awesome-icon :icon="['far','heart']" size="lg" class="me-2 ms-4 text-light"/> {{commentsData.likes.post_likes}} </p>
                                     <p class="m-0 mx-2 text-light curser-point" ><font-awesome-icon :icon="['far','eye']" size="lg" class=" text-light"/> {{ post.views}} </p>
                                     <!-- <p class="m-0 curser-point"><font-awesome-icon :icon="['fas','share-alt']" size="lg" class="mx-2 text-light"/></p>
                                     <p class="m-0 curser-point" ><font-awesome-icon :icon="['far','comment']" size="lg" class="mx-2 text-light"/></p> -->
                                    </div>
                                    <button style="position: absolute;top: 0;right: 1px;" @click="stopVideoNow" type="button" class="btn-close btn-close-white bw-bold" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>                                
                            </div>
                            <div style="color:white" v-if="Object.keys(commentsData).length">
                                <div v-for="d in commentsData.comments" :key="'comments'+d.comment_id">
                                    <div class="modal-body border-section ">
                                        <p style="font-size:12px" class="pe-4 align-middle text-light curser-point">
                                          <img v-if="d.profile_pic" :src="d.profile_pic" alt="Avatar" class="avatar-suggest me-2"/>
                                          <img v-if="!d.profile_pic" src="../../../assets/images/dummy.png" alt="Avatar" class="avatar-suggest me-2 img-fluid"/>
                                          {{d.handle}}
                                        </p>
                                        <p>
                                            {{ d.comment_text }}
                                        </p>
                                
                                     </div>
                                </div>
                            </div>
                         
                            
                            <div class="border-section modal-footer">
                                 
                                 <div class="mx-3">
                                    <div class="input-group my-3  d-flex justify-content-between" >
                                        <input type="text" class="bringin-input flex-fill me-2" placeholder="Add a comment..." aria-label="Username" aria-describedby="basic-addon1"/>
                                        <p class="m-0 second-primary-color fw-bold p-0 curser-point">Post</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment'
import axios from 'axios'
export default {
    name:'BringinCard',
    props:['post','profile_pic','handle'],
    data(){
        return {
            commentsData: {}

        }
     
    },
    methods:{
        getCommentsData(){
            let self = this
            let url = "/api/v1/viewcommentlikes/?"
            url += "post_id=" + self.post.post_id
            url+= "&get_userdata=true&get_likes=true"
            axios.get(url).then(function(res){
                let resData = res.data.data
                if(resData.success)
                {
                   self.commentsData = resData.data
                }
                else
                {
                   console.log('comment api error')
                }
            });

        },
        playVideoNow(){
            let self = this
            let video = self.$refs['video_'+this.post.mux_playback_id]
            video.currentTime = 0;
            video.play()
            self.getCommentsData()
           
        },
        stopVideoNow(){
            let self = this
            let video = self.$refs['video_'+this.post.mux_playback_id]
            video.pause()
          
        },
        getTimeAgo(){ 
        let a =  moment()
        let b = moment(this.post.created_at)
        
        var years = a.diff(b, 'year');
        b.add(years, 'years');

        var months = a.diff(b, 'months');
        b.add(months, 'months');

        var days = a.diff(b, 'days');  
        b.add(days, 'days');

        var hours = a.diff(b, 'hours');  
        b.add(hours, 'hours');

        var minutes = a.diff(b, 'minutes');  
        b.add(minutes, 'minutes');

        var seconds = a.diff(b, 'seconds');  
        b.add(seconds, 'seconds');
        
        let ago = years + ' Years ago'
        if(years==0) ago = months + ' Months ago' 
        if(months==0) ago = days + ' Days ago' 
        if(days==0) ago = hours + ' Hours ago' 
        if(hours==0) ago = minutes + ' Minutes ago' 
        if(minutes==0) ago = seconds + ' Seconds ago'
        return ago
        }
    }
    
    // createdTime:moment(firstdate).format('YYY-MM-DD HH:ss') - moment(post.created_at).format('YYY-MM-DD HH:ss')
}
</script>
<style scoped>
.second-primary-color{
    color: #b8f009;
}
.card{
    background:none;
    border: none;
}
.card-img{
    width:100%;
    overflow: hidden;

}
.card-img img{
    border-radius: 5%;
}
.card-body{
    background: none;
    border: none;
}
.card-text-handle{
    font-size: 12px !important;
}
.card-text-title{
    font-size:12px ;
    font-weight: 600;
    overflow: hidden;
}
.card-overlay-section{
     height: 180px;
}
.avatar {
    vertical-align: middle;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
.curser-point{
    cursor: pointer;
}
.modal-bg{
    background: #202020;
}
.bringin-input{
    border: none;
    background: #202020;
    color:#fff;
    border-bottom: 1px solid #fff;
}
.bringin-input::placeholder{
    color:#9e9e9e;
}
.bringin-input:focus{
    outline:none;
}
</style>
