<template>
    <div>
        <Navbar/>
        <div class="bringin-container">
            <div class="row my-4 text-light d-flex justify-content-center">
                <div class="col-12">
                    <div class="category-head row mt-2">
                        <!-- <div v-for="category in viewCategoryData" :key="category.interest_id" class="col-6"> -->
                            <!-- <h2 v-if="category.interest_id==category_id" class="text-light fw-bold text-center"> -->
                                <!-- {{viewCategoryData}} -->
                            <!-- </h2> -->
                        <!-- </div> -->
                        <div class="filter col-12">
                            <div class="modal-title-ind" :class="{activeFilter:is_sponsored==false}" @click="changeSponsored(false)">Independent Content </div>
                            <div class="modal-title-brand" :class="{activeFilter:is_sponsored==true}" @click="changeSponsored(true)">Brand association</div>
                        </div>
                    </div>
                     <div class="row text-light mt-5">
                        <div class="d-flex justify-content-center mt-3" v-if="loader">
                            <Loader/>
                        </div>
                        <!-- {{viewPostbyCategoryData}} -->
                        <div v-for="post of viewPostbyCategoryData.posts" :key="post.id" class="col-6 col-md-3 col-sm-4 my-4">
                            <BringinCard 
                                v-bind:post="post" 
                                v-bind:handle="post.handle"
                                v-bind:profile_pic="post.profile_pic"
                            />
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>
<script>
import axios from 'axios'
import Navbar from '../../common/Navbar/Navbar.vue'
import Loader from '../../common/Loader/Loader.vue'
import BringinCard from '../../common/Card/BringinCard.vue'
import Footer from "../../common/Footer/Footer.vue"

export default{
    name: "PostByCategory",
    components: {Navbar,Loader,BringinCard,Footer},
    data(){
        return{
            loader:true,
            category_id:this.$route.params.category_id,
            viewPostbyCategoryData:[],
            viewCategoryData:'',
            is_sponsored:false
        }
    },
    mounted(){
     this.dataAPIs();
    },
    methods:{
        dataAPIs(){
            let token = JSON.parse(localStorage.userData).token
            axios.defaults.headers.common['token'] = token
            this.getAPIsData();
        },
        getAPIsData(){
            this.getPostbyCategory();
            this.getFormData();
        },
        changeSponsored(sponsored){
            this.is_sponsored=sponsored;
            this.getPostbyCategory();
        },
        getPostbyCategory(){
            let self = this
            self.loader=true
            self.viewPostbyCategoryData={}
            let categoryId=[]
            categoryId.push(self.category_id)
            let url = "/api/v1/homepage/postbyinterestv5/"
            let payload={
                "user_id": "",
                "language": [],
                "interest_id": categoryId,
                // "filter_tag": self.hashtag,
                "is_sponsored":self.is_sponsored,
                "start_pos": 0,
                "end_pos": 100
            }
            axios.post(url,payload).then(function(res){
                // console.log("res",res.data.);
                let resData = res.data
                if(resData.success)
                {
                    self.viewPostbyCategoryData=resData.data.data;
                    self.loader=false;
                }
                else
                {
                    alert("Invalid User")
                }
            });
        },
    }
}
</script>
<style scoped>
.bringin-container{
    padding:0 4% 0 4%;
}
.category-head{
}
.filter{
  display: flex;
  justify-content: center;
  line-height: 30px;
}
.modal-title-ind{
  font-size:16px;
  background: black;
  color: white;
  padding: 1px 5px;
  border-radius: 25px 0 0 25px;
}
.modal-title-brand{
  font-size:16px;
  background: black;
  color: white;
  padding: 3px 10px;
  border-radius: 0 25px 25px 0px;
}
.activeFilter{
  background: #B8F009; 
  color: #000;
}
</style>