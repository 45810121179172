<template>
  <div class="landing_page"  > 
    <header id="site-header">
       <div class="site-container d-flex align-items-space-between" style="justify-content:space-between">
          <div class="logo">
            <a href="/">
              <img src="/bringin_landing/assets/images/logo2.png" alt="" />
            </a>
          </div>
      </div>
    </header>
    <main id="main-content">
      <section class="content-section lightbg bg-color">
        <div class="container">
            <div class="content-container">
                <h4 class="heading heading-md text-color mb-4">1.Return (duration, conditions)</h4>
                <p>
                    Bringin's return policy gives you an option to return items purchased on the app for any reason
                    within the specified return period.
                </p>
                <p>    
                    You can place a return request within 7 days after the product is delivered.
                    However, the product must be in the condition that it was delivered to you in, along with the
                    original tags and packaging.
                </p>
                <p>     
                    A quality check will be done during the return to initiate a smooth refund process.
                </p>
                <p>     
                <h4 class="heading heading-md text-color mb-4">2. Cancellation</h4>
                <p> 
                    You can cancel the order within 24 hours of placing it. This also applies to things bought using
                    discounts and offers. For prepaid orders, the amount will be credited to the same payment
                    method that was used to make the payment.
                </p>
                <h4 class="heading heading-md text-color mb-4">3. Refund</h4>
                <p>
                    Please note that after a successful return, the refund will be initiated. Once the returned product
                    has reached us and the quality check has been performed successfully, the refund amount is
                    expected to be reflected in the customer's account within 5-7 days. For refunds directly to source
                    account that the payment was made from, the amount may take 5-7 days to reflect in your
                    account depending upon bank partner.
                </p>
                <p>    
                    Therefore, the refund initiation time may vary depending on the time taken by the courier partner
                    to deliver the return to the warehouse.  
                </p>   
            </div>
        </div>    
      </section>
    </main>
    <footer id="site-footer">
      <div class="site-container">
        <div class="languages-wrap">
          <img src="/bringin_landing/assets/images/languages.png" alt="">
        </div>
        <div class="logo-icon mb-3">
          <img src="/bringin_landing/assets/images/bg-vector-icon.png" alt="">
        </div>
        <div class="flinks mb-1">
          <ul>
            <li>
              <a href="/aboutus/" class="text-white">About Us</a>
            </li>
            <!-- <li>
              <a href="#">Careers</a>
            </li> -->
            <li>
              <a href="/privacy/" class="text-white">Privacy Policy</a>
            </li>
            <!-- <li>
              <a href="#">Terms of Service</a>
            </li> -->
            <li>
              <a href="/support/" class="text-white">Support</a>
            </li>
          </ul>
        </div>
        <div class="copyright">
          <p class="m-0">&copy; Copyright 2024 Bringin Web Private Limited. All rights reserved.</p>
        </div>
      </div>
      <div class="bg-vector">
        <img src="/bringin_landing/assets/images/bg-vector-light.png" alt="">
      </div>
    </footer>
  </div>
</template>
<script>

export default {
    name:'Welcome',
    data(){
        return {
          desc: ''
        }
    }
}
</script>
