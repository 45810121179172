<template>
    <div>
        <div class="card">
            <div class="card-img">
                <div v-if="post.thumbnail!=null">
                    <img :src="post.thumbnail" class="card-img-top" alt="...">
                </div>
                <div v-else>
                        <img :src="'https://image.mux.com/'+post.mux_playback_id+'/thumbnail.png?width=400&height=400&time=5'" 
                            class="card-img-top" alt=""
                            v-if="'https://image.mux.com/'+post.mux_playback_id+'/thumbnail.png?width=400&height=400&time=5'" 
                        >
                        <img src="../../../assets/images/white.png" 
                            class="card-img-top" alt=""
                            v-if="!'https://image.mux.coPm/'+post.mux_playback_id+'/thumbnail.png?width=400&height=400&time=5'" 
                        >
                </div>
                <div class="card-img-overlay p-0">
                    <img src="../../../assets/Assets.xcassets/Dark_Theme_icons/gradient_discover_live.imageset/gradient_discover_live.png" 
                    class=" p-0 img-fluid"/>
                </div>
            </div>
            <div class="card-img-overlay p-1 ">
                <router-link :to="'/profile/'+post.handle" style="text-decoration:none">
                    <p class="card-title text-light p-1 m-0 fw-bold curser-point" style="font-size:12px">                 
                        <img :src="post.profile_pic" alt="Avatar" class="avatar me-2">
                        {{post.handle}}
                </p>
                </router-link>
                <a :href="'/watch?v='+post.post_id" style="text-decoration:none" target="_blank" >
                <!-- <router-link :to="'/watch/'+post.post_id" style="text-decoration:none"> -->
                <!-- <div @click="playVideoNow" class="" data-bs-toggle="modal" :data-bs-target="'#modal_'+post.mux_playback_id"> -->
                    <div class="card-body position-absolute bottom-0 start-0 px-3 py-1">
                        <p class="badge rounded-pill pills-bedge  fw-light m-0">{{ getTimeAgo() }}</p>
                        <p style="font-size:12px" class=" m-0 p-0 text-light">{{post.title}}</p>
                    </div>
                    <img src="../../../assets/Assets.xcassets/Dark_Theme_icons/play_btn_dark.imageset/play_btn_dark@2x.png" 
                    class="position-absolute top-50 start-50 translate-middle curser-point img-fluid"/>
                <!-- </div> -->
                </a>
            </div>
        </div>
                <!---modal !-->
        <div class="modal fade" :id="'modal_'+post.mux_playback_id" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg ">
                <div class="modal-content modal-bg">
                    <div class="row">
                        <div class="col-6 pe-0" style="height:100%;">
                            <div class="align-middle" >
                                <video :ref="'video_'+post.mux_playback_id" :id="'video_'+post.mux_playback_id" height="100%" controls>
                                    <source :src="'https://stream.mux.com/'+ post.mux_playback_id +'/'+'low.mp4'" type="video/mp4">
                                </video> 
                            </div>
                        </div>
                        <div class="col-6 ps-0">
                            <div class="modal-header">
                                <p class="align-middle text-light fw-bold curser-point m-0">
                                    <img :src="post.profile_pic" alt="Avatar" class="avatar-suggest me-2"/>
                                    {{post.handle}}
                                </p>
                                <div>
                                    <p v-if="Object.keys(commentsData).length" class="m-0 text-light curser-point"><font-awesome-icon :icon="['far','heart']" size="lg" class="me-2 ms-4 text-light"/> {{commentsData.likes.post_likes}} </p>
                                    <!-- <p class="m-0 mx-2 text-light curser-point" ><font-awesome-icon :icon="['far','eye']" size="lg" class=" text-light"/> {{ post.views}} </p> -->
                                    <button style="position: absolute;top: 0;right: 1px;" @click="stopVideoNow" type="button" class="btn-close btn-close-white bw-bold" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>                                
                            </div>
                             <div style="color:white" v-if="Object.keys(commentsData).length">
                                <div v-for="d in commentsData.comments" :key="'comments'+d.comment_id">
                                    <div class="modal-body border-section ">
                                        <p style="font-size:12px" class="pe-4 align-middle text-light curser-point">
                                          <img v-if="d.profile_pic" :src="d.profile_pic" alt="Avatar" class="avatar-suggest me-2"/>
                                          <img v-if="!d.profile_pic" src="../../../assets/images/dummy.png" alt="Avatar" class="avatar-suggest me-2"/>
                                          {{d.handle}}
                                        </p>
                                        <p>
                                            {{ d.comment_text }}
                                        </p>
                                
                                     </div>
                                </div>
                            </div>
                             <!-- <div class="modal-body border-section ">
                                <p class="pe-4 align-middle text-light fw-bold curser-point">
                                    <img :src="profile_pic" alt="Avatar" class="avatar-suggest me-2"/>
                                    {{handle}}
                                </p>
                                <p class="pe-4 align-middle text-light fw-bold curser-point">
                                    <img :src="profile_pic" alt="Avatar" class="avatar-suggest me-2"/>
                                    {{handle}}
                                </p>
                                <p class="pe-4 align-middle text-light fw-bold curser-point">
                                    <img :src="profile_pic" alt="Avatar" class="avatar-suggest me-2"/>
                                    {{handle}}
                                </p>
                             </div> -->
                             <!-- <div class="border-section modal-footer">
                                 <div class="d-flex justify-content-start mt-3">
                                     <p class="m-0 curser-point"><font-awesome-icon :icon="['far','heart']" size="lg" class="me-2 ms-4 text-light"/></p>
                                     <p class="m-0 mx-2 text-light curser-point" ><font-awesome-icon :icon="['far','eye']" size="lg" class=" text-light"/> 1</p>
                                     <p class="m-0 curser-point"><font-awesome-icon :icon="['fas','share-alt']" size="lg" class="mx-2 text-light"/></p>
                                     <p class="m-0 curser-point" ><font-awesome-icon :icon="['far','comment']" size="lg" class="mx-2 text-light"/></p>
                                 </div>
                                 <div class="mx-3">
                                    <div class="input-group my-3  d-flex justify-content-between" >
                                        <input type="text" class="bringin-input flex-fill me-2" placeholder="Add a comment..." aria-label="Username" aria-describedby="basic-addon1"/>
                                        <p class="m-0 second-primary-color fw-bold p-0 curser-point">Post</p>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>   
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment'
import axios from 'axios'
export default {
  name:'DiscoverCard',
  props:['post'],
  data(){
        return {
            commentsData: {}
        }
    },
  methods:{
    getCommentsData(){
        let self = this
        let url = "/api/v1/viewcommentlikes/?"
        url += "post_id=" + self.post.post_id
        url+= "&get_userdata=true&get_likes=true"
        axios.get(url).then(function(res){
            let resData = res.data.data
            if(resData.success)
            {
                self.commentsData = resData.data
            }
            else
            {
                console.log('comment api error')
            }
        });

    },
    playVideoNow(){
        let self = this
        let video = self.$refs['video_'+this.post.mux_playback_id]
        video.currentTime = 0;
        video.play()
        self.getCommentsData()
           
    },
    stopVideoNow(){
        let self = this
        let video = self.$refs['video_'+this.post.mux_playback_id]
        video.pause()
    },

    getTimeAgo(){ 
      let a =  moment()
      let b = moment(this.post.created_at)
     
      var years = a.diff(b, 'year');
      b.add(years, 'years');

      var months = a.diff(b, 'months');
      b.add(months, 'months');

      var days = a.diff(b, 'days');  
      b.add(days, 'days');

      var hours = a.diff(b, 'hours');  
      b.add(hours, 'hours');

      var minutes = a.diff(b, 'minutes');  
      b.add(minutes, 'minutes');

      var seconds = a.diff(b, 'seconds');  
      b.add(seconds, 'seconds');
      
      let ago = years + ' Years ago'
      if(years==0) ago = months + ' Months ago' 
      if(months==0) ago = days + ' Days ago' 
      if(days==0) ago = hours + ' Hours ago' 
      if(hours==0) ago = minutes + ' Minutes ago' 
      if(minutes==0) ago = seconds + ' Seconds ago'
      return ago
    }
  }
}
</script>

<style scoped>
.curser-point{
        cursor: pointer;
    }
.card{
    background-color:#181818;
    border: none;
}
.card-img{
   
    width: 95%;
    overflow: hidden;
}
.card-img-overlay{
    width: 95%;
    overflow: hidden;
}
.card-img img{
    width: 95%;
}
/* .card-body{
        background: #353535;
        border: none;
} */
 .avatar {
        vertical-align: middle;
        width: 35px;
        height: 35px;
        border-radius: 50%;
    }
 .pills-bedge{
        background-color: #b8f009;
        color:#000;
    }
</style>