<template>
    <div class="video-container"> 
        <Navbar/>       
        <div class="container ">
            <div class="row mt-4 d-flex justify-content-center">
                <div class="col-12 col-md-6 col-lg-4 pe-0 ">
                    <div class="d-flex justify-content-end" v-if="Object.keys(viewPostData).length" >
                        <video :ref="'video_'+viewPostData.mux_playback_id" :id="'video_'+viewPostData.mux_playback_id" height="100%" controls autoplay>
                            <source :src="'https://stream.mux.com/'+viewPostData.mux_playback_id+'/'+'low.mp4'" type="video/mp4">
                        </video> 
                    </div>
                </div>
                <!-- {{viewPostData.mux_playback_id}} -->
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 pe-0 px-0 comment-section-container">
                    <div class="my-2 ">
                        <router-link :to="'/profile/'+viewPostData.handle" style="text-decoration:none">
                            <p class="text-light fw-bold cursor-point mt-2 m-0 px-3">
                                <img :src="viewPostData.profile_pic" alt="Avatar" class="avatar-suggest me-2"/>
                                {{viewPostData.handle}}
                                <!-- <span class="fw-none ps-5" style="font-size:12px"><br/>{{viewPostData.title}}<br/>{{viewPostData.text}}</span> -->
                            </p>
                        </router-link>
                        <div class="text-light px-3" style="margin-left:50px">
                            <p class="m-0">{{viewPostData.title}}<br/>{{viewPostData.text}}</p>
                            <span v-for="tag in viewPostData.tags.split('#')" :key="tag" >
                                <router-link :to="'/hashtag/'+tag" class="text-dark">
                                    <span class="badge rounded-pill me-2 p-2 mb-1 cursor-point" style="background-color:#000; font-size:12px">#{{tag}}</span>
                                </router-link>
                            </span>
                            <p class="m-0 text-muted mt-1">{{getTimeAgo(viewPostData.created_at)}}</p>
                        </div>                              
                    </div>
                     <div class="border-section">
                        <div class="d-flex justify-content-start mt-2 px-3">
                            <p v-if="Object.keys(commentsData).length" class="me-2 text-light cursor-point">
                                <font-awesome-icon :icon="['far','heart']" size="lg" class=" me-1 text-light" @click="likePost('like')"  v-if="!commentsData.like_status"/>
                                <font-awesome-icon :icon="['fas','heart']" size="lg" class=" me-1 text-light" @click="likePost('unlike')" v-if="commentsData.like_status"/>
                                {{commentsData.likes.post_likes}} 
                            </p>
                            <p class="m-0 mx-2 text-light " ><font-awesome-icon :icon="['far','eye']" size="lg" class=" text-light"/> {{viewPostData.views}} </p>
                            <!-- <p class="m-0 text-light me-2">Share:</p> -->
                            <!-- <p class="m-0 cursor-point" ><font-awesome-icon :icon="['far','comment']" size="lg" class="mx-2 text-light"/></p> -->
                            <div v-for="d in socialPlateform">
                                <ShareNetwork
                                    :network="d"
                                    :url="url"
                                    >
                                    <i
                                    <font-awesome-icon :icon="['fab',d]" size="lg" :class="d" style="margin-left:10px"/>
                                </ShareNetwork>
                            </div>
                            
                        </div>
                        <div class="px-3">
                            <div class="input-group my-2  d-flex justify-content-between" >
                                <input v-model="addCommentData" type="text" class="bringin-input flex-fill me-2" placeholder="Add a comment..." aria-label="Username" aria-describedby="basic-addon1"/>
                                <div :class="{active:addCommentData.length!=0}">
                                    <p class="m-0 second-primary-color fw-bold p-0  cursor-point deactive" @click="postAddComment">Post</p>
                                </div>
                            </div>
                        </div>
                    </div>  
                                    <!-- <p class="text-light">{{viewPostData.mux_playback_id}}</p> -->

                    <div class=" my-2 p-3 pt-2" data-spy="scroll">
                        <div style="color:white" v-if="Object.keys(commentsData).length">
                            <div v-for="d in commentsData.comments" :key="'comments'+d.comment_id">
                                    <p style="font-size:12px" class=" text-light cursor-point m-0">
                                        <router-link :to="'/profile/'+d.handle" style="text-decoration:none">
                                            <img v-if="d.profile_pic" :src="d.profile_pic" alt="Avatar" class="avatar-comment me-2"/>
                                            <img v-if="!d.profile_pic" src="../../../assets/images/dummy.png" alt="Avatar" class="avatar-comment me-2"/>
                                            <span class="fw-bold">{{d.handle}}</span>
                                        </router-link>
                                        <span class="ms-3">{{ d.comment_text }}</span>
                                    </p>
                                <p class="text-light px-3 comment-time">
                                    <span class="text-muted me-3">{{getTimeAgo(d.created_at)}}</span>
                                    <span @click="deleteComment(d.comment_id)" 
                                        class="me-3 text-muted cursor-point"
                                        v-if="d.user_id===userId"
                                    >delete</span>
                                    <!-- <span class="text-muted cursor-point">Edit</span> -->
                                    <span></span>
                                </p>
                            </div>
                            <!-- <p class="text-light">{{commentsData.like_status}}</p> -->
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    </div>
</template>
<script>
import moment from 'moment'
import axios from 'axios'
import Navbar from '../../common/Navbar/Navbar.vue'
export default {
    name:'BringinCard',
    components: {Navbar},
    data(){
        console.log('here')
        return {
            postId:this.$route.params.post_id,
            userId:JSON.parse(localStorage.userData).user.id,
            commentsData: {},
            addCommentData:'',
            viewPostData:{},
            likeStatus:{},
            desc: '',
            url:window.location.pathname,
            socialPlateform:['facebook','twitter','whatsapp','telegram']
        }
    },
    mounted(){
        console.log(this.postId)
        this.dataAPIs();
        this.playVideoNow();
    },
    methods:{
        dataAPIs(){
            this.getPostData()
            this.getCommentsData()
            this.putPostView()
        },
        putPostView(){
            let self=this
            let url = "/api/v1/postviews/"
            let payload={
                "post_id":self.postId,
            }
            let timeout={
                "timeout":50000
            }
            axios.put(url,payload,timeout).then(function(res){
                let resData = res.data
                if(resData.success)
                {
                    // self.likeStatus=resData.data.status
                    // self.getCommentsData();
                    // self.likeAction=''
                }
                else
                {
                   console.log('comment api error')
                }
            });
        },

        likePost(action){
            let self=this
            let url = "/api/v1/postlikes/?post_id="+self.postId
            let payload={
                "post_id":self.postId,
                "action":action
            }
            axios.put(url,payload).then(function(res){
                let resData = res.data
                if(resData.success)
                {
                    self.getCommentsData();
                }
                else
                {
                   console.log('comment api error')
                }
            });
        },
        postAddComment(){
            let self = this
            if(!self.addCommentData)
                return false
            let url = "/api/v1/postcomment/"
            let payload={
                "comment_text":self.addCommentData,
                "post_id":self.postId
            } 
            axios.post(url,payload).then(function(res){
                let resData = res.data
                if(resData.success)
                {
                   self.getCommentsData()
                   self.addCommentData=''
                }
                else
                {
                   console.log('comment api error')
                }
            });

        },
        deleteComment(commentId){
            let self = this
            let url = "/api/v1/postcomment/"
            let payload={
                "post_id":self.postId,
                "comment_id":commentId
            }
            axios.delete(url,{data:payload}).then(function(res){
                let resData = res.data
                if(resData.success)
                {
                   self.getCommentsData()
                }
                else
                {
                   console.log('comment api error')
                }
            });
        },
        getPostData(){
            let self = this
            let url = "/api/v1/webpostview/"
            let payload={
                "post_id":self.postId
            }
            axios.post(url,payload).then(function(res){
                let resData = res.data.data
                if(resData.success)
                {
                   self.viewPostData = resData.data
                }
                else
                {
                   console.log('comment api error')
                }
            });
        },
        getCommentsData(){
            let self = this
            let url = "/api/v1/viewcommentlikes/?"
            url += "post_id=" + self.postId
            url+= "&get_userdata=true&get_likes=true&get_likestatus=true"
            axios.get(url).then(function(res){
                let resData = res.data.data
                if(resData.success)
                {
                   self.commentsData = resData.data
                }
                else
                {
                   console.log('comment api error')
                }
            });

        },
        playVideoNow(){
            let self = this
            let video = self.$refs['video_'+self.viewPostData.mux_playback_id]
            video.currentTime = 0;
            video.play()
            self.getCommentsData()
           
        },
        stopVideoNow(){
            let self = this
            let video = self.$refs['video_'+self.viewPostData.mux_playback_id]
            video.pause()
          
        },
        getTimeAgo(createdTime){ 
        let a =  moment()
        let b = moment(createdTime)
        
        var years = a.diff(b, 'year');
        b.add(years, 'years');

        var months = a.diff(b, 'months');
        b.add(months, 'months');

        var days = a.diff(b, 'days');  
        b.add(days, 'days');

        var hours = a.diff(b, 'hours');  
        b.add(hours, 'hours');

        var minutes = a.diff(b, 'minutes');  
        b.add(minutes, 'minutes');

        var seconds = a.diff(b, 'seconds');  
        b.add(seconds, 'seconds');
        
        let ago = years + ' Years ago'
        if(years==0) ago = months + ' Months ago' 
        if(months==0) ago = days + ' Days ago' 
        if(days==0) ago = hours + ' Hours ago' 
        if(hours==0) ago = minutes + ' Minutes ago' 
        if(minutes==0) ago = seconds + ' Seconds ago'
        return ago
        }
    }
}
</script>
<style scoped>
.second-primary-color{
    color: #b8f009;
}
.card-text-handle{
    font-size: 12px !important;
}
.card-text-title{
    font-size:12px ;
    font-weight: 600;
}
.card-overlay-section{
     height: 180px;
}
.avatar {
    vertical-align: middle;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
.cursor-point{
        cursor: pointer;
}
.avatar-comment{
     vertical-align: middle;
    width: 25px;
    height: 25px;
    border-radius: 50%;
}
.bringin-input{
    border: none;
    background: #181818;
    color:#fff;
    border-bottom: 1px solid #fff;
}
.bringin-input::placeholder{
    color:#9e9e9e;
}
.bringin-input:focus{
    outline:none;
}
.comment-section-container {
  border: 1px  solid #303030;
  /* border-left: 0px !important; */
  overflow: hidden;
}
.video-container{
    height: 100vh;
}
.video-container row{
    height: 100%;
}
.comment-time{
    margin-left:22px;
    font-size:12px;
    
}
.facebook{
    color:#4064AD;
}
.twitter{
    color:#3A9DEA;
}
.whatsapp{
    color:#76C95A;
}
.telegram{
    color: #3997DE;
}
</style>
