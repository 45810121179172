
<template>
    <div>
        <Navbar/>
        <div class="bringin-container">
            <div class="row my-4 text-light d-flex justify-content-center">
                <div class="col-12">
                     <h2 class="text-light fw-bold text-center pb-1 border-bottom">
                        Trending Topics
                     </h2>
                    <div class="trending-container mt-2">
                        <div class="d-flex justify-content-center" v-if="loader">
                            <Loader/>
                        </div>
                        <div  v-for="(tag,tag_index) in viewTrendingTopicsListData.meta" :key="tag.order" class="mt-3">
                            <div class="d-flex justify-content-between">
                                <p class="text-light fs-3 fw-bold mb-0">#{{tag.tag_text}}</p>
                                <router-link :to="'/hashtag/'+tag.tag_text"><p class="secont-primary-color fs-5 fw-bold">See All</p></router-link>
                            </div>   
                            <div>
                                <Hooper :settings="trendingResponsiveSettings" style="height:100%">
                                    <Slide v-for="(post,i) in viewTrendingTopicsListData.posts[tag_index]" :key="i">
                                    <div class="p-2">
                                        <BringinCard 
                                        v-bind:post="post" 
                                        v-bind:handle="post.handle"
                                        v-bind:profile_pic="post.profile_pic"
                                        />
                                        </div>
                                    </Slide>
                                </Hooper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>
<script>
import axios from 'axios'
import { Hooper, Slide,Navigation as HooperNavigation } from 'hooper';
import 'hooper/dist/hooper.css';
import Navbar from '../../common/Navbar/Navbar.vue'
import Loader from '../../common/Loader/Loader.vue'
import BringinCard from '../../common/Card/BringinCard.vue'
import Footer from "../../common/Footer/Footer.vue"

export default{
    name: "AllCategoryList",
    components: {Navbar,Loader,BringinCard,Footer,Hooper,Slide,},
    data(){
        return{
            loader:true,
            viewTrendingTopicsListData:[],
            viewCategoryData:'',
            trendingResponsiveSettings:{
              itemsToShow: 2,
              itemsToSlide:2,
              breakpoints: {
                720: {
                  itemsToShow: 3,
                  itemsToSlide: 3,
                },
                960: {
                  itemsToShow: 4,
                  itemsToSlide: 4,
                }
              }
            }
        }
    },
    mounted(){
     this.dataAPIs();
    },
    methods:{
        dataAPIs(){
            let token = JSON.parse(localStorage.userData).token
            axios.defaults.headers.common['token'] = token
            this.getAPIsData();
        },
        getAPIsData(){
            this.getTrendingTopicsList();
        },
        getTrendingTopicsList(){
            let self = this
            self.loader=true
            self.viewTrendingTopicsListData=[]
            let url = "/api/v1/homepage/trendingpostsgroupedv3/"
            let payload={
                "language": [],
                "interest_id": [],
                "start_pos": 0,
                "end_pos": 1000
            }
            axios.post(url,payload).then(function(res){
                // console.log("res",res.data.);
                let resData = res.data
                if(resData.success)
                {
                    self.viewTrendingTopicsListData=resData.data;
                    self.loader=false;
                }
                else
                {
                    alert("Invalid User")
                }
            });
        },
    }
}
</script>
<style scoped>
.bringin-container{
    padding:0 4% 0 4%;
}
.secont-primary-color {
  color: #B8F009;
} 
.category-head{
}
.filter{
  display: flex;
  justify-content: center;
  line-height: 30px;
}
.modal-title-ind{
  font-size:16px;
  background: black;
  color: white;
  padding: 1px 5px;
  border-radius: 25px 0 0 25px;
}
.modal-title-brand{
  font-size:16px;
  background: black;
  color: white;
  padding: 3px 10px;
  border-radius: 0 25px 25px 0px;
}
.activeFilter{
  background: #B8F009; 
  color: #000;
}
.trending-container {
  overflow: hidden;
}
a{
  text-decoration: none;
}
</style>